import imgData from '../images/photos';
const newsData = [
  {
    title: 'Lorem ipsum dolor sit amet',
    desc: 'Vivamus sit amet interdum elit. Proin lacinia erat ac velit tempus auctor.',
    thumb: imgData[45]
  },
  {
    title: 'Lorem ipsum dolor sit amet',
    desc: 'Vivamus sit amet interdum elit. Proin lacinia erat ac velit tempus auctor.',
    thumb: imgData[10]
  },
  {
    title: 'Lorem ipsum dolor sit amet',
    desc: 'Vivamus sit amet interdum elit. Proin lacinia erat ac velit tempus auctor.',
    thumb: imgData[20]
  }
];

export default newsData;
