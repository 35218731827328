/* eslint-disable consistent-return */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, FormControl, FormLabel, Typography } from '@material-ui/core';
import {
  useStripe,
  useElements,
  Elements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// eslint-disable-next-line no-unused-vars
import {
  currencyFormatter,
  getStripeCharges,
  getStripeTotalAmountToPay,
} from '../helpers';
import stripeLogo from '../../assets/images/stripe.svg';
import { ConfirmDialog } from './ConfirmDialog';
import { extractErrorMessage } from '../../redux/api/helpers';
import { PAYMENT_REQUESTS } from '../../redux/api/requests';
import http from '../../redux/api';
import { CircularIndeterminateSm } from '../../components/Progress/CircularIndeterminate';
import { useStripeCharges } from './hooks';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    minWidth: '100%',
  },
  text: {
    margin: 5,
    marginBottom: 8,
  },
  tc: {
    display: 'flex',
  },
  button: {
    border: 'none',
    background: 'none',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    textDecoration: 'underline',
    marginLeft: -15,
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  heading: {
    color: theme.palette.primary.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    marginBottom: '8px',
  },
  buttonContainer: {
    display: 'flex',
    gap: 10,
    flexWrap: 'wrap',
    marginTop: 10,
  },
  payButton: {
    background: theme.palette.primary.main,
    color: 'white',
    borderRadius: 5,
    marginRight: 10,
    textTransform: 'capitalize',
    '&:hover': { background: theme.palette.secondary.dark, color: 'white' },
  },
  cancelButton: {
    background: theme.palette.secondary.main,
    color: 'white',
    borderRadius: 5,
    textTransform: 'capitalize',
    '&:hover': { background: theme.palette.secondary.dark, color: 'white' },
  },
  dialogActions: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        marginBottom: 5,
      },
    },
  },
  poweredBy: {
    marginTop: 10,
  },
  stripeElement: {
    width: '100%',
    marginTop: 8,
    border: '1px solid rgba(0,0,0,0.32)',
    borderRadius: 8,
    padding: '10px',
  },
}));

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const cardInfoOptions = {
  style: {
    base: {
      fontSize: '16px',
      color: 'black',
      padding: '15rem',
      '::placeholder': {
        color: '#aab7c4',
      },
      iconColor: '#c4f0ff',
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

export function StripeParent({ children }) {
  return <Elements stripe={stripePromise}>{children}</Elements>;
}

export function PayWithStripe({
  open,
  onError,
  onSuccess,
  handleClose,
  amount,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [processingPayment, setProcessingPayment] = useState(false);
  const { collectedFee, amountToBePayed } = useStripeCharges(amount);

  const handleOpenConfirmDialog = (e) => {
    e.preventDefault();
    setOpenConfirmDialog(true);
  };

  const payWithStripe = async () => {
    if (!stripe || !elements) {
      return;
    }
    try {
      setProcessingPayment(true);
      const cardElement = elements.getElement(CardNumberElement);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      if (error || !paymentMethod) {
        onError(
          error
            ? error.message
            : "We couldn't process your payment. Please try again"
        );
        // eslint-disable-next-line consistent-return
        return setProcessingPayment(false);
      }
      const { data } = await http.post(
        PAYMENT_REQUESTS.INITIALIZE_WALLET_TOP_FROM_STRIPE,
        {
          amount: amountToBePayed,
          paymentMethodId: paymentMethod.id,
          collectedFee,
        }
      );
      const { id: paymentIntentId, reference } = data.data;
      await http.post(PAYMENT_REQUESTS.CONFIRM_WALLET_TOP_FROM_STRIPE, {
        reference,
        paymentIntentId,
      });
      setProcessingPayment(false);
      onSuccess();
    } catch (error) {
      const err = extractErrorMessage(error);
      onError(err);
      return setProcessingPayment(false);
    }
  };

  if (!open) {
    return null;
  }

  if (open && (!stripe || !elements)) {
    return <CircularIndeterminateSm size='24px' />;
  }

  return (
    <>
      <ConfirmDialog
        open={openConfirmDialog}
        onCancel={() => setOpenConfirmDialog(false)}
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={payWithStripe}
        title='Confirm Deposit'
        subtitle={`You will be charged ${currencyFormatter({
          value: amount,
          currency: 'USD',
        })} and ${currencyFormatter({
          value: collectedFee / 100,
          currency: 'USD',
        })} processing fees. This process is irreversible.`}
      />
      <form
        className={classes.form}
        onSubmit={handleOpenConfirmDialog}
        id='stripe-payment-form'
      >
        <FormLabel htmlFor='card-number'>Card Number</FormLabel>
        <FormControl
          id='card-number'
          required
          className={classes.stripeElement}
        >
          {/* <CardElement options={cardInfoOptions} /> */}
          <CardNumberElement options={cardInfoOptions} />
        </FormControl>
        <FormLabel htmlFor='card-expiry'>Card Expiry Date</FormLabel>
        <FormControl
          id='card-expiry'
          required
          className={classes.stripeElement}
        >
          {/* <CardElement options={cardInfoOptions} /> */}
          <CardExpiryElement options={cardInfoOptions} />
        </FormControl>
        <FormLabel htmlFor='card-cvc'>Card CVC</FormLabel>
        <FormControl id='card-cvc' required className={classes.stripeElement}>
          {/* <CardElement options={cardInfoOptions} /> */}
          <CardCvcElement options={cardInfoOptions} />
        </FormControl>
      </form>
      <div className={classes.buttonContainer}>
        <Button
          form='stripe-payment-form'
          type='submit'
          className={classes.payButton}
          disabled={processingPayment || !stripe || !elements}
        >
          {processingPayment ? <CircularIndeterminateSm size='24px' /> : 'Pay'}
        </Button>
        <Button
          onClick={handleClose}
          disabled={processingPayment}
          className={classes.cancelButton}
        >
          Cancel
        </Button>
      </div>
      <Typography className={classes.poweredBy}>
        Powered By
        <img
          height='35px'
          style={{ objectFit: 'contain' }}
          src={stripeLogo}
          alt='Stripe'
        />
      </Typography>
    </>
  );
}

PayWithStripe.propTypes = {
  open: PropTypes.bool.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  amount: PropTypes.string.isRequired,
};

StripeParent.propTypes = { children: PropTypes.element.isRequired };
