import { fromJS } from 'immutable';
import { commonState } from '../constants';
import { extractStatus, handleFetch } from '../../utils/reducerHelper';
import {
  FETCH_PROFILE_FORM_DATA_ERROR,
  FETCH_PROFILE_FORM_DATA_REQUEST,
  FETCH_PROFILE_FORM_DATA_SUCCESS,
} from '../types';

const initialState = fromJS({
  ...commonState,
  data: {},
});

const profileFormDataReducer = (state = initialState, action) => {
  const status = extractStatus(action.type);
  switch (action.type) {
    case FETCH_PROFILE_FORM_DATA_REQUEST:
    case FETCH_PROFILE_FORM_DATA_SUCCESS:
    case FETCH_PROFILE_FORM_DATA_ERROR:
      return fromJS(handleFetch(state.toJS(), status, action.payload));
    default:
      return state;
  }
};

export default profileFormDataReducer;
