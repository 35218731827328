/* eslint-disable camelcase */
import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppsIcon from '@material-ui/icons/Apps';
import Refresh from '@material-ui/icons/Replay';

import dummy from 'dan-api/dummy/dummyContents';
import messageStyles from 'dan-styles/Messages.scss';
import link from 'dan-api/ui/link';
import { useDispatch, connect } from 'react-redux';
import * as io from 'socket.io-client';
import { Help, LocalAtm } from '@material-ui/icons';
import {
  Dialog,
  Typography,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormLabel,
  TextField,
  Input,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import styles from './header-jss';
import { logout } from '../../redux/actions/authActions';
import { ENDPOINT } from '../../utils/constants';
import {
  setUnreadMessagesAction,
  setUnreadDealsAction,
  setUnreadEmailsAction,
  setConnection,
  setUnreadApplicationsAction,
  fetchProfile,
} from '../../redux/actions/profileActions';
import { Apps } from './AppBox';
import http, { getToken } from '../../redux/api';
import {
  useOutsideClick,
  useMobile,
  useProfile,
  useNetwork,
} from '../../utils/components/hooks';
import { LOGGED_IN_STATE_KEY } from '../../redux/constants';
import { currencyFormatter } from '../../utils/helpers';
import { FundWallet } from '../../utils/components/FundWallet';
import { ConfirmDialog } from '../../utils/components/ConfirmDialog';
import { CloseDialogButton } from '../../utils/components/CloseModalButton';
import {
  authRequests,
  PAYMENT_REQUESTS,
  userRequests,
} from '../../redux/api/requests';
import { NotifyUserOfEvent } from '../../redux/api/EventNotifier';
import { CircularIndeterminateSm } from '../Progress/CircularIndeterminate';
import { extractErrorMessage } from '../../redux/api/helpers';
import { UserContext } from '../../redux/context';
import { WalletContext } from '../../redux/walletContext';
import { KYCAlert, VerifyIdentity } from '../../utils/components/KYCAlert';
import { Bold } from '../../utils/components/Bold';

const useStyles = makeStyles((theme) => ({
  badges: {
    fontSize: '30px',
  },
  link: {
    textDecoration: 'none',
    color: 'white',
  },
  totalBalanceMargin: {
    marginBottom: 4,
  },
  button: {
    background: theme.palette.primary.main,
    color: 'white',
    borderRadius: 8,
    '&:hover': {
      background: theme.palette.secondary.main,
      color: 'white',
    },
  },
  withdrawRequestButton: {
    background: theme.palette.secondary.main,
    color: 'white',
    borderRadius: 8,
    height: 40,
    alignSelf: 'center',
    flex: 1,
    '&:hover': {
      background: theme.palette.secondary.dark,
      color: 'white',
    },
  },
  fundingRequestButton: {
    background: theme.palette.primary.main,
    color: 'white',
    borderRadius: 8,
    height: 40,
    alignSelf: 'center',
    '&:hover': {
      background: theme.palette.secondary.dark,
      color: 'white',
    },
    flex: 1,
  },
}));

export function WithdrawalDialog({
  open,
  close,
  email = '',
  networkId,
  syndicateId,
}) {
  const [withdrawing, setWithdrawing] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [response, setResponse] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [requestingVerificationCode, setRequestingVerificationCode] = useState(
    false
  );
  const [amount, setAmount] = useState('');
  const fullScreen = useMobile();
  const {
    state: { verified },
  } = useContext(UserContext);

  const receivable = +amount - +amount * 0.01;

  const sendCode = async () => {
    try {
      setRequestingVerificationCode(true);
      await http.post(authRequests.SEND_VERIFICATION_CODE(email));
      setRequestingVerificationCode(false);
    } catch (error) {
      const message = extractErrorMessage(error);
      setResponse(message);
      setRequestingVerificationCode(false);
    }
  };

  const withdrawFromNetwork = async () =>
    http.post(PAYMENT_REQUESTS.WITHDRAW_NETWORK_FUNDS, {
      amount: +amount * 100,
      verificationCode,
      networkId,
    });

  const withdrawFromSyndicate = async () =>
    http.post(PAYMENT_REQUESTS.WITHDRAW_SPV_COMMISSION, {
      amount: +amount * 100,
      verificationCode,
      syndicateId,
    });

  const confirmWIthdrawal = async () => {
    try {
      setWithdrawing(true);
      if (networkId) {
        await withdrawFromNetwork();
      } else {
        await withdrawFromSyndicate();
      }
      setWithdrawing(false);
      setResponse('Withdrawal request sent successfully');
      setError('');
      setSuccessMessage(
        'Your request is being processed. An email will be sent to you when the process is completed.'
      );
    } catch (err) {
      const message = extractErrorMessage(err);
      setError(message);
      setWithdrawing(false);
    }
  };

  return (
    <Dialog
      disableBackdropClick
      open={open}
      onClose={close}
      fullScreen={fullScreen}
    >
      <DialogTitle id='edit-syndicate-dialog'>Withdrawal Request</DialogTitle>
      <CloseDialogButton onClick={close} />
      <DialogContent>
        <DialogContentText>
          {verified ? (
            <>
              We just sent a verification code to {email.toLowerCase()}. Use it
              to complete your withdrawal request. Withdrawals can take up to 3
              working days to complete. This is to enable us prevent fraudulent
              withdrawals. You will be charged a 1% of the withdrawal amount to
              process this transaction.
            </>
          ) : (
            <KYCAlert />
          )}
        </DialogContentText>
        <form
          onFocus={() => {
            setError('');
          }}
          onSubmit={(e) => {
            e.preventDefault();
            setOpenConfirmDialog(true);
          }}
          id='withdrawal'
        >
          {error ? <Alert severity='error'>{error}</Alert> : null}
          {successMessage ? (
            <Alert severity='success'>{successMessage}</Alert>
          ) : (
            <>
              <Alert severity='success'>
                <Bold value={amount} /> will be deducted from your wallet. You
                will be charged 1% withdrawal fees for this transaction. You
                will receive <Bold value={receivable} /> in your bank account.
              </Alert>
              <br />
              <Alert severity='info'>
                Fees ranging from <Bold value={20} /> to <Bold value={30} /> may
                apply for international bank wires. This fee, where it applies,
                will be deducted from the amount you will receive in your bank
                account.
              </Alert>
            </>
          )}
          <FormControl
            style={{ width: '100%', marginTop: 10, marginBottom: 10 }}
            required
          >
            <FormLabel>Withdrawal Amount ($)</FormLabel>
            <Input
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
              fullWidth
              type='number'
            />
          </FormControl>
          <FormControl style={{ width: '100%' }} required>
            <FormLabel>Enter verification code</FormLabel>
            <TextField
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              required
              fullWidth
            />
          </FormControl>
        </form>
        <ConfirmDialog
          open={openConfirmDialog}
          onCancel={() => setOpenConfirmDialog(false)}
          onClose={() => setOpenConfirmDialog(false)}
          onConfirm={confirmWIthdrawal}
          subtitle='Do you want to confirm this withdrawal. This process is irreversible.'
        />
        <NotifyUserOfEvent
          message={response}
          open={!!response}
          close={() => setResponse('')}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={withdrawing || requestingVerificationCode}
          type='button'
          variant='contained'
          onClick={sendCode}
          color='secondary'
        >
          Resend Code
        </Button>
        <Button
          disabled={withdrawing || requestingVerificationCode || !verified}
          type='submit'
          color='primary'
          form='withdrawal'
          variant='contained'
        >
          {withdrawing ? <CircularIndeterminateSm /> : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function NetworkBalance() {
  const {
    networkBalance: {
      walletDetails: { availableBalance, totalBalance },
    },
  } = useContext(WalletContext);
  const { totalBalanceMargin } = useStyles();
  return (
    <MenuItem button={false}>
      <div className={messageStyles.messageInfo}>
        <ListItemText
          secondary='Network Balance'
          primary={
            <>
              <Typography className={totalBalanceMargin}>
                Total:{' '}
                {currencyFormatter({
                  value: totalBalance,
                })}
              </Typography>
              <Typography>
                Available:{' '}
                {currencyFormatter({
                  value: availableBalance,
                })}
              </Typography>
            </>
          }
        />
      </div>
    </MenuItem>
  );
}

function FeedbackForm({ handleClose }) {
  const [feedback, setFeedback] = useState('');
  const [sendingFeedback, setSendingFeedback] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const sendFeedback = async () => {
    try {
      setErrorMessage('');
      setSuccessMessage('');
      setSendingFeedback(true);
      await http.post(userRequests.SUPPORT, { message: feedback });
      setSuccessMessage(
        'Message sent successfully. We will be in touch shortly.'
      );
      setSendingFeedback(false);
    } catch (error) {
      const err = extractErrorMessage(error);
      setErrorMessage(err);
      setSendingFeedback(false);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        sendFeedback();
      }}
      id='feedback-form'
      style={{ display: 'block', padding: 10 }}
    >
      {errorMessage ? <Alert severity='error'>{errorMessage}</Alert> : null}
      {successMessage ? (
        <Alert severity='success'>{successMessage}</Alert>
      ) : null}
      <fieldset>
        {errorMessage || successMessage ? null : (
          <legend>Create a ticket</legend>
        )}
        <FormControl required style={{ marginBottom: 5, width: '100%' }}>
          <FormLabel>Message</FormLabel>
          <TextField
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            fullWidth
            required
            placeholder='How can we help you today?'
            multiline
            rows={3}
          />
        </FormControl>
      </fieldset>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button onClick={handleClose} color='secondary' variant='contained'>
          Cancel
        </Button>
        <Button
          disabled={sendingFeedback}
          color='primary'
          variant='contained'
          type='submit'
        >
          {sendingFeedback ? <CircularIndeterminateSm size='16px' /> : 'Submit'}
        </Button>
      </div>
    </form>
  );
}

function UserMenu({ classes, dark }) {
  const customStyle = useStyles();
  const [, , removeCookie] = useCookies();
  const dispatch = useDispatch();
  const [unReadMessages, setUnreadMessages] = useState(0);
  const [unReadEmails, setUnreadEmails] = useState(0);
  const isMobile = useMobile();
  const {
    state: { verified },
  } = useContext(UserContext);
  const {
    personalBalance: {
      walletDetails: { availableBalance },
    },
    networkBalance: {
      walletDetails: { availableBalance: networkAvailableBalance },
    },
    refetch,
  } = useContext(WalletContext);
  const [menuState, setMenuState] = useState({
    anchorEl: null,
    openMenu: null,
  });
  const [userId, setId] = useState(null);
  const profile = useProfile();
  const network = useNetwork();
  const {
    state: { profile: profileData },
  } = useContext(UserContext);
  const [email, setEmail] = useState('');
  const [openFundWallet, setOpenFundWallet] = useState(false);
  const { withdrawRequestButton, fundingRequestButton } = useStyles();
  const [response, setResponse] = useState('');
  const [requestingVerificationCode, setRequestingVerificationCode] = useState(
    false
  );
  const [showWithdrawalDialog, setShowWithdrawalDialog] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [openIdVerification, setOpenIdVerification] = useState(false);

  const requestWithdrawal = async () => {
    try {
      setRequestingVerificationCode(true);
      await http.post(authRequests.SEND_VERIFICATION_CODE(email));
      setRequestingVerificationCode(false);
      setShowWithdrawalDialog(true);
    } catch (error) {
      const message = extractErrorMessage(error);
      setResponse(message);
      setRequestingVerificationCode(false);
    }
  };

  useEffect(() => {
    const socket = io(ENDPOINT, {
      path: '/socket/messages',
      transports: ['websocket', 'polling'],
      auth: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    socket.on('connect', () => {
      console.log('Websocket connection successful');
      dispatch(setConnection(socket));
    });
    socket.on(`unread_messages_${userId}`, (count) => {
      setUnreadMessages(count);
      dispatch(setUnreadMessagesAction(count));
    });

    socket.on('unread_emails', (count) => {
      dispatch(setUnreadEmailsAction(count));
      setUnreadEmails(count);
    });

    socket.on('unseen_deals', (unseen_deals) => {
      dispatch(setUnreadDealsAction(unseen_deals));
    });

    socket.on('pending_applications', (count) => {
      dispatch(setUnreadApplicationsAction(count));
    });
  }, [userId]);

  const handleMenu = (menu) => (event) => {
    const { openMenu } = menuState;
    setMenuState({
      openMenu: openMenu === menu ? null : menu,
      anchorEl: event.currentTarget,
    });
  };

  const handleClose = () => {
    setMenuState({ anchorEl: null, openMenu: null });
  };

  const logoutUser = () => {
    dispatch(logout());
    removeCookie(LOGGED_IN_STATE_KEY, {
      ...(process.env.NODE_ENV === 'production' && {
        domain: '.conectivest.com',
      }),
      secure: true,
      path: '/',
    });
    setMenuState({ anchorEl: null, openMenu: null });
  };

  const { anchorEl, openMenu } = menuState;

  const appsContainerRef = useRef();
  useOutsideClick(appsContainerRef, handleClose);

  useEffect(() => {
    setId(profile.id);
    setEmail(profile.email);
  }, [profile]);

  useEffect(() => {
    setIsSuperAdmin(!!network.isSuperAdmin);
  }, [network]);

  return (
    <div style={{ position: 'relative' }}>
      <NotifyUserOfEvent
        message={response}
        open={!!response}
        close={() => setResponse('')}
      />
      <WithdrawalDialog
        open={showWithdrawalDialog}
        close={() => setShowWithdrawalDialog(false)}
        email={email}
        networkId={network.networkId}
      />
      {isSuperAdmin && !isMobile ? (
        <Link to='/app/pages/email' className={customStyle.link}>
          <IconButton color='inherit'>
            <Badge
              className={`${classes.badge} ${customStyle.badges}`}
              badgeContent={unReadEmails}
              color='secondary'
            >
              <i className='class= ion-ios-email-outline' />
            </Badge>
          </IconButton>
        </Link>
      ) : null}
      <Link to='/app/pages/chat' className={customStyle.link}>
        <IconButton color='inherit'>
          <Badge
            className={`${classes.badge} ${customStyle.badges}`}
            badgeContent={unReadMessages}
            color='secondary'
          >
            <i className='icon ion-ios-chatbubble-outline' />
          </Badge>
        </IconButton>
      </Link>
      <IconButton
        aria-haspopup='true'
        id='wallet-button'
        aria-label='Show wallet balance'
        onClick={handleMenu('notification')}
        color='inherit'
        className={classNames(
          classes.notifIcon,
          dark ? classes.dark : classes.light
        )}
        title='Show wallet balance'
      >
        <LocalAtm />
      </IconButton>
      <IconButton
        aria-haspopup='true'
        onClick={handleMenu('apps')}
        color='inherit'
        aria-describedby='apps-menu'
        title='Conectivest Apps'
        aria-label='Conectivest Apps'
        id='apps-container'
        className={classNames(
          classes.notifIcon,
          dark ? classes.dark : classes.light
        )}
      >
        <AppsIcon />
      </IconButton>
      <IconButton
        aria-haspopup='true'
        aria-label='Contact support'
        onClick={handleMenu('feedback')}
        color='inherit'
        className={classNames(
          classes.notifIcon,
          dark ? classes.dark : classes.light
        )}
        title='Contact support.'
      >
        <Help />
      </IconButton>
      <Button onClick={handleMenu('user-setting')}>
        <Avatar
          alt={profile.firstName || dummy.user.avatar}
          src={profileData.avatar || dummy.user.avatar}
        />
      </Button>
      <Menu
        id='menu-notification'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className={classes.notifMenu}
        PaperProps={{
          style: {
            width: 350,
          },
        }}
        open={openMenu === 'notification'}
        onClose={handleClose}
      >
        <MenuItem button={false}>
          <div className={messageStyles.messageInfo}>
            {/* <ListItemAvatar>
              <Avatar alt='User Name' src={avatarApi[0]} />
            </ListItemAvatar> */}
            <ListItemText
              secondary='Personal Balance'
              primary={currencyFormatter({
                value: availableBalance,
              })}
            />
            <IconButton
              onClick={() => {
                dispatch(fetchProfile());
                refetch();
              }}
              style={{ marginLeft: 150 }}
            >
              <Refresh />
            </IconButton>
          </div>
        </MenuItem>
        {isSuperAdmin ? <NetworkBalance messageStyles={messageStyles} /> : null}
        <Divider variant='inset' />
        <div
          style={{
            padding: 5,
            display: 'flex',
          }}
        >
          <Button
            onClick={() => {
              handleClose();
              setOpenFundWallet(true);
            }}
            className={fundingRequestButton}
          >
            Fund Wallet
          </Button>
          {Number(networkAvailableBalance) && isSuperAdmin ? (
            <Button
              onClick={requestWithdrawal}
              disabled={requestingVerificationCode}
              className={withdrawRequestButton}
              style={{ marginLeft: 30 }}
              size='small'
            >
              {requestingVerificationCode ? (
                <CircularIndeterminateSm />
              ) : (
                'Withdraw'
              )}
            </Button>
          ) : null}
        </div>
      </Menu>
      <Menu
        id='menu-feedback'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className={classes.notifMenu}
        PaperProps={{
          style: {
            width: 350,
          },
        }}
        open={openMenu === 'feedback'}
        onClose={handleClose}
      >
        <FeedbackForm handleClose={handleClose} />
      </Menu>
      {openMenu === 'apps' ? (
        <div className={classes.appsArea} ref={appsContainerRef}>
          <Apps />
        </div>
      ) : null}
      <Menu
        id='menu-appbar'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={openMenu === 'user-setting'}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} component={Link} to={link.profile}>
          Profile
        </MenuItem>
        {/* <MenuItem onClick={handleClose} component={Link} to={link.calendar}>
          My Calendar
        </MenuItem> */}
        {isSuperAdmin && (
          <MenuItem onClick={handleClose} component={Link} to={link.email}>
            Inbox
            <ListItemIcon>
              <Badge
                className={classNames(classes.badge, classes.badgeMenu)}
                // badgeContent={2}
                color='secondary'
              />
            </ListItemIcon>
          </MenuItem>
        )}
        <MenuItem onClick={handleClose} component={Link} to={link.settings}>
          Billing
        </MenuItem>
        {!verified ? (
          <MenuItem onClick={() => setOpenIdVerification(true)}>
            Verify Identity
          </MenuItem>
        ) : null}
        <Divider />
        <MenuItem onClick={() => logoutUser()}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          Sign Out
        </MenuItem>
      </Menu>
      <FundWallet
        open={openFundWallet}
        onClose={() => setOpenFundWallet(false)}
      />
      <VerifyIdentity
        open={openIdVerification}
        close={() => setOpenIdVerification(false)}
      />
    </div>
  );
}

UserMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  dark: PropTypes.bool,
};

UserMenu.defaultProps = {
  dark: false,
};

const mapStateToProps = (state) => ({
  force: state, // force state from reducer
  profile: state.toJS().profile.data,
  groupData: state.toJS().network.groups.data,
});

const UserProfileMapped = connect(mapStateToProps)(UserMenu);

export default withStyles(styles)(UserProfileMapped);
