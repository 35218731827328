import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import NotFound from 'containers/Pages/Standalone/NotFoundDedicated';
import { useCookies } from 'react-cookie';
import Auth from './Auth';
import Application from './Application';
import LandingCorporate from './Landing';
// import ArticleNews from './ArticleNews';
import ThemeWrapper from './ThemeWrapper';

import ProtectedRoute from '../../utils/ProtectedRoute';
import { LOGGED_IN_STATE_KEY } from '../../redux/constants';
import { PENDING_PATH_KEY } from '../../utils/constants';
import Holding from './Holding';
import { useSearchParams } from '../../utils/components/hooks';
import { NotifyUserOfEvent } from '../../redux/api/EventNotifier';
// import globalStyles from '../../styles/global.css';
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

function App() {
  const redirectTo = localStorage.getItem(PENDING_PATH_KEY) || '/app';
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [cookies] = useCookies();
  const error = useSearchParams('error');
  const success = useSearchParams('success');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (!cookies[LOGGED_IN_STATE_KEY] && !shouldRedirect) {
      localStorage.setItem(PENDING_PATH_KEY, window.location.pathname);
      setShouldRedirect(true);
    }
  }, [cookies, shouldRedirect]);

  useEffect(() => {
    if (error) {
      setMessage('Error processing payment. Please try again.');
    }

    if (success) {
      setMessage(
        'Payment successfully initiated. We will notify you of the status of your payment once it is resolved.'
      );
    }
  }, [error, success]);

  if (!cookies[LOGGED_IN_STATE_KEY] && !shouldRedirect) {
    return (
      <ThemeWrapper>
        <Switch>
          <Route component={Holding} />
          <Route component={NotFound} />
        </Switch>
      </ThemeWrapper>
    );
  }

  if (!cookies[LOGGED_IN_STATE_KEY] && shouldRedirect) {
    return (
      <ThemeWrapper>
        <Switch>
          <Route component={Auth} />
          <Route component={NotFound} />
        </Switch>
      </ThemeWrapper>
    );
  }

  return (
    <ThemeWrapper>
      <Switch>
        <Redirect
          exact
          from='/login'
          to={
            redirectTo !== '/login' && redirectTo !== '/' ? redirectTo : '/app'
          }
        />
        <ProtectedRoute path='/app' component={Application} />
        <Redirect
          exact
          from='/'
          to={
            redirectTo !== '/login' && redirectTo !== '/' ? redirectTo : '/app'
          }
        />
        <Route component={NotFound} />
      </Switch>
      <NotifyUserOfEvent
        open={!!message}
        message={message}
        close={() => setMessage('')}
      />
    </ThemeWrapper>
  );
}

export default App;
