export default function isImage(file) {
  const fileName = file.fileName || file.path;
  const extension = fileName.split('.').pop();
  if (
    extension === 'jpg' ||
    extension === 'jpeg' ||
    extension === 'bmp' ||
    extension === 'png' ||
    extension === 'svg'
  ) {
    return true;
  }
  return false;
}
