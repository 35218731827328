/* eslint-disable import/prefer-default-export */
export function extractErrorMessage(error) {
  if (typeof error === 'string') return error;

  if (error.response) {
    if (error.response.data) {
      if (typeof error.response.data === 'string') {
        return error.response.data;
      }

      if (typeof error.response.data.error === 'string') {
        return error.response.data.error;
      }

      if (error.response.data.error && error.response.data.error.error) {
        return error.response.data.error.error;
      }

      return 'Something went wrong. Please try again.';
    }
    return 'Something went wrong. Please try again.';
  }

  return (
    error.error || error.message || 'Something went wrong. Please try again.'
  );
}
