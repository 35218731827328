import { call, put, takeLatest } from 'redux-saga/effects';
import safeSaga from '../../utils/safeSaga';

import http from '../api';
import { networkRequests, PAYMENT_REQUESTS } from '../api/requests';
import {
  FETCH_NETWORKS_ERROR,
  FETCH_NETWORKS_REQUEST,
  FETCH_NETWORKS_SUCCESS,
  FETCH_NETWORK_MEMBERS_ERROR,
  FETCH_NETWORK_MEMBERS_REQUEST,
  FETCH_NETWORK_MEMBERS_SUCCESS,
  FETCH_NETWORK_MEMBER_ERROR,
  FETCH_NETWORK_MEMBER_REQUEST,
  FETCH_NETWORK_MEMBER_SUCCESS,
  FETCH_NETWORKS_APPLICATIONS_SUCCESS,
  FETCH_NETWORKS_APPLICATIONS_ERROR,
  FETCH_NETWORKS_APPLICATIONS_REQUEST,
  FETCH_NETWORKS_MEMBER_APPLICATIONS_ERROR,
  FETCH_NETWORKS_MEMBER_APPLICATIONS_SUCCESS,
  FETCH_NETWORKS_MEMBER_APPLICATION_REQUEST,
  INVITE_MEMBERS_ERROR,
  INVITE_MEMBERS_REQUEST,
  INVITE_MEMBERS_SUCCESS,
  UPDATE_APPLICATION_REQUEST,
  UPDATE_APPLICATION_ERROR,
  UPDATE_APPLICATION_SUCCESS,
  DELETE_APPLICATION_ERROR,
  DELETE_APPLICATION_REQUEST,
  DELETE_APPLICATION_SUCCESS,
  CREATE_GROUP_ERROR,
  CREATE_GROUP_REQUEST,
  CREATE_GROUP_SUCCESS,
  FETCH_INVITES_ERROR,
  FETCH_INVITES_REQUEST,
  FETCH_INVITES_SUCCESS,
  ADD_MEMBERS_ERROR,
  ADD_MEMBERS_REQUEST,
  ADD_MEMBERS_SUCCESS,
  REMOVE_MEMBERS_ERROR,
  REMOVE_MEMBERS_REQUEST,
  REMOVE_MEMBERS_SUCCESS,
  FETCH_GROUP_ERROR,
  FETCH_GROUP_REQUEST,
  FETCH_GROUP_SUCCESS,
  DELETE_GROUP_ERROR,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  UPDATE_GROUP_ERROR,
  UPDATE_GROUP_REQUEST,
  UPDATE_GROUP_SUCCESS,
  FETCH_DEALFLOW,
  FETCH_DEALFLOW_ERROR,
  FETCH_DEALFLOW_SUCCESS,
  FETCH_DEAL,
  FETCH_DEAL_ERROR,
  FETCH_DEAL_SUCCESS,
  FETCH_SINGLE_DEAL,
  FETCH_SINGLE_DEAL_ERROR,
  FETCH_SINGLE_DEAL_SUCCESS,
  FETCH_RECENT_ACTIVITIES,
  FETCH_RECENT_ACTIVITIES_ERROR,
  FETCH_RECENT_ACTIVITIES_SUCCESS,
  FETCH_COMMENTS_SUCCESS,
  FETCH_COMMENTS,
  FETCH_COMMENTS_ERROR,
  ADD_COMMENT,
  ADD_COMMENT_ERROR,
  ADD_COMMENT_SUCCESS,
  TOGGLE_INTEREST,
  TOGGLE_INTEREST_ERROR,
  TOGGLE_INTEREST_SUCCESS,
  GET_ALL_DEAL_INTEREST,
  GET_ALL_DEAL_INTEREST_SUCCESS,
  GET_ALL_DEAL_INTEREST_ERROR,
  CLOSE_DEAL,
  CLOSE_DEAL_ERROR,
  CLOSE_DEAL_SUCCESS,
} from '../types';

function* fetchNetworks() {
  const { data } = yield call([http, 'get'], networkRequests.NETWORKS);
  yield put({
    type: FETCH_NETWORKS_SUCCESS,
    payload: data,
  });
}

function* inviteMembers({ payload: { networkId, ...payload } }) {
  const { data } = yield call(
    [http, 'post'],
    `${networkRequests.INVITE}${networkId}`,
    payload
  );
  yield put({
    type: INVITE_MEMBERS_SUCCESS,
    payload: data,
  });
}

function* getInvitees({ payload: { networkId } }) {
  const { data } = yield call(
    [http, 'get'],
    networkRequests.FETCH_INVITES(networkId)
  );
  yield put({
    type: FETCH_INVITES_SUCCESS,
    payload: data,
  });
}

function* fetchNetworkMembers({ payload: { networkId } }) {
  const { data } = yield call(
    [http, 'get'],
    `${networkRequests.FETCH_MEMBERS(networkId)}`
  );
  yield put({
    type: FETCH_NETWORK_MEMBERS_SUCCESS,
    payload: data,
  });
}

function* fetchNetworkMember({ payload: { userId, networkId } }) {
  const { data } = yield call(
    [http, 'get'],
    networkRequests.FETCH_MEMBER(userId, networkId)
  );
  yield put({
    type: FETCH_NETWORK_MEMBER_SUCCESS,
    payload: data,
  });
}

function* fetchNetworkApplications({ payload: { networkId } }) {
  const { data } = yield call(
    [http, 'get'],
    networkRequests.FETCH_NETWORK_APPLICATIONS(networkId)
  );
  yield put({
    type: FETCH_NETWORKS_APPLICATIONS_SUCCESS,
    payload: data,
  });
}

function* fetchNetworkmemberApplication({ payload: { appId, networkId } }) {
  const { data } = yield call(
    [http, 'get'],
    networkRequests.FETCH_NETWORK_MEMBER_APPLICATION(appId, networkId)
  );
  yield put({
    type: FETCH_NETWORKS_MEMBER_APPLICATIONS_SUCCESS,
    payload: data,
  });
}

function* updateApplicationStatus({ payload: { appId, networkId, value } }) {
  const { data } = yield call(
    [http, 'post'],
    networkRequests.UPDATE_APPLICATION(appId, networkId, value)
  );
  yield put({
    type: UPDATE_APPLICATION_SUCCESS,
    payload: data,
  });
  if (data) {
    window.location.href = '/app/members';
  }
}

function* deleteNetworkApplication({ payload: { appId, networkId } }) {
  const { data } = yield call(
    [http, 'delete'],
    networkRequests.DELETE_APPLICATION(appId, networkId)
  );
  yield put({
    type: DELETE_APPLICATION_SUCCESS,
    payload: data,
  });
  if (data) {
    window.location.href = '/app/members';
  }
}

function* createGroup({ payload: { ...payload } }) {
  const { data } = yield call(
    [http, 'post'],
    networkRequests.CREATE_GROUP,
    payload
  );
  yield put({
    type: CREATE_GROUP_SUCCESS,
    payload: data,
  });
}

// starts here
function* addMemberToGroup({ payload: { groupId, ...payload } }) {
  const { data } = yield call(
    [http, 'post'],
    networkRequests.ADD_MEMBER,
    payload
  );
  yield put({
    type: ADD_MEMBERS_SUCCESS,
    payload: data,
  });
  if (data) {
    window.location.href = '/app/members';
  }
}

function* removeMemberFromGroup({ payload: { groupId, ...payload } }) {
  const { data } = yield call(
    [http, 'delete'],
    networkRequests.REMOVE_MEMBER(groupId),
    payload
  );
  yield put({
    type: REMOVE_MEMBERS_SUCCESS,
    payload: data,
  });
  if (data) {
    window.location.href = '/app/members';
  }
}

function* deleteGroup({ payload: { groupId, networkId } }) {
  const { data } = yield call(
    [http, 'delete'],
    networkRequests.DELETE_GROUPS(groupId, networkId)
  );
  yield put({
    type: DELETE_GROUP_SUCCESS,
    payload: data,
  });
  if (data) {
    window.location.href = '/app/members';
  }
}

function* updateGroup({ payload: { groupId, ...payload } }) {
  const { data } = yield call(
    [http, 'put'],
    networkRequests.UPDATE_GROUPS(groupId),
    payload
  );
  yield put({
    type: UPDATE_GROUP_SUCCESS,
    payload: data,
  });
  if (data) {
    window.location.href = '/app/members';
  }
}

function* getGroups() {
  const { data } = yield call([http, 'get'], networkRequests.GET_GROUPS);
  yield put({
    type: FETCH_GROUP_SUCCESS,
    payload: data,
  });
}

function* getDealflow({ payload: { networkId } }) {
  const { data } = yield call(
    [http, 'get'],
    networkRequests.FETCH_DEALFLOW(networkId)
  );
  yield put({
    type: FETCH_DEALFLOW_SUCCESS,
    payload: data,
  });
}

function* getRecentActivities({ payload: { networkId } }) {
  const { data } = yield call(
    [http, 'get'],
    networkRequests.FETCH_RECENT_ACTIVITIES(networkId)
  );
  yield put({
    type: FETCH_RECENT_ACTIVITIES_SUCCESS,
    payload: data,
  });
}

function* getAllDeals({ payload: { networkId, dealflowId } }) {
  const { data } = yield call(
    [http, 'get'],
    networkRequests.FETCH_DEALS(networkId, dealflowId)
  );
  yield put({
    type: FETCH_DEAL_SUCCESS,
    payload: data,
  });
}
function* getSingleDeal({ payload: { networkId, dealflowId, dealId } }) {
  const { data } = yield call(
    [http, 'get'],
    networkRequests.FETCH_DEAL(networkId, dealflowId, dealId)
  );
  yield put({
    type: FETCH_SINGLE_DEAL_SUCCESS,
    payload: data,
  });
}

function* getComments({ payload: { networkId, dealflowId, dealId } }) {
  const { data } = yield call(
    [http, 'get'],
    networkRequests.FETCH_COMMENTS_IN_DEAL(networkId, dealflowId, dealId)
  );
  yield put({
    type: FETCH_COMMENTS_SUCCESS,
    payload: data,
  });
}

function* addComment({ payload: { ...payload } }) {
  const { data } = yield call(
    [http, 'post'],
    `${networkRequests.ADD_COMMENT_TO_DEAL}`,
    payload
  );
  yield put({
    type: ADD_COMMENT_SUCCESS,
    payload: data,
  });
}

function* toggleInterest({ payload: { ...payload } }) {
  const { data } = yield call(
    [http, 'post'],
    `${networkRequests.TOGGLE_INTEREST}`,
    payload
  );
  yield put({
    type: TOGGLE_INTEREST_SUCCESS,
    payload: data,
  });
}
function* getDealInterest({ payload: { networkId, dealflowId, dealId } }) {
  const { data } = yield call(
    [http, 'get'],
    networkRequests.GET_ALL_INTEREST_IN_DEAL(networkId, dealflowId, dealId)
  );
  yield put({
    type: GET_ALL_DEAL_INTEREST_SUCCESS,
    payload: data,
  });
}

function* closeDeal({ payload: { networkId, dealId, setOpen, setMsg } }) {
  const { data } = yield call(
    [http, 'post'],
    PAYMENT_REQUESTS.payoutDeal(dealId),
    { networkId }
  );
  yield put({
    type: CLOSE_DEAL_SUCCESS,
    payload: data,
  });
  setOpen(true);
  setMsg('Deal has been closed successfully');
}

export default function* networksSaga() {
  yield takeLatest(
    FETCH_NETWORKS_REQUEST,
    safeSaga(fetchNetworks, FETCH_NETWORKS_ERROR)
  );
  yield takeLatest(
    INVITE_MEMBERS_REQUEST,
    safeSaga(inviteMembers, INVITE_MEMBERS_ERROR)
  );
  yield takeLatest(
    FETCH_NETWORK_MEMBERS_REQUEST,
    safeSaga(fetchNetworkMembers, FETCH_NETWORK_MEMBERS_ERROR)
  );
  yield takeLatest(
    FETCH_NETWORK_MEMBER_REQUEST,
    safeSaga(fetchNetworkMember, FETCH_NETWORK_MEMBER_ERROR)
  );
  yield takeLatest(
    FETCH_NETWORKS_APPLICATIONS_REQUEST,
    safeSaga(fetchNetworkApplications, FETCH_NETWORKS_APPLICATIONS_ERROR)
  );
  yield takeLatest(
    FETCH_NETWORKS_MEMBER_APPLICATION_REQUEST,
    safeSaga(
      fetchNetworkmemberApplication,
      FETCH_NETWORKS_MEMBER_APPLICATIONS_ERROR
    )
  );
  yield takeLatest(
    UPDATE_APPLICATION_REQUEST,
    safeSaga(updateApplicationStatus, UPDATE_APPLICATION_ERROR)
  );
  yield takeLatest(
    DELETE_APPLICATION_REQUEST,
    safeSaga(deleteNetworkApplication, DELETE_APPLICATION_ERROR)
  );
  yield takeLatest(
    CREATE_GROUP_REQUEST,
    safeSaga(createGroup, CREATE_GROUP_ERROR)
  );
  yield takeLatest(
    FETCH_INVITES_REQUEST,
    safeSaga(getInvitees, FETCH_INVITES_ERROR)
  );

  yield takeLatest(
    ADD_MEMBERS_REQUEST,
    safeSaga(addMemberToGroup, ADD_MEMBERS_ERROR)
  );

  yield takeLatest(
    REMOVE_MEMBERS_REQUEST,
    safeSaga(removeMemberFromGroup, REMOVE_MEMBERS_ERROR)
  );

  yield takeLatest(
    DELETE_GROUP_REQUEST,
    safeSaga(deleteGroup, DELETE_GROUP_ERROR)
  );

  yield takeLatest(
    UPDATE_GROUP_REQUEST,
    safeSaga(updateGroup, UPDATE_GROUP_ERROR)
  );

  yield takeLatest(FETCH_DEALFLOW, safeSaga(getDealflow, FETCH_DEALFLOW_ERROR));

  yield takeLatest(FETCH_DEAL, safeSaga(getAllDeals, FETCH_DEAL_ERROR));

  yield takeLatest(
    FETCH_SINGLE_DEAL,
    safeSaga(getSingleDeal, FETCH_SINGLE_DEAL_ERROR)
  );

  yield takeLatest(FETCH_COMMENTS, safeSaga(getComments, FETCH_COMMENTS_ERROR));

  yield takeLatest(ADD_COMMENT, safeSaga(addComment, ADD_COMMENT_ERROR));

  yield takeLatest(
    FETCH_RECENT_ACTIVITIES,
    safeSaga(getRecentActivities, FETCH_RECENT_ACTIVITIES_ERROR)
  );
  yield takeLatest(
    TOGGLE_INTEREST,
    safeSaga(toggleInterest, TOGGLE_INTEREST_ERROR)
  );
  yield takeLatest(
    GET_ALL_DEAL_INTEREST,
    safeSaga(getDealInterest, GET_ALL_DEAL_INTEREST_ERROR)
  );
  yield takeLatest(FETCH_GROUP_REQUEST, safeSaga(getGroups, FETCH_GROUP_ERROR));
  yield takeLatest(CLOSE_DEAL, safeSaga(closeDeal, CLOSE_DEAL_ERROR));
}
