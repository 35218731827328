import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
  progress: {
    // margin: theme.spacing(2),
    margin: '0px',
  },
});

function CircularIndeterminate(props) {
  const { classes } = props;
  return (
    <div>
      <CircularProgress className={classes.progress} />
    </div>
  );
}

function CircularIndeterminateSmall(props) {
  const { classes, size = '24px', color = 'white' } = props;
  return (
    <div>
      <CircularProgress
        className={classes.progress}
        style={{
          width: size,
          height: size,
          color,
        }}
      />
    </div>
  );
}

CircularIndeterminate.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const CircularIndeterminateSm = withStyles(styles)(
  CircularIndeterminateSmall
);

export default withStyles(styles)(CircularIndeterminate);
