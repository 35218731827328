exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);", ""]);

// Module
exports.push([module.id, "/**\n * Retrieves a color value from the $material-colors Sass map\n * Inspired by https://github.com/nilskaspersson/Google-Material-UI-Color-Palette/\n *\n * @function\n * @param {string} color-name             - quoted, lower-case, dasherized color\n *                                          name (e.g. 'pink', 'amber')\n * @param {string} [color-variant='500']  - quoted, lowercase color variant (e.g.\n *                                          '200', 'a100')\n */\n.light__1fIWD {\n  font-weight: 300;\n}\n\n.regular__3i1ht {\n  font-weight: 400;\n}\n\n.medium__8Walv {\n  font-weight: 600;\n}\n\n.bold__2oOfZ {\n  font-weight: 700;\n}\n\n.bolder__2Ahkg {\n  font-weight: 800;\n}\n\n.italic__3wapO {\n  font-style: italic;\n}\n\n.underline__3veH1 {\n  text-decoration: underline;\n}\n\n.lineThrought__3KXiz {\n  text-decoration: line-through;\n}\n\n.textInfo__3PuF8 {\n  color: #2196f3 !important;\n}\n\n.textSuccess__3WjEm {\n  color: #4caf50 !important;\n}\n\n.textWarning__1P4w6 {\n  color: #ff9800 !important;\n}\n\n.textError__24NYY {\n  color: #f44336 !important;\n}\n\n.textGreyDark__20zE3 {\n  color: #263238 !important;\n}\n\n.textGrey__2Omhn {\n  color: #78909c !important;\n}\n\n.textGreyLight__1NTZy {\n  color: #cfd8dc !important;\n}\n\n.capitalyze__s5iuX {\n  text-transform: capitalize;\n}\n\n.uppercase__GtO__ {\n  text-transform: uppercase;\n}\n\n.lowercase__38tCP {\n  text-transform: lowercase;\n}\n\n.textLeft__Bv64W {\n  text-align: left;\n}\n\n.textRight__2wP4z {\n  text-align: right;\n}\n\n.textCenter__1h8AK {\n  text-align: center;\n}\n\n.textJustify__ZPkE2 {\n  text-align: justify;\n}\n\n.list__1KyBZ {\n  list-style: disc;\n  margin-left: 20px;\n}\n\n.list__1KyBZ ul {\n  list-style: circle;\n  margin-left: 20px;\n}\n\n.orderedlist__3Rx3l {\n  list-style: decimal;\n  margin-left: 20px;\n}\n\n.orderedlist__3Rx3l ul {\n  list-style: lower-alpha;\n  margin-left: 20px;\n}\n", ""]);

// Exports
exports.locals = {
	"light": "light__1fIWD",
	"regular": "regular__3i1ht",
	"medium": "medium__8Walv",
	"bold": "bold__2oOfZ",
	"bolder": "bolder__2Ahkg",
	"italic": "italic__3wapO",
	"underline": "underline__3veH1",
	"lineThrought": "lineThrought__3KXiz",
	"textInfo": "textInfo__3PuF8",
	"textSuccess": "textSuccess__3WjEm",
	"textWarning": "textWarning__1P4w6",
	"textError": "textError__24NYY",
	"textGreyDark": "textGreyDark__20zE3",
	"textGrey": "textGrey__2Omhn",
	"textGreyLight": "textGreyLight__1NTZy",
	"capitalyze": "capitalyze__s5iuX",
	"uppercase": "uppercase__GtO__",
	"lowercase": "lowercase__38tCP",
	"textLeft": "textLeft__Bv64W",
	"textRight": "textRight__2wP4z",
	"textCenter": "textCenter__1h8AK",
	"textJustify": "textJustify__ZPkE2",
	"list": "list__1KyBZ",
	"orderedlist": "orderedlist__3Rx3l"
};