import React, { useEffect } from 'react';
import { useNetworkWallet, useWallet } from '../utils/components/hooks';

export const WalletContext = React.createContext({
  networkBalance: {
    networkId: '',
    walletDetails: {
      totalBalance: '0',
      availableBalance: '0',
      id: '',
      status: '',
    },
  },
  personalBalance: {
    userId: '',
    walletDetails: {
      totalBalance: '0',
      availableBalance: '0',
      id: '',
    },
  },
  refetch: () => null,
});

export function WalletProvider({ children }) {
  const [reload, setReload] = React.useState('');
  const personalBalance = useWallet(reload);
  const networkBalance = useNetworkWallet(reload);
  const value = React.useMemo(
    () => ({
      networkBalance,
      personalBalance,
      refetch: () => setReload(Math.random()),
    }),
    [reload, personalBalance, networkBalance]
  );

  return (
    <WalletContext.Provider value={value}>{children}</WalletContext.Provider>
  );
}
