export const commonState = {
  processing: false,
  processed: false,
  success: false,
  errors: {},
  message: '',
};

/**
 * Tells us if a user is logged in or not.
 * @constant
 */
export const LOGGED_IN_STATE_KEY = 'isLoggedIn';

/**
 * Tells us if a user is logged in or not.
 * @constant
 */
export const OLD_LOGIN_KEY = 'token';
