export const FETCH_CONTACT_DATA = 'FETCH_CONTACT_DATA';
export const SHOW_DETAIL_CONTACT = 'SHOW_DETAIL_CONTACT';
export const HIDE_DETAIL = 'HIDE_DETAIL';
export const ADD_CONTACT = 'ADD_CONTACT';
export const EDIT_CONTACT = 'EDIT_CONTACT';
export const SUBMIT_CONTACT = 'SUBMIT_CONTACT';
export const CLOSE_CONTACT_FORM = 'CLOSE_CONTACT_FORM';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const TOGGLE_FAVORITE = 'TOGGLE_FAVORITE';
export const SEARCH_CONTACT = 'SEARCH_CONTACT';
