/* eslint-disable import/prefer-default-export */
import React from 'react';
import { IconButton, colors } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const { grey } = colors;

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: grey[500],
  },
}));

export function CloseDialogButton({ onClick }) {
  const classes = useStyles();
  return (
    <IconButton
      aria-label='close'
      className={classes.closeButton}
      onClick={onClick}
    >
      <CloseIcon />
    </IconButton>
  );
}

CloseDialogButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
