const guideSteps = [
  {
    title: 'Welcome to Conectivest for Angel Investors',
    label:
      'Here is some guide to use this Application. Click next to continue and back to previous or click on outside to skip it.',
    imgPath:
      'https://conectivest-erp.s3.eu-west-2.amazonaws.com/others/37623129-9537-4092-8814-a3e585aaba6a-.sav.conectivest_latest_logo.ease_access_and_value_Africa.png',
  },
  {
    title: 'Enable Notifications',
    label:
      'Enable notifications so we can send you real time information about events on your network.',
    imgPath:
      'https://attachment.conectivest.com/6aa9d023-9589-4db2-ae3d-600fc24f883c-notification.settings.gif',
  },
  {
    title: 'Inviting Other Network Members',
    label:
      'Increase your ease of collaboration and engagements by inviting your network members.',
    imgPath:
      'https://attachment.conectivest.com/4ccb69d2-363e-43ea-b8f1-53a7b0177fd7-invite.gif',
  },

  {
    title: 'Confirming Invited Network Members',
    label: 'Be in control, confirm invited members of your network. ',
    imgPath:
      'https://attachment.conectivest.com/a6541d7f-a937-4f48-8891-bce327656f06-confirm.gif',
  },

  {
    title: 'Searching and Viewing Profile Details of Network Members',
    label: 'See details and interests of each member of your network. ',
    imgPath:
      'https://attachment.conectivest.com/714cedb5-779a-4b5f-9c11-413f9fdc00ba-profile.view.gif',
  },

  {
    title: 'Suspending Member Access to Future Deals',
    label: 'Control membership access of your members. ',
    imgPath:
      'https://attachment.conectivest.com/c7d73464-a31b-4b16-86b8-7322891ece1d-suspend.gif',
  },
  {
    title: 'Re-activating Suspended Network Members ',
    label: 'Re-activate suspended network members at your convenience. ',
    imgPath:
      'https://attachment.conectivest.com/21092b47-0ed1-4b78-929a-b2d1c3f4f022-activate.gif',
  },

  {
    title: 'Creating a Group for Interested Members of a Deal',
    label:
      'Get everyone syndicating a deal on the same page by creating a group for easy chatting and communication. ',
    imgPath:
      'https://attachment.conectivest.com/cbba4d4c-97c8-4b06-a600-ed9d0a510f55-create.group.gif',
  },

  {
    title: 'Starting a Chat Session with Group Members ',
    label: 'Chat and share ideas with group members seamlessly in real-time. ',
    imgPath:
      'https://attachment.conectivest.com/4b31bb10-145d-4a81-a4e5-7fecefbcb5dd-start.chat.gif',
  },

  {
    title: 'Checking Profile of Investors Applying to Join your Network ',
    label:
      'See the profile of your would-be investors before accepting them to join your membership community.',
    imgPath:
      'https://attachment.conectivest.com/c9c9a606-8764-4865-93ac-0f4dd36acf02-check.investor.gif',
  },

  {
    title: 'Accepting/Rejecting Investors Applying to Join your Network ',
    label: 'Choose who joins your network membership list. ',
    imgPath:
      'https://attachment.conectivest.com/c9c9a606-8764-4865-93ac-0f4dd36acf02-check.investor.gif',
  },

  {
    title: 'Creating a Deal from your Custom Email Client ',
    label:
      'Create a deal from email and move it into your deal-flow process tray. send to "screening" tray for members to view.  ',
    imgPath:
      'https://attachment.conectivest.com/ff9ffb4c-738f-4193-898d-e27d22e90744-create.deal.gif',
  },

  {
    title: 'Viewing and Commenting on Deals ',
    label:
      'Members can view, add comments and show interests in deals in the "screening" tray.  ',
    imgPath:
      'https://attachment.conectivest.com/45422fc7-dccc-4fd3-82f3-8ac1b2590213-view.deal.gif',
  },

  {
    title: 'Viewing and Editing your Personal Profile ',
    label: 'Stay updated, edit your profile information.  ',
    imgPath:
      'https://attachment.conectivest.com/d2fbe698-a164-45c0-9101-ea07c38f94ac-view.edit.pprofile.gif',
  },

  {
    title: 'Viewing and Editing your Network Profile ',
    label: 'Keep Entrepreneurs updated on your network information  ',
    imgPath:
      'https://attachment.conectivest.com/cb7679f0-c4f7-4ceb-b61b-909778181eac-view.edit.nprofile.gif',
  },

  {
    title: 'Dark and Light Mode',
    label:
      'Enjoy your eyes with dark/light mode, just switch the option at the top left corner.',
    imgPath:
      'https://attachment.conectivest.com/7211ea94-e2f8-4bcc-95d5-dbb5bb940672-switch.mode.gif',
  },

  {
    title: 'Full Screen Mode',
    label: 'Switch to no distraction mode ',
    imgPath:
      'https://attachment.conectivest.com/a941e0e8-1fa7-4044-92d4-f127f354a37b-fullscreen.gif',
  },
  {
    title: 'Enable Notifications',
    label:
      'Enable notifications so we can send you real time information about events on your network.',
    imgPath:
      'https://attachment.conectivest.com/6aa9d023-9589-4db2-ae3d-600fc24f883c-notification.settings.gif',
  },
  // {
  //   title: 'Search Components and Pages',
  //   label: 'Find any ui component or template page quicker.',
  //   imgPath: '/images/guide/guide4.gif',
  // },
  // {
  //   title: 'Code Preview',
  //   label:
  //     'Get source code easier without open files. Just click showcode button then You will see what a magic behind. It is available for Layout, Form Buttons, UI Components, Chart and Maps category.',
  //   imgPath: '/images/guide/guide5.gif',
  // },

  // {
  //   title: 'Dark and Light Mode',
  //   label:
  //     'Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header.',
  //   imgPath: '/images/guide/guide2.gif',
  // },

  // {
  //   title: 'Themes and Layout',
  //   label:
  //     'Build your template with various themes and layout combination. It easy to adjust following your brand identity.',
  //   imgPath: '/images/guide/guide3.gif',
  // },
];

export default guideSteps;
