import { call, put, takeLatest } from 'redux-saga/effects';
import safeSaga from '../../utils/safeSaga';

import http from '../api';
import { userRequests } from '../api/requests';
import {
  FETCH_PROFILE_REQUEST,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_ERROR,
  FETCH_PROFILE_FORM_DATA_SUCCESS,
  FETCH_PROFILE_FORM_DATA_ERROR,
  FETCH_PROFILE_FORM_DATA_REQUEST,
  CREATE_PROFILE_REQUEST,
  CREATE_PROFILE_SUCCESS,
  CREATE_PROFILE_ERROR,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_ERROR,
  SOCKET_CONNECTION,
} from '../types';

function* fetchProfile({ onSuccess }) {
  const { data } = yield call([http, 'get'], userRequests.PROFILE);
  let network;
  const networkId =
    data &&
    data.data &&
    data.data.networkMembership[0] &&
    data.data.networkMembership[0].networkId;
  if (networkId) {
    const { data } = yield call(
      [http, 'get'],
      userRequests.GET_NETWORK_DETAIL(networkId)
    );
    network = data.network;
  }
  yield put({
    type: FETCH_PROFILE_SUCCESS,
    payload: { ...data, network },
  });
  if (onSuccess) onSuccess(data);
}

function* createProfile({ payload, onSuccess }) {
  const { data } = yield call([http, 'post'], userRequests.PROFILE, payload);
  yield put({
    type: CREATE_PROFILE_SUCCESS,
    payload: data,
  });
  if (onSuccess) onSuccess(data);
}

function* updateProfile({ payload, onSuccess }) {
  const { data } = yield call([http, 'put'], userRequests.PROFILE, payload);
  yield put({
    type: UPDATE_PROFILE_SUCCESS,
    payload: data,
  });
  if (onSuccess) onSuccess(data);
}

function* fetchProfileFormData() {
  const { data } = yield call([http, 'get'], userRequests.FORM_DATA);
  yield put({
    type: FETCH_PROFILE_FORM_DATA_SUCCESS,
    payload: data,
  });
}

export default function* userSaga() {
  yield takeLatest(
    FETCH_PROFILE_REQUEST,
    safeSaga(fetchProfile, FETCH_PROFILE_ERROR)
  );
  yield takeLatest(
    CREATE_PROFILE_REQUEST,
    safeSaga(createProfile, CREATE_PROFILE_ERROR)
  );
  yield takeLatest(
    UPDATE_PROFILE_REQUEST,
    safeSaga(updateProfile, UPDATE_PROFILE_ERROR)
  );
  yield takeLatest(
    FETCH_PROFILE_FORM_DATA_REQUEST,
    safeSaga(fetchProfileFormData, FETCH_PROFILE_FORM_DATA_ERROR)
  );
}
