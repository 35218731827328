/* eslint-disable func-names */
import { put } from 'redux-saga/effects';
import { PENDING_PATH_KEY } from './constants';
import { clearCookies } from './helpers';

export const SET_SAGA_ERROR = 'SET_SAGA_ERROR';

export default function safeSaga(func, action) {
  // eslint-disable-next-line generator-star-spacing
  return function*(args) {
    try {
      yield* func(args);
    } catch (err) {
      let errors;
      const tempError = yield err.response && err.response.data.error;
      if (tempError) {
        errors = tempError;
      } else {
        errors = yield err.response && err.response.data.message;
        if (!errors) errors = err.message;
      }
      if (
        err.response &&
        err.response.status === 401 &&
        window.location.pathname !== '/login'
      ) {
        const pendingPath = window.location.pathname;
        localStorage.setItem(PENDING_PATH_KEY, pendingPath);
        yield clearCookies();
        yield (window.location.href = '/login');
      } else if (action) {
        yield put({
          type: action,
          payload: errors,
        });
      } else {
        yield put({
          type: SET_SAGA_ERROR,
          payload: { errors },
        });
      }
    }
  };
}
