/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
// eslint-disable-next-line no-unused-vars
import 'linkifyjs';
import linkifyHtml from 'linkify-html';
import DOMPurify from 'dompurify';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Send from '@material-ui/icons/Send';
import IconButton from '@material-ui/core/IconButton';
import Type from 'dan-styles/Typography.scss';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { connect } from 'react-redux';
import MessageField from './MessageField';
import ChatHeader from './ChatHeader';
import styles from './chatStyle-jss';
import http from '../../redux/api';
import { chatRequest, networkRequests } from '../../redux/api/requests';
import { extractErrorMessage } from '../../redux/api/helpers';
import { NotifyUserOfEvent } from '../../redux/api/EventNotifier';
import { CircularIndeterminateSm } from '../Progress/CircularIndeterminate';
import { useNetwork } from '../../utils/components/hooks';
dayjs.extend(localizedFormat);

function ChatRoom(props) {
  const field = useRef(null);
  const {
    classes,
    chatSelected,
    dataContact,
    showMobileDetail,
    groupMembers,
    remove,
    hideDetail,
    userId,
  } = props;
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [response, setResponse] = useState('');
  const [newMessage, setNewMessage] = useState();
  const { socket } = props;
  const { networkId } = useNetwork();
  const messagesEndRef = useRef(document.createElement('div'));

  const getGroupMessages = async () => {
    try {
      const groupId = dataContact[chatSelected].group.id;
      const {
        data: { data },
      } = await http.get(chatRequest.GET_GROUP_MESSAGE(groupId));
      setMessages(
        data.map((msg) => ({
          ...msg,
          messageSentBy: {
            groupMemberDetails: {
              userId: msg.fromUserId,
            },
            userId: msg.fromUserId,
          },
        }))
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      const error = extractErrorMessage(err);
      setResponse(error);
    }
  };

  const getMessages = async () => {
    try {
      const channelId = dataContact[chatSelected].id;
      if (!channelId) {
        return getGroupMessages();
      }
      const {
        data: { data },
      } = await http.get(networkRequests.GET_PRIVATE_MESSAGES(channelId));
      setMessages(
        data.map((msg) => ({
          ...msg,
          messageSentBy: {
            groupMemberDetails: {
              userId: msg.fromUserId,
            },
            userId: msg.fromUserId,
          },
        }))
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      const error = extractErrorMessage(err);
      setResponse(error);
    }
  };

  const handleWrite = (e, value) => {
    setMessage(value);
  };

  const resetInput = () => {
    const ctn = document.getElementById('roomContainer');
    setMessage('');
    field.current.value = '';
    setTimeout(() => {
      ctn.scrollTo(0, ctn.scrollHeight);
    }, 300);
  };

  const sendMessage = async () => {
    const { toUserId } = dataContact[chatSelected];
    const optimisticResponse = {
      message,
      attachments: null,
      id: uuidv4(),
      fromUserId: userId,
      toUserId,
      createdAt: new Date(),
      messageSentBy: {
        groupMemberDetails: {
          userId,
        },
        userId,
      },
    };
    try {
      // eslint-disable-next-line no-unused-vars
      setMessages((value) => [...value, optimisticResponse]);
      resetInput();
      const channelId = dataContact[chatSelected].id;
      const body = {
        toUserId,
        message,
      };
      if (channelId) {
        return await http.post(chatRequest.PRIVATE_MESSAGES, body);
      }
      const groupId = dataContact[chatSelected].group.id;
      let payload = {
        networkGroupId: groupId,
        message,
      };

      return http.post(chatRequest.SEND_GROUP_MESSAGE(networkId), payload);
      // await getMessages();
    } catch (err) {
      const error = extractErrorMessage(err);
      setResponse(error);
      const clone = messages.pop();
      setMessages(clone);
    }
  };

  const sendMessageByEnter = (event) => {
    if (event.key === 'Enter' && event.target.value !== '') {
      sendMessage();
    }
  };

  useEffect(() => {
    if (dataContact.length) {
      setLoading(true);
      getMessages();
    }
  }, [dataContact, chatSelected]);

  useEffect(() => {
    if (socket && dataContact.length) {
      const { id } = dataContact[chatSelected];
      socket.on(`new_private_message_${id}`, (privateMessage) => {
        setNewMessage(privateMessage);
      });
    }
  }, [dataContact, socket, chatSelected]);

  useEffect(() => {
    if (messagesEndRef) {
      messagesEndRef.current.addEventListener('DOMNodeInserted', (event) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
      });
    }

    return () => {
      messagesEndRef.current.removeEventListener('DOMNodeInserted', (event) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
      });
    };
  }, []);

  useEffect(() => {
    if (newMessage) {
      setNewMessage(null);
      if (!messages.length) {
        getMessages();
      } else {
        const senderDetails = messages.find(
          ({ fromUserId }) => fromUserId === newMessage.fromUserId
        );
        if (senderDetails) {
          setMessages((value) => [
            ...value,
            { ...senderDetails, ...newMessage },
          ]);
        }
      }
    }
  }, [newMessage, messages, chatRequest, dataContact]);

  const html = { __html: message };
  const options = {
    defaultProtocol: 'https',
    target: {
      url: '_blank',
    },
    rel: 'noopener noreferrer',
  };

  const getChat = (dataArray) =>
    dataArray.map((data, i) => {
      const {
        messageSentBy: { groupMemberDetails },
      } = data;
      const renderHTML = {
        __html: DOMPurify.sanitize(linkifyHtml(data.message, options)),
      };
      return (
        <li
          className={
            data.messageSentBy.userId !== userId ? classes.from : classes.to
          }
          // eslint-disable-next-line react/no-array-index-key
          key={i}
        >
          <time dateTime={data.createdAt}>
            {dayjs(data.createdAt).format('MMM, D YYYY LT')}
          </time>
          <div className={classes.talk}>
            <p>
              <span>
                {data.messageSentBy.userId !== userId && (
                  <b
                    style={{
                      fontSize: 13,
                      margin: 0,
                      fontWeight: 'bold',
                    }}
                  >
                    {[
                      groupMemberDetails.firstName,
                      groupMemberDetails.lastName,
                    ].join(' ')}
                  </b>
                )}
                <div dangerouslySetInnerHTML={renderHTML} />
              </span>
            </p>
          </div>
        </li>
      );
    });

  return (
    <div
      className={classNames(
        classes.root,
        classes.content,
        showMobileDetail ? classes.detailPopup : ''
      )}
    >
      <NotifyUserOfEvent
        open={!!response}
        close={() => setResponse('')}
        message={response}
      />
      <ChatHeader
        dataContact={dataContact}
        chatSelected={chatSelected}
        groupMembers={groupMembers}
        remove={remove}
        showMobileDetail={showMobileDetail}
        hideDetail={hideDetail}
        loading={loading}
      />
      <div style={{ flex: 1, overflow: 'auto' }} ref={messagesEndRef}>
        <ul className={classes.chatList} id='roomContainer'>
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: 150,
                minHeight: 150,
              }}
            >
              <CircularIndeterminateSm size='46px' color='#0566AD' />
            </div>
          ) : messages.length > 0 ? (
            getChat(messages)
          ) : (
            <Typography
              display='block'
              variant='caption'
              className={Type.textCenter}
            >
              {"You haven't made any conversation yet"}
            </Typography>
          )}
        </ul>
      </div>
      <div>
        <Paper className={classes.writeMessage}>
          <MessageField
            onChange={handleWrite}
            passedRef={field}
            placeholder='Type a message'
            fieldType='input'
            value={message}
            onKeyPress={(event) => sendMessageByEnter(event, html)}
          />
          <Tooltip id='tooltip-send' title='Send'>
            <div>
              <IconButton
                mini='true'
                color='secondary'
                disabled={message === ''}
                onClick={() => sendMessage(html)}
                aria-label='send'
                className={classes.sendBtn}
              >
                <Send />
              </IconButton>
            </div>
          </Tooltip>
        </Paper>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  socket: state.toJS().profile.connection,
});

export default withStyles(styles)(connect(mapStateToProps)(ChatRoom));
