/* eslint-disable react/require-default-props */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import Info from '@material-ui/icons/Info';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withStyles } from '@material-ui/core/styles';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useSelector } from 'react-redux';
import styles from './jss/cover-jss';
import { extractNetwork } from '../../utils/helpers';

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const ITEM_HEIGHT = 48;

function Cover(props) {
  const [anchorElOpt, setAnchorElOpt] = useState(null);
  const {
    classes,
    avatar,
    name,
    desc,
    profile,
    coverImg,
    onEditProfileClick,
    handleOpen,
    response,
    errorMessage,
    handleClose2,
    opened,
    isNetwork,
  } = props;
  const profileActionText = `${profile ? 'Edit' : 'Create'} Profile`;
  const optionsOpt = [profileActionText];

  const userProfile = useSelector((state) => state.toJS().profile.data);

  const loadedProfile = useRef(false);

  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const handleClickOpt = (event) => {
    setAnchorElOpt(event.currentTarget);
  };

  const handleCloseOpt = () => {
    setAnchorElOpt(null);
  };

  useEffect(() => {
    if (userProfile.data && isNetwork && !loadedProfile.current) {
      const network = extractNetwork(userProfile);
      setIsSuperAdmin(network.isSuperAdmin);
      loadedProfile.current = true;
    }
  }, [isNetwork, userProfile]);

  return (
    <div
      className={classes.cover}
      style={{ backgroundImage: `url(${coverImg})` }}
    >
      {errorMessage && (
        <Snackbar
          open={opened}
          autoHideDuration={3000}
          onClose={() => handleClose2()}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => handleClose2()} severity='error'>
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
      {response && (
        <Snackbar
          open={opened}
          autoHideDuration={3000}
          onClose={handleClose2}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleClose2} severity='success'>
            {response}
          </Alert>
        </Snackbar>
      )}
      {onEditProfileClick && (
        <div className={classes.opt}>
          <IconButton className={classes.button} aria-label='Delete'>
            <Info />
          </IconButton>
          <IconButton
            aria-label='More'
            aria-owns={anchorElOpt ? 'long-menu' : null}
            aria-haspopup='true'
            className={classes.button}
            onClick={handleClickOpt}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id='long-menu'
            anchorEl={anchorElOpt}
            open={Boolean(anchorElOpt)}
            onClose={handleCloseOpt}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 200,
              },
            }}
          >
            {optionsOpt.map((option) => {
              const isEdit = option === profileActionText;
              return (
                <MenuItem
                  key={option}
                  selected={isEdit}
                  onClick={
                    isEdit
                      ? () => {
                          onEditProfileClick();
                          setTimeout(handleCloseOpt, 350);
                        }
                      : handleCloseOpt
                  }
                >
                  {option}
                </MenuItem>
              );
            })}
          </Menu>
        </div>
      )}
      <div className={classes.content}>
        <Badge
          overlap='circle'
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={
            !isNetwork || (isNetwork && isSuperAdmin) ? (
              <IconButton onClick={handleOpen}>
                <EditIcon style={{ color: 'white' }} />
              </IconButton>
            ) : null
          }
        >
          <Avatar alt={name} src={avatar} className={classes.avatar} />
        </Badge>
        <Typography variant='h4' className={classes.name} gutterBottom>
          {name}
          <VerifiedUser className={classes.verified} />
        </Typography>
        <Typography className={classes.subheading} gutterBottom>
          {desc}
        </Typography>
      </div>
    </div>
  );
}

Cover.propTypes = {
  classes: PropTypes.object.isRequired,
  avatar: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  coverImg: PropTypes.string.isRequired,
  onEditProfileClick: PropTypes.func,
};

export default withStyles(styles)(Cover);
