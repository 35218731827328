/* eslint-disable react/require-default-props */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import LocalPhone from '@material-ui/icons/LocalPhone';
import LocationOn from '@material-ui/icons/LocationOn';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';
import { Email, LinkedIn, People } from '@material-ui/icons';
import { Chip } from '@material-ui/core';
import PapperBlock from '../PapperBlock/PapperBlock';
import styles from './profile-jss';

const TabBox = ({
  label,
  items,
  emptyListText,
  textSelector,
  classes,
  icon,
}) => (
  <PapperBlock whiteBg noMargin title={label} icon={icon || ''}>
    <Divider className={classes.divider} />
    {items && items.length
      ? items.map((item, index) => (
          <Chip
            key={index}
            color='primary'
            className={classes.chip}
            label={(textSelector && textSelector(item)) || ''}
          />
        ))
      : emptyListText}
  </PapperBlock>
);

class About extends React.Component {
  render() {
    let { classes, profile = {}, user = {}, networkName } = this.props;
    const { otherMentorshipSkills, mentoringSkills } = profile;

    const getMentorshipItems = () => {
      let list = [];
      if (mentoringSkills) {
        list = [...list, ...mentoringSkills];
      }

      if (otherMentorshipSkills) {
        list = [
          ...list,
          ...otherMentorshipSkills.map((skill) => {
            if (typeof skill === 'string') {
              return { MentoringSkill: { name: skill } };
            }
            return skill;
          }),
        ];
      }
      return Array.from(new Set(list.map(({ MentoringSkill }) => MentoringSkill.name)));
    };

    return (
      <Grid
        container
        spacing={3}
        direction='row'
        justify='flex-start'
        alignItems='flex-start'
      >
        <Grid item md={5} xs={12}>
          {/* About Me */}
          <PapperBlock
            whiteBg
            noMargin
            title='Bio'
            icon='ios-contact-outline'
          >
            <Divider className={classes.divider} />
            <List dense className={classes.profileList}>
              {profile && profile.location ? (
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      {/* <DateRange /> */}
                      <LocationOn />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary='Location'
                    secondary={profile.location}
                  />
                </ListItem>
              ) : (
                ''
              )}
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <Email />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary='Email' secondary={user.email} />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <Email />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary='Custom Email'
                  secondary={user.customEmail}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <LocalPhone />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary='Phone'
                  secondary={user.phoneNumber}
                />
              </ListItem>
              {profile && profile.linkedInProfile ? (
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <LinkedIn />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary='LinkedIn Profile'
                    secondary={
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href={profile.linkedInProfile}
                      >
                        {`${profile.linkedInProfile.substring(0, 35)}...`}
                      </a>
                    }
                  />
                </ListItem>
              ) : (
                ''
              )}
              {user && user.networkMembership ? (
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <People />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary='Network' secondary={networkName} />
                </ListItem>
              ) : (
                ''
              )}
            </List>
          </PapperBlock>
        </Grid>
        <Grid item md={7} xs={12}>
          {/* Mentoring skills */}
          <TabBox
            {...{ classes }}
            label='Skill set'
            icon='ios-hammer-outline'
            emptyListText='No skill listed'
            textSelector={(name) => name}
            items={getMentorshipItems()}
          />
          <Divider className={classes.divider} />
          {/* Interested sectors */}
          <TabBox
            {...{ classes }}
            label='Interested Sectors'
            icon='ios-briefcase-outline'
            items={
              (profile &&
                profile.sectors &&
                Array.from(
                  new Set(profile.sectors.map(({ Sector }) => Sector.name))
                )) ||
              []
            }
            emptyListText='No sector listed'
            textSelector={(name) => name}
          />
        </Grid>
      </Grid>
    );
  }
}

About.propTypes = {
  classes: PropTypes.object.isRequired,
  profile: PropTypes.object,
};

export default withStyles(styles)(About);
