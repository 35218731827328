/* eslint-disable no-confusing-arrow */
/* eslint-disable no-return-assign */
import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Field, reduxForm } from 'redux-form/immutable';
import Button from '@material-ui/core/Button';
import { connect, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import Hidden from '@material-ui/core/Hidden';
import brand from 'dan-api/dummy/brand';
import logo from 'dan-images/logo.png';
import logo2 from 'dan-images/logo2.png';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useCookies } from 'react-cookie';
import { TextFieldRedux, CheckboxRedux } from './ReduxFormMUI';
import styles from './user-jss';
import { CircularIndeterminateSm } from '../Progress/CircularIndeterminate';
import { PENDING_PATH_KEY, REDIRECT_URL } from '../../utils/constants';
import { LOGGED_IN_STATE_KEY } from '../../redux/constants';
import { SIGN_IN_SUCCESS } from '../../redux/types';

// validation functions
const required = (value) => (value === null ? 'Required' : undefined);

function Login({ token }) {
  const [, setCookie] = useCookies();
  const dispatch = useDispatch();
  useEffect(() => {
    if (token) {
      setCookie(LOGGED_IN_STATE_KEY, token, {
        ...(process.env.NODE_ENV === 'production' && {
          domain: '.conectivest.com',
        }),
        secure: true,
        path: '/',
      });
      dispatch({
        type: SIGN_IN_SUCCESS,
        payload: {},
      });
      window.location.pathname =
        localStorage.getItem(PENDING_PATH_KEY) || '/app';
    }
  }, [token]);
  return null;
}

// eslint-disable-next-line
class LoginForm extends React.Component {
  state = {
    showPassword: false,
  };

  handleClickShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {
    const {
      classes,
      handleSubmit,
      pristine,
      submitting,
      deco,
      auth,
    } = this.props;
    const { showPassword } = this.state;
    const snack = auth && typeof auth.errors === 'string' && (
      <Snackbar
        open={typeof auth.errors === 'string'}
        autoHideDuration={3000}
        onClose={this.handleClose2}
      >
        <Alert severity='error'>
          {typeof auth.errors === 'string' && auth.errors}
        </Alert>
      </Snackbar>
    );
    return (
      <Fragment>
        <Login token={auth && auth.data && auth.data.token} />
        <Hidden mdUp>
          <NavLink to='/' className={classNames(classes.brand, classes.outer)}>
            <img src={logo2} alt={brand.name} />
            {brand.name}
          </NavLink>
        </Hidden>
        <Paper className={classNames(classes.paperWrap, deco && classes.petal)}>
          <div smDown>
            <div className={classes.topBar}>
              <NavLink to='/' className={classes.brand}>
                <img src={logo} alt={brand.name} />
                {brand.name}
              </NavLink>
              <Button
                size='small'
                className={classes.buttonLink}
                onClick={() =>
                  (window.location.href = `${
                    process.env.REACT_APP_TIMELINE_URL
                  }/register?redirect=deal-manager`)
                }
              >
                <Icon className={classes.icon}>arrow_forward</Icon>
                Create new account
              </Button>
            </div>
          </div>
          <Typography variant='h4' className={classes.title} gutterBottom>
            Sign In
          </Typography>
          {snack}
          <section className={classes.formWrap}>
            <form onSubmit={handleSubmit}>
              <div>
                <FormControl className={classes.formControl}>
                  <Field
                    name='email'
                    component={TextFieldRedux}
                    placeholder='Your Email'
                    label='Your Email'
                    required
                    type='email'
                    className={classes.field}
                  />
                </FormControl>
              </div>
              <div>
                <FormControl className={classes.formControl}>
                  <Field
                    name='password'
                    component={TextFieldRedux}
                    type={showPassword ? 'text' : 'password'}
                    label='Your Password'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='Toggle password visibility'
                            onClick={this.handleClickShowPassword}
                            onMouseDown={this.handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    required
                    validate={required}
                    className={classes.field}
                  />
                </FormControl>
              </div>
              <div className={classes.optArea}>
                <FormControlLabel
                  className={classes.label}
                  control={
                    <Field name='rememberMe' component={CheckboxRedux} />
                  }
                  label='Remember me'
                />
                <Button
                  size='small'
                  onClick={() =>
                    (window.location.href = `${
                      process.env.REACT_APP_TIMELINE_URL
                    }/reset-password`)
                  }
                  className={classes.buttonLink}
                >
                  Forgot Password?
                </Button>
              </div>
              <div className={classes.btnArea}>
                <Button
                  variant='contained'
                  color='primary'
                  size='large'
                  type='submit'
                  disabled={auth.processing || pristine}
                >
                  {auth.processing ? (
                    <CircularIndeterminateSm size='24px' />
                  ) : (
                    <>
                      Continue
                      <ArrowForward
                        className={classNames(
                          classes.rightIcon,
                          classes.iconSmall
                        )}
                        disabled={submitting || pristine}
                      />
                    </>
                  )}
                </Button>
              </div>
            </form>
          </section>
        </Paper>
      </Fragment>
    );
  }
}

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  deco: PropTypes.bool.isRequired,
};

const LoginFormReduxed = reduxForm({
  form: 'immutableExample',
  enableReinitialize: true,
})(LoginForm);

const reducerLogin = 'login';
const reducerUi = 'ui';
const FormInit = connect((state) => ({
  force: state,
  auth: state.toJS().auth,
  initialValues: state.getIn([reducerLogin, 'usersLogin']),
  deco: state.getIn([reducerUi, 'decoration']),
}))(LoginFormReduxed);

export default withStyles(styles)(FormInit);
