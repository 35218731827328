import { fromJS } from 'immutable';
import { extractStatus, handleFetch } from '../../utils/reducerHelper';
import { commonState } from '../constants';
import {
  FETCH_PROFILE_REQUEST,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_ERROR,
  CREATE_PROFILE_REQUEST,
  CREATE_PROFILE_SUCCESS,
  CREATE_PROFILE_ERROR,
  CLEAR_PROFILE_MESSAGES,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_SUCCESS,
  SOCKET_CONNECTION,
  UNREAD_MESAGES,
  UNREAD_DEALS,
  UNREAD_EMAILS,
  UNREAD_APPLICATIONS
} from '../types';

const initialState = fromJS({
  ...commonState,
  data: {},
  connection: null,
  unReadMessages: 0,
  unReadDeals: [{ unseenDeals: 0 }, { unseenDeals: 0 }],
  unReadEmails: 0,
  unReadApplications: 0
});

const profileReducer = (state = initialState, action) => {
  const status = extractStatus(action.type);
  switch (action.type) {
    case FETCH_PROFILE_REQUEST:
    case FETCH_PROFILE_SUCCESS:
    case FETCH_PROFILE_ERROR:
      return fromJS(handleFetch(state.toJS(), status, action.payload));
    case CREATE_PROFILE_REQUEST:
    case CREATE_PROFILE_ERROR:
    case UPDATE_PROFILE_REQUEST:
    case UPDATE_PROFILE_ERROR:
      return fromJS(handleFetch(state.toJS(), status, action.payload));
    case CREATE_PROFILE_SUCCESS:
    case UPDATE_PROFILE_SUCCESS:
      return fromJS({
        ...state.toJS(),
        processing: false,
        processed: true,
        success: true,
        errors: {},
      });
    case SOCKET_CONNECTION:
      return fromJS(
        handleFetch(
          state.toJS(),
          SOCKET_CONNECTION,
          action.payload,
          'connection'
        )
      );
    case UNREAD_MESAGES:
      return fromJS(
        handleFetch(
          state.toJS(),
          UNREAD_MESAGES,
          action.payload,
          'unReadMessages'
        )
      );
    case UNREAD_DEALS:
      return fromJS(
        handleFetch(state.toJS(), UNREAD_DEALS, action.payload, 'unReadDeals')
      );
    case UNREAD_EMAILS:
      return fromJS(
        handleFetch(
          state.toJS(),
          UNREAD_EMAILS,
          action.payload,
          'unReadEmails'
        )
      );
    case UNREAD_APPLICATIONS:
      return fromJS(
        handleFetch(
          state.toJS(),
          UNREAD_APPLICATIONS,
          action.payload,
          'unReadApplications'
        )
      );
    case CLEAR_PROFILE_MESSAGES:
      return fromJS({ ...state.toJS(), errors: {}, message: '' });
    default:
      return state;
  }
};

export default profileReducer;
