import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { GuideSlider } from 'dan-components';
import {
  toggleAction,
  openAction,
  playTransitionAction,
} from 'dan-redux/actions/uiActions';
import LeftSidebarLayout from './layouts/LeftSidebarLayout';
import RightSidebarLayout from './layouts/RightSidebarLayout';
import LeftSidebarBigLayout from './layouts/LeftSidebarBigLayout';
import DropMenuLayout from './layouts/DropMenuLayout';
import MegaMenuLayout from './layouts/MegaMenuLayout';
import styles from './appStyles-jss';
import http from '../../redux/api';
import { extractErrorMessage } from '../../redux/api/helpers';
import { NotifyUserOfEvent } from '../../redux/api/EventNotifier';

function Dashboard(props) {
  // Initial header style
  const [openGuide, setOpenGuide] = useState(false);
  const [appHeight, setAppHeight] = useState(0);
  const [showField, setShowField] = useState(openGuide);
  const [response, setResponse] = useState('');

  useEffect(() => {
    const { history, loadTransition } = props;

    const getShowField = async () => {
      try {
        const { data } = await http.get('/show-field');
        setShowField(data.showField);
        setOpenGuide(data.showField);
      } catch (err) {
        const message = extractErrorMessage(err);
        setResponse(message);
      }
    };
    getShowField();

    // Adjust min height
    setAppHeight(window.innerHeight + 112);

    // Set expanded sidebar menu
    const currentPath = history.location.pathname;
    props.initialOpen(currentPath);
    // Play page transition
    loadTransition(true);

    // Execute all arguments when page changes
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
      setTimeout(() => {
        loadTransition(true);
      }, 500);
    });

    return () => {
      if (unlisten != null) {
        unlisten();
      }
    };
  }, []);

  const handleOpenGuide = () => {
    setOpenGuide(true);
  };

  const handleCloseGuide = () => {
    setOpenGuide(false);
  };

  const {
    classes,
    children,
    toggleDrawer,
    sidebarOpen,
    loadTransition,
    pageLoaded,
    mode,
    history,
    gradient,
    deco,
    bgPosition,
    layout,
    changeMode,
  } = props;
  const titleException = [
    '/app',
    '/app/crm-dashboard',
    '/app/crypto-dashboard',
  ];
  const parts = history.location.pathname.split('/');
  const place = parts[parts.length - 1].replace('-', ' ');
  return (
    <div
      style={{ minHeight: appHeight }}
      className={classNames(
        classes.appFrameInner,
        layout === 'top-navigation' || layout === 'mega-menu'
          ? classes.topNav
          : classes.sideNav,
        mode === 'dark' ? 'dark-mode' : 'light-mode'
      )}
    >
      {/* <GuideSlider
        openGuide={openGuide}
        showField={showField && showField}
        closeGuide={handleCloseGuide}
      /> */}
      {/* Left Sidebar Layout */
      layout === 'left-sidebar' && (
        <LeftSidebarLayout
          history={history}
          toggleDrawer={toggleDrawer}
          loadTransition={loadTransition}
          changeMode={changeMode}
          sidebarOpen={sidebarOpen}
          pageLoaded={pageLoaded}
          mode={mode}
          gradient={gradient}
          deco={deco}
          bgPosition={bgPosition}
          place={place}
          titleException={titleException}
          handleOpenGuide={handleOpenGuide}
        >
          {children}
        </LeftSidebarLayout>
      )}
      {/* Left Big-Sidebar Layout */
      layout === 'big-sidebar' && (
        <LeftSidebarBigLayout
          history={history}
          toggleDrawer={toggleDrawer}
          loadTransition={loadTransition}
          changeMode={changeMode}
          sidebarOpen={sidebarOpen}
          pageLoaded={pageLoaded}
          gradient={gradient}
          deco={deco}
          bgPosition={bgPosition}
          mode={mode}
          place={place}
          titleException={titleException}
          handleOpenGuide={handleOpenGuide}
        >
          {children}
        </LeftSidebarBigLayout>
      )}
      {/* Right Sidebar Layout */
      layout === 'right-sidebar' && (
        <RightSidebarLayout
          history={history}
          toggleDrawer={toggleDrawer}
          loadTransition={loadTransition}
          changeMode={changeMode}
          sidebarOpen={sidebarOpen}
          pageLoaded={pageLoaded}
          mode={mode}
          gradient={gradient}
          deco={deco}
          bgPosition={bgPosition}
          place={place}
          titleException={titleException}
          handleOpenGuide={handleOpenGuide}
        >
          {children}
        </RightSidebarLayout>
      )}
      {/* Top Bar with Dropdown Menu */
      layout === 'top-navigation' && (
        <DropMenuLayout
          history={history}
          toggleDrawer={toggleDrawer}
          loadTransition={loadTransition}
          changeMode={changeMode}
          sidebarOpen={sidebarOpen}
          pageLoaded={pageLoaded}
          mode={mode}
          gradient={gradient}
          deco={deco}
          bgPosition={bgPosition}
          place={place}
          titleException={titleException}
          handleOpenGuide={handleOpenGuide}
        >
          {children}
        </DropMenuLayout>
      )}
      {/* Top Bar with Mega Menu */
      layout === 'mega-menu' && (
        <MegaMenuLayout
          history={history}
          toggleDrawer={toggleDrawer}
          loadTransition={loadTransition}
          changeMode={changeMode}
          sidebarOpen={sidebarOpen}
          pageLoaded={pageLoaded}
          mode={mode}
          gradient={gradient}
          deco={deco}
          bgPosition={bgPosition}
          place={place}
          titleException={titleException}
          handleOpenGuide={handleOpenGuide}
        >
          {children}
        </MegaMenuLayout>
      )}
      <NotifyUserOfEvent
        message={response}
        open={!!response}
        close={() => setResponse('')}
      />
    </div>
  );
}

const reducer = 'ui';
const mapStateToProps = (state) => ({
  sidebarOpen: state.getIn([reducer, 'sidebarOpen']),
  pageLoaded: state.getIn([reducer, 'pageLoaded']),
  mode: state.getIn([reducer, 'type']),
  gradient: state.getIn([reducer, 'gradient']),
  deco: state.getIn([reducer, 'decoration']),
  layout: state.getIn([reducer, 'layout']),
  bgPosition: state.getIn([reducer, 'bgPosition']),
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  toggleDrawer: () => dispatch(toggleAction),
  initialOpen: bindActionCreators(openAction, dispatch),
  loadTransition: bindActionCreators(playTransitionAction, dispatch),
});

const DashboardMaped = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);

export default withStyles(styles)(DashboardMaped);
