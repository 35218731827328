import { all } from 'redux-saga/effects';
import userSaga from './userSaga';
import authSaga from './authSaga';
import emailSaga from './emailSaga';
import networksSaga from './networksSaga';

export default function* rootSaga() {
  yield all([
    userSaga(),
    authSaga(),
    emailSaga(),
    networksSaga()
  ]);
}
