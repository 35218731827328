// export const Statuses = ["SUCCESS", "REQUEST", "ERROR"];

import {
  UNREAD_MESAGES,
  UNREAD_DEALS,
  UNREAD_EMAILS,
  SOCKET_CONNECTION,
  UNREAD_APPLICATIONS,
} from '../redux/types';

export function handleFetch(state, status, payload, key) {
  // const { errors, data/*, pagination*/ } = payload || {};
  let newStatus = {};

  if (status === 'REQUEST') {
    newStatus = {
      processing: true,
      processed: false,
      errors: {},
    };
  } else if (status === 'SUCCESS') {
    newStatus = {
      processing: false,
      processed: true,
      success: true,
      data: payload || {},
      // pagination,
    };
  } else {
    newStatus = {
      processing: false,
      processed: true,
      success: false,
      errors: payload,
    };
  }

  if (
    status === UNREAD_MESAGES ||
    status === UNREAD_DEALS ||
    status === UNREAD_EMAILS ||
    status === SOCKET_CONNECTION ||
    status === UNREAD_APPLICATIONS
  ) {
    const newState = { ...state, [key]: payload };
    return newState;
  }

  if (key) {
    return {
      ...state,
      [key]: {
        ...state[key],
        ...newStatus,
      },
    };
  }

  return {
    ...state,
    ...newStatus,
  };
}

export function extractStatus(type) {
  let status = type.split('_').pop();

  if (status !== 'ERROR' && status !== 'SUCCESS') {
    status = 'REQUEST';
  }

  return status;
}
