/* eslint-disable no-case-declarations */
import { fromJS } from 'immutable';
import { commonState } from '../constants';
import { extractStatus, handleFetch } from '../../utils/reducerHelper';
import {
  FETCH_NETWORKS_ERROR,
  FETCH_NETWORKS_REQUEST,
  FETCH_NETWORKS_SUCCESS,
  INVITE_MEMBERS_ERROR,
  INVITE_MEMBERS_REQUEST,
  INVITE_MEMBERS_SUCCESS,
  FETCH_NETWORK_MEMBERS_SUCCESS,
  FETCH_NETWORK_MEMBERS_REQUEST,
  FETCH_NETWORK_MEMBERS_ERROR,
  FETCH_NETWORK_MEMBER_SUCCESS,
  FETCH_NETWORK_MEMBER_REQUEST,
  FETCH_NETWORK_MEMBER_ERROR,
  FETCH_NETWORKS_APPLICATIONS_REQUEST,
  FETCH_NETWORKS_APPLICATIONS_ERROR,
  FETCH_NETWORKS_APPLICATIONS_SUCCESS,
  FETCH_NETWORKS_MEMBER_APPLICATIONS_ERROR,
  FETCH_NETWORKS_MEMBER_APPLICATION_REQUEST,
  FETCH_NETWORKS_MEMBER_APPLICATIONS_SUCCESS,
  CLEAR_NETWORK_MESSAGES,
  UPDATE_APPLICATION_REQUEST,
  UPDATE_APPLICATION_ERROR,
  UPDATE_APPLICATION_SUCCESS,
  DELETE_APPLICATION_REQUEST,
  DELETE_APPLICATION_ERROR,
  DELETE_APPLICATION_SUCCESS,
  CREATE_GROUP_REQUEST,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_ERROR,
  FETCH_INVITES_REQUEST,
  FETCH_INVITES_ERROR,
  FETCH_INVITES_SUCCESS,
  ADD_MEMBERS_ERROR,
  ADD_MEMBERS_REQUEST,
  ADD_MEMBERS_SUCCESS,
  REMOVE_MEMBERS_ERROR,
  REMOVE_MEMBERS_REQUEST,
  REMOVE_MEMBERS_SUCCESS,
  FETCH_GROUP_MEMBERS_REQUEST,
  FETCH_GROUP_MEMBERS_ERROR,
  FETCH_GROUP_MEMBERS_SUCCESS,
  FETCH_GROUP_ERROR,
  FETCH_GROUP_REQUEST,
  FETCH_GROUP_SUCCESS,
  DELETE_GROUP_ERROR,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  UPDATE_GROUP_ERROR,
  UPDATE_GROUP_REQUEST,
  UPDATE_GROUP_SUCCESS,
  FETCH_DEALFLOW,
  FETCH_DEALFLOW_ERROR,
  FETCH_DEALFLOW_SUCCESS,
  FETCH_DEAL,
  FETCH_DEAL_ERROR,
  FETCH_DEAL_SUCCESS,
  FETCH_SINGLE_DEAL,
  FETCH_SINGLE_DEAL_ERROR,
  FETCH_SINGLE_DEAL_SUCCESS,
  FETCH_RECENT_ACTIVITIES,
  FETCH_RECENT_ACTIVITIES_ERROR,
  FETCH_RECENT_ACTIVITIES_SUCCESS,
  FETCH_COMMENTS,
  FETCH_COMMENTS_ERROR,
  FETCH_COMMENTS_SUCCESS,
  ADD_COMMENT,
  ADD_COMMENT_ERROR,
  ADD_COMMENT_SUCCESS,
  TOGGLE_INTEREST,
  TOGGLE_INTEREST_ERROR,
  TOGGLE_INTEREST_SUCCESS,
  GET_ALL_DEAL_INTEREST,
  GET_ALL_DEAL_INTEREST_SUCCESS,
  GET_ALL_DEAL_INTEREST_ERROR,
  CLOSE_DEAL,
  CLOSE_DEAL_ERROR,
  CLOSE_DEAL_SUCCESS,
} from '../types';

const initialState = fromJS({
  networks: {
    ...commonState,
    data: {},
  },
  invitations: {
    ...commonState,
    data: {},
  },
  members: {
    ...commonState,
    data: {},
  },

  groups: {
    ...commonState,
    data: {},
  },

  dealflow: {
    ...commonState,
    data: {},
  },

  deals: {
    ...commonState,
    data: {},
  },
  deal: {
    ...commonState,
    data: {},
  },
  recentActivities: {
    ...commonState,
    data: {},
  },
  comments: {
    ...commonState,
    data: {},
  },
  dealInterest: {
    ...commonState,
    data: {},
  },
});

const networksReducer = (state = initialState, action) => {
  const status = extractStatus(action.type);
  switch (action.type) {
    case CREATE_GROUP_ERROR:
    case CREATE_GROUP_REQUEST:
    case CREATE_GROUP_SUCCESS:
      return fromJS(
        handleFetch(state.toJS(), status, action.payload, 'groupCreate')
      );
    case ADD_MEMBERS_REQUEST:
    case ADD_MEMBERS_SUCCESS:
    case ADD_MEMBERS_ERROR:
      return fromJS(
        handleFetch(state.toJS(), status, action.payload, 'addMembers')
      );
    case REMOVE_MEMBERS_SUCCESS:
    case REMOVE_MEMBERS_REQUEST:
    case REMOVE_MEMBERS_ERROR:
      return fromJS(
        handleFetch(state.toJS(), status, action.payload, 'removeMembers')
      );
    case FETCH_GROUP_MEMBERS_SUCCESS:
    case FETCH_GROUP_MEMBERS_REQUEST:
    case FETCH_GROUP_MEMBERS_ERROR:
      return fromJS(
        handleFetch(state.toJS(), status, action.payload, 'groupMembers')
      );
    case FETCH_GROUP_ERROR:
    case FETCH_GROUP_REQUEST:
    case FETCH_GROUP_SUCCESS:
      return fromJS(
        handleFetch(state.toJS(), status, action.payload, 'groups')
      );
    case DELETE_GROUP_ERROR:
    case DELETE_GROUP_REQUEST:
    case DELETE_GROUP_SUCCESS:
      return fromJS(
        handleFetch(state.toJS(), status, action.payload, 'groupDelete')
      );
    case UPDATE_GROUP_ERROR:
    case UPDATE_GROUP_REQUEST:
    case UPDATE_GROUP_SUCCESS:
      return fromJS(
        handleFetch(state.toJS(), status, action.payload, 'groupUpdate')
      );
    case FETCH_NETWORKS_REQUEST:
    case FETCH_NETWORKS_SUCCESS:
    case FETCH_NETWORKS_ERROR:
      return fromJS(
        handleFetch(state.toJS(), status, action.payload, 'networks')
      );
    case FETCH_INVITES_REQUEST:
    case FETCH_INVITES_ERROR:
    case FETCH_INVITES_SUCCESS:
      return fromJS(
        handleFetch(state.toJS(), status, action.payload, 'invitees')
      );
    case INVITE_MEMBERS_REQUEST:
    case INVITE_MEMBERS_SUCCESS:
    case INVITE_MEMBERS_ERROR:
      return fromJS(
        handleFetch(state.toJS(), status, action.payload, 'invitations')
      );
    case UPDATE_APPLICATION_REQUEST:
    case UPDATE_APPLICATION_SUCCESS:
    case UPDATE_APPLICATION_ERROR:
      return fromJS(
        handleFetch(state.toJS(), status, action.payload, 'applicationUpdate')
      );
    case DELETE_APPLICATION_REQUEST:
    case DELETE_APPLICATION_SUCCESS:
    case DELETE_APPLICATION_ERROR:
      return fromJS(
        handleFetch(state.toJS(), status, action.payload, 'applicationDelete')
      );
    case FETCH_NETWORK_MEMBERS_REQUEST:
    case FETCH_NETWORK_MEMBERS_SUCCESS:
    case FETCH_NETWORK_MEMBERS_ERROR:
      return fromJS(
        handleFetch(state.toJS(), status, action.payload, 'members')
      );
    case FETCH_NETWORK_MEMBER_REQUEST:
    case FETCH_NETWORK_MEMBER_SUCCESS:
    case FETCH_NETWORK_MEMBER_ERROR:
      return fromJS(
        handleFetch(state.toJS(), status, action.payload, 'memberDetails')
      );
    case FETCH_NETWORKS_APPLICATIONS_REQUEST:
    case FETCH_NETWORKS_APPLICATIONS_ERROR:
    case FETCH_NETWORKS_APPLICATIONS_SUCCESS:
      return fromJS(
        handleFetch(state.toJS(), status, action.payload, 'memberApplications')
      );
    case FETCH_DEALFLOW:
    case FETCH_DEALFLOW_ERROR:
    case FETCH_DEALFLOW_SUCCESS:
      return fromJS(
        handleFetch(state.toJS(), status, action.payload, 'dealflow')
      );
    case FETCH_DEAL:
    case FETCH_DEAL_ERROR:
    case FETCH_DEAL_SUCCESS:
      return fromJS(handleFetch(state.toJS(), status, action.payload, 'deals'));
    case FETCH_SINGLE_DEAL:
    case FETCH_SINGLE_DEAL_ERROR:
    case FETCH_SINGLE_DEAL_SUCCESS:
      return fromJS(handleFetch(state.toJS(), status, action.payload, 'deal'));
    case FETCH_RECENT_ACTIVITIES:
    case FETCH_RECENT_ACTIVITIES_ERROR:
    case FETCH_RECENT_ACTIVITIES_SUCCESS:
      return fromJS(
        handleFetch(state.toJS(), status, action.payload, 'recentActivities')
      );
    case FETCH_COMMENTS:
    case FETCH_COMMENTS_ERROR:
    case FETCH_COMMENTS_SUCCESS:
      return fromJS(
        handleFetch(state.toJS(), status, action.payload, 'comments')
      );
    case ADD_COMMENT:
    case ADD_COMMENT_ERROR:
    case ADD_COMMENT_SUCCESS:
      return fromJS(
        handleFetch(state.toJS(), status, action.payload, 'addComment')
      );
    case TOGGLE_INTEREST:
    case TOGGLE_INTEREST_ERROR:
    case TOGGLE_INTEREST_SUCCESS:
      return fromJS(
        handleFetch(state.toJS(), status, action.payload, 'toggleInterest')
      );
    case GET_ALL_DEAL_INTEREST:
    case GET_ALL_DEAL_INTEREST_SUCCESS:
    case GET_ALL_DEAL_INTEREST_ERROR:
      return fromJS(
        handleFetch(state.toJS(), status, action.payload, 'dealInterest')
      );
    case CLOSE_DEAL:
    case CLOSE_DEAL_ERROR:
    case CLOSE_DEAL_SUCCESS:
      return fromJS(
        handleFetch(state.toJS(), status, action.payload, 'closeDeal')
      );
    case FETCH_NETWORKS_MEMBER_APPLICATION_REQUEST:
    case FETCH_NETWORKS_MEMBER_APPLICATIONS_SUCCESS:
    case FETCH_NETWORKS_MEMBER_APPLICATIONS_ERROR:
      return fromJS(
        handleFetch(
          state.toJS(),
          status,
          action.payload,
          'networkMemberApplication'
        )
      );

    case CLEAR_NETWORK_MESSAGES:
      const { key } = action.payload;
      const stateObj = state.toJS();
      let { message } = stateObj[key].data;
      message = message ? '' : undefined;

      if (key) {
        return fromJS({
          ...stateObj,
          [key]: {
            ...stateObj[key],
            message: '',
            errors: {},
            data: {
              ...stateObj[key].data,
              message,
            },
          },
        });
      }
      return state;
    default:
      return state;
  }
};

export default networksReducer;
