module.exports = [
  {
    key: 'dashboard',
    // eslint-disable-next-line linebreak-style
    name: 'Dashboard',
    icon: 'ios-home-outline',
    linkParent: '/app',
  },
  {
    key: 'syndication',
    name: 'Syndication',
    icon: 'ios-card',
    linkParent: '/app/syndication',
    isBeta: false,
    isNew: true,
  },
  {
    key: 'app',
    name: 'Apps',
    icon: 'ios-appstore-outline',
    child: [
      {
        key: 'connect',
        name: 'Connect',
        link: process.env.REACT_APP_TIMELINE_URL,
        icon: 'ios-mail-outline',
      },
      {
        key: 'stack',
        name: 'Stack',
        link: process.env.REACT_APP_FOUNDER_URL,
        icon: 'ios-mail-outline',
      },
    ],
  },
  {
    key: 'messaging',
    name: 'Messaging',
    icon: 'ios-appstore-outline',
    child: [
      {
        key: 'chat',
        name: 'Chat',
        link: '/app/pages/chat',
        icon: 'ios-chatbubbles-outline',
      },
    ],
  },
  {
    key: 'transactions',
    name: 'Transactions',
    icon: 'ios-stats-outline',
    child: [
      {
        key: 'deal-flow-transactions',
        name: 'Deal Flow',
        link: '/app/transactions',
        icon: 'ios-mail-outline',
      },
      {
        key: 'syndication-transactions',
        name: 'Syndication',
        link: '/app/transactions/syndication',
        icon: 'ios-mail-outline',
      },
    ],
  },
];
