import React, { useEffect, useRef, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import styles from 'dan-components/Contact/contact-jss';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import PersonIcon from '@material-ui/icons/Person';

function SinglePrivateContact(props) {
  const [newMessagesCount, setNewMessagesCount] = useState(0);
  const {
    classes,
    socket,
    data,
    index,
    itemSelected,
    showDetail,
    keyword,
  } = props;

  const { channelUser, id } = data;

  const userData = channelUser || {};

  const { firstName = 'Unknown', lastName = 'Unknown' } = userData;

  const name = `${firstName} ${lastName}`;

  const handleSelectChannel = () => {
    setNewMessagesCount(0);
    showDetail(index, id);
  };

  useEffect(() => {
    if (socket) {
      socket.on(`unread_messages_for_channel_${id}`, (unreadMessagesCount) => {
        setNewMessagesCount(unreadMessagesCount);
      });
    }
  }, [socket]);

  return (
    <ListItem
      button
      key={index}
      className={index === itemSelected ? classes.selected : ''}
      onClick={handleSelectChannel}
    >
      <ListItemAvatar>
        <Avatar className={classes.avatar}>
          <PersonIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>
              {name.length > 20 ? `${name.substring(0, 20)}...` : name}{' '}
            </span>
            <span>
              {newMessagesCount ? <Chip label={newMessagesCount} /> : null}
            </span>
          </div>
        }
      />
    </ListItem>
  );
}

SinglePrivateContact.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.any.isRequired,
  classes: PropTypes.any.isRequired,
  itemSelected: PropTypes.any.isRequired,
  keyword: PropTypes.string.isRequired,
  showDetail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  socket: state.toJS().profile.connection,
});

export default withStyles(styles)(
  connect(mapStateToProps)(SinglePrivateContact)
);
