import cookies from '../config/cookies';
import http from '../redux/api';
import { extractErrorMessage } from '../redux/api/helpers';
import { PAYMENT_REQUESTS } from '../redux/api/requests';
import { LOGGED_IN_STATE_KEY, OLD_LOGIN_KEY } from '../redux/constants';
import { PENDING_PATH_KEY, STRIPE_CHARGE } from './constants';

export function setWithExpiry(key, value, ttl) {
  const item = {
    value,
    expiry: new Date().getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key) {
  const itemString = window.localStorage.getItem(key);
  if (!itemString) return null;

  const item = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}

export const extractNetwork = (profile = {}) => {
  if (profile.networkMembership) {
    return profile.networkMembership[0];
  }
  let { user, data } = profile;
  if (!user) user = data || {};
  const { networkMembership = [] } = user;
  return networkMembership[0] || {};
};

export function truncateString(str, num = 10) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
}

export function isLoggedIn() {
  const cookie =
    cookies.get(LOGGED_IN_STATE_KEY) ||
    cookies.get(OLD_LOGIN_KEY) ||
    localStorage.getItem(OLD_LOGIN_KEY) ||
    sessionStorage.getItem(OLD_LOGIN_KEY);
  return !!cookie;
}

export function clearCookies() {
  cookies.remove(OLD_LOGIN_KEY);
  const pendingPath = window.location.pathname;
  localStorage.setItem(PENDING_PATH_KEY, pendingPath);
  localStorage.removeItem(OLD_LOGIN_KEY);
  sessionStorage.removeItem(OLD_LOGIN_KEY);
  cookies.remove(LOGGED_IN_STATE_KEY, {
    ...(process.env.NODE_ENV === 'production' && {
      domain: '.conectivest.com',
    }),
    secure: true,
  });
}

export const getNavigatorLanguage = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  }
  return (
    navigator.userLanguage ||
    navigator.language ||
    navigator.browserLanguage ||
    'en'
  );
};

export function currencyFormatter({
  value,
  currency = 'USD',
  locale = getNavigatorLanguage(),
  truncateString,
  notation,
}) {
  const nf = new Intl.NumberFormat(locale, {
    currency,
    style: 'currency',
    ...(truncateString && { maximumFractionDigits: 2 }),
    notation,
  });
  return nf.format(Number(value) || 0);
}

export async function convertCurrency({
  toCurrency = 'NGN',
  fromCurrency = 'USD',
  amount = 1,
}) {
  try {
    const { data } = await http.post(PAYMENT_REQUESTS.CURRENCY_CONVERT, {
      toCurrency,
      fromCurrency,
      amount,
    });
    return data.data;
  } catch (error) {
    const err = extractErrorMessage(error);
    throw new Error(err);
  }
}

export function getFullUrl(link) {
  if (
    String(link).startsWith('www') ||
    String(link).startsWith('http') ||
    String(link).startsWith('https')
  ) {
    return link;
  }

  return `https://www.${link}`;
}

export function getStripeCharges(amount) {
  const STRIPE_CHARGE_PERCENTAGE = STRIPE_CHARGE / 100;
  const toPay = Number(amount);
  return Math.ceil(toPay * STRIPE_CHARGE_PERCENTAGE);
}

export function getStripeTotalAmountToPay(amount) {
  const toPay = Number(amount);
  const collectedFee = getStripeCharges(toPay);
  return (collectedFee + toPay) * 100;
}

export function getPlaidCharges(amount) {
  const PLAID_CHARGES = Number(amount) * (1 / 100);
  const PLAID_UPPER_LIMIT = 5;
  const PLAID_LOWER_LIMIT = 1;

  if (PLAID_CHARGES < PLAID_LOWER_LIMIT) {
    return PLAID_LOWER_LIMIT;
  }

  if (PLAID_CHARGES > PLAID_UPPER_LIMIT) {
    return PLAID_UPPER_LIMIT;
  }

  return PLAID_CHARGES;
}

export function convertStringToBoolean(value) {
  return !!+value;
}

export function convertBooleanToString(value) {
  if (value) {
    return '1';
  }

  return '0';
}

export const requestKYC = async () => {
  try {
    // TODO
  } catch (error) {
    // TODO
  }
};
