/* eslint-disable indent */
export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const MASTER = 'master';

export const REDIRECT_URL = process.env.REACT_APP_WEBSITE_URL;

export const API_URL = process.env.REACT_APP_API_URL || '';

export const ENDPOINT = API_URL.replace('api', '');

export const PENDING_PATH_KEY = 'PENDING_PATH';

export const formatToCurrency = (amount) =>
  amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

export const TIMELINE_URL = process.env.REACT_APP_TIMELINE_URL;

export const FOUNDER_ERP_URL = process.env.REACT_APP_FOUNDER_URL;

export const PERMISSIONS = {
  CAN_ADD_TWENTY_PLUS_MEMBERS: 'canAdd20+Members',
  CAN_ADD_FIVE_PLUS_DEALS: 'canAdd5+deals',
};

export const INDEX_OF_DEAL_SUBSCRIPTION = 1;

export const ACH_UPPER_LIMIT = 95; // 95 cents

export const STRIPE_CHARGE = 4.1;

export const UNITED_STATES = 'United States';

export const NIGERIAN_PAYMENT_METHODS = {
  PAYSTACK: 'paystack',
  FLW: 'flutterwave',
  MONO: 'mono',
};

export const CARD_PAYMENT_ALIASES = {
  APPLE_PAY: 'apple_pay',
  GOOGLE_PAY: 'google_pay',
};

export const US_BANK_ACCOUNT = 'us_bank_account';

const getLocale = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  }
  return (
    navigator.userLanguage ||
    navigator.language ||
    navigator.browserLanguage ||
    'en'
  );
};

const strongify = (value, currency = 'USD') =>
  `<strong>${Number(value).toLocaleString(getLocale(), {
    style: 'currency',
    currency,
  })}</strong>`;

const CARD_OPTION = {
  name: 'Card',
  method: 'card',
};

const FLW_OPTION = {
  name: 'Flutterwave',
  method: NIGERIAN_PAYMENT_METHODS.FLW,
  info: `For Nigerian users, payments above ${strongify(
    10000,
    'NGN'
  )} attract a stamp duty of ${strongify(50, 'NGN')}.`,
};

const APPLE_PAY = {
  name: 'Apple Pay',
  method: CARD_PAYMENT_ALIASES.APPLE_PAY,
};

const US_OPTIONS = [
  {
    name: 'ACH Transfer (Recommended)',
    method: US_BANK_ACCOUNT,
    info: `Transaction fees are capped at ${strongify(
      10
    )}. ACH transfers take 5 - 9 days to complete. <strong>Available only to users with a US bank account</strong>.`,
  },
  CARD_OPTION,
  APPLE_PAY,
  {
    name: 'Google Pay',
    method: CARD_PAYMENT_ALIASES.GOOGLE_PAY,
  },
  // {
  //   name: 'WeChat Pay',
  //   method: 'wechat_pay',
  // },
];

export const PAYMENT_OPTIONS = [
  {
    country: 'Nigeria',
    code: 'NG',
    currency: 'NGN',
    options: [
      FLW_OPTION,
      {
        name: 'Mono',
        method: NIGERIAN_PAYMENT_METHODS.MONO,
        info: `Payments above ${strongify(
          10000,
          'NGN'
        )} attract a stamp duty of ${strongify(50, 'NGN')}.`,
      },
      // {
      //   name: 'Paystack',
      //   method: NIGERIAN_PAYMENT_METHODS.PAYSTACK,
      //   info: `Payments above ${strongify(
      //     10000,
      //     'NGN'
      //   )} attract a stamp duty of ${strongify(50, 'NGN')}.`,
      // },
    ],
  },
  {
    country: UNITED_STATES,
    code: 'US',
    currency: 'USD',
    options: US_OPTIONS,
  },
  {
    country: 'European Union',
    code: 'EU',
    currency: 'EUR',
    options: [
      {
        name: 'SEPA Direct Debit (Recommended)',
        method: 'sepa_debit',
        info: `This attracts a 2.6% transaction fee. Transaction fees are capped at ${strongify(
          10
        )}. It can take up to 14 business days for transactions to be settled, though the average is 5 business days.`,
      },
      {
        name: 'Ideal (Netherlands only) (Recommended)',
        method: 'ideal',
        info: `This attracts a 1.7% + ${strongify(0.8)} transaction fee.`,
      },
      {
        name: 'Giropay (Germany only)',
        method: 'giropay',
        info: 'This attracts a 3.1% transaction fee.',
      },
      CARD_OPTION,
      APPLE_PAY,
    ],
  },
  {
    country: 'Canada',
    code: 'CAD',
    currency: 'USD',
    options: [
      // {
      //   method: 'acss_debit',
      //   name: 'Preauthorised Debit Payments (Recommended)',
      //   info: `Transaction fees are capped at ${strongify(4)}.`,
      // },
      ...US_OPTIONS,
    ],
  },
  {
    country: 'Ghana',
    code: 'GH',
    currency: 'USD',
    options: US_OPTIONS,
  },
  {
    country: 'Kenya',
    code: 'KY',
    currency: 'USD',
    options: US_OPTIONS,
  },
  {
    country: 'Tanzania',
    code: 'TZ',
    currency: 'USD',
    options: US_OPTIONS,
  },
  {
    country: 'Uganda',
    code: 'UG',
    currency: 'USD',
    options: US_OPTIONS,
  },
  {
    country: 'Algeria',
    code: 'ALG',
    currency: 'USD',
    options: US_OPTIONS,
  },
  {
    country: 'Rwanda',
    code: 'RW',
    currency: 'USD',
    options: US_OPTIONS,
  },
  {
    country: 'South Africa',
    code: 'RSA',
    currency: 'USD',
    options: US_OPTIONS,
  },
  {
    country: 'United Kingdom',
    code: 'UK',
    currency: 'USD',
    options: [CARD_OPTION, APPLE_PAY],
  },
  {
    country: 'Egypt',
    code: 'EGY',
    currency: 'USD',
    options: [APPLE_PAY, CARD_OPTION],
  },
  {
    country: 'Asia / Middle East',
    code: 'ASIA',
    currency: 'USD',
    options: [APPLE_PAY, CARD_OPTION],
  },
];

export const STRIPE_REDIRECT_KEY = 'STRIPE_REDIRECT_KEY';

export const NIGERIA = 'Nigeria';
