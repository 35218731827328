import { LOGOUT_REQUEST, SIGN_IN_REQUEST } from '../types';

export const signIn = (payload) => ({
  type: SIGN_IN_REQUEST,
  payload,
});

export const logout = () => ({
  type: LOGOUT_REQUEST,
});
