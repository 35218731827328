/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import '@babel/polyfill';
// Import all the third party stuff
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConnectedRouter } from 'connected-react-router/immutable';
import FontFaceObserver from 'fontfaceobserver';
import history from 'utils/history';
import 'sanitize.css/sanitize.css';
import 'react-virtualized/styles.css';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en'; // locale-data for en

import runtime from 'offline-plugin/runtime';

// import { ThemeProvider } from '@chakra-ui/core';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CookiesProvider } from 'react-cookie';

// Import root app
import App from 'containers/App';
import './styles/layout/base.scss';
import './styles/app.css';

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';

// Load the favicon and the .htaccess file
import '!file-loader?name=[name].[ext]!../public/favicons/favicon.ico'; // eslint-disable-line
import 'file-loader?name=.htaccess!./.htaccess'; // eslint-disable-line

// test import service worker plugin
// import runtime from 'serviceworker-webpack-plugin/lib/runtime';

// import chakraTheme from './config/chakraTheme';
import { Button } from '@material-ui/core';
import configureStore from './redux/configureStore';

// Import i18n messages
import { translationMessages } from './i18n';
import { setWithExpiry, getWithExpiry } from './utils/helpers';
import { UserProvider } from './redux/context';
import { WalletProvider } from './redux/walletContext';

// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Open Sans', {});

const queryClient = new QueryClient();

// When Open Sans is loaded, add a font-family using Open Sans to the body
openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

const isProductionEnvironment = () => process.env.NODE_ENV === 'production';

// initialize sentry for error reporting and performance monitoring
if (isProductionEnvironment()) {
  Sentry.init({
    dsn:
      'https://16b04b077d9d4675b68fe2b2a3b8a2d8@o567050.ingest.sentry.io/5710571',
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
}

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

const Fallback = ({ error }) => {
  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error && error.message && chunkFailedMessage.test(error.message)) {
      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 5000);
        window.location.reload();
      }
    }
  }, [error]);

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h3>
        An error occurred. Please{' '}
        <Button
          style={{
            padding: 4,
            borderRadius: 10,
            display: 'inline',
            color: 'white',
            backgroundColor: '#2196F3',
            fontWeight: 'bold',
          }}
          onClick={() => window.location.reload()}
        >
          reload
        </Button>{' '}
        the page to try again
      </h3>
    </div>
  );
};

const render = (messages) => {
  ReactDOM.render(
    <CookiesProvider>
      <Provider store={store}>
        <LanguageProvider messages={messages}>
          <QueryClientProvider client={queryClient}>
            <ConnectedRouter history={history}>
              <UserProvider>
                <WalletProvider>
                  {isProductionEnvironment() ? (
                    <Sentry.ErrorBoundary
                      fallback={({ error }) => <Fallback error={error} />}
                    >
                      <App />
                    </Sentry.ErrorBoundary>
                  ) : (
                    <App />
                  )}
                </WalletProvider>
              </UserProvider>
            </ConnectedRouter>
          </QueryClientProvider>
        </LanguageProvider>
      </Provider>
    </CookiesProvider>,
    MOUNT_NODE
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() =>
      Promise.all([
        import('intl/locale-data/jsonp/en.js'),
        import('intl/locale-data/jsonp/de.js'),
      ])
    ) // eslint-disable-line prettier/prettier
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}

runtime.install({ onUpdateReady: () => runtime.applyUpdate() });
