import Axios from 'axios';
import cookies from '../../config/cookies';
import { LOGGED_IN_STATE_KEY, OLD_LOGIN_KEY } from '../constants';

export const baseURL = process.env.REACT_APP_API_URL;

const http = Axios.create({
  baseURL,
  headers: {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
  },
});

export function getToken() {
  const token =
    cookies.get(OLD_LOGIN_KEY) ||
    sessionStorage.getItem(OLD_LOGIN_KEY) ||
    localStorage.getItem(OLD_LOGIN_KEY) ||
    cookies.get(LOGGED_IN_STATE_KEY);
  return token;
}

const addTokenToRequest = (request) => {
  const token = getToken();
  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
};

http.interceptors.request.use(addTokenToRequest);

export default http;
