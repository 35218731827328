import { baseURL } from '.';

export const userRequests = {
  PROFILE: '/profile',
  FORM_DATA: '/form-data',
  GET_NETWORK_DETAIL: (id) => `/network/${id}`,
  UPLOAD_IMAGE: '/profile/avatar',
  UPLOAD: '/founder/upload/image',
  ME: '/user/me',
  SUPPORT: '/support',
  GET_CONNECTIONS: '/user/connections?page=1&limit=100',
  getUser: (userId) => `/user/${userId}`,
};

export const authRequests = {
  LOGIN: '/user/network/login',
  NEW_LOGIN: '/user/login',
  SEND_VERIFICATION_CODE: (email) => `/user/network/send-code?email=${email}`,
};

export const emailRequests = {
  EMAIL: '/email',
  FETCH_SINGLE_EMAIL: (emailId, type) => `/email/${emailId}?type=${type}`,
};

export const chatRequest = {
  GET_GROUP_MESSAGE: (groupId) => `/network/group/chat/${groupId}`,
  SEND_GROUP_MESSAGE: (networkId) =>
    `/network/group/chat${networkId ? `?networkId=${networkId}` : ''}`,
  PRIVATE_MESSAGES: `${baseURL}/chat`,
  SEND_BULK_MESSAGES: `${baseURL}/chat/bulk`,
};

export const networkRequests = {
  NETWORKS: '/network',
  REQUEST_ACCESS_TO_SYNDICATE: '/syndicate/apply',
  GET_NETWORKS_OPEN_TO_INTER_NETWORK_ACTIVITIES:
    '/network?allowInterNetworksActivites=true',
  UPLOAD: '/upload',
  SUSPEND_USER: '/network/members/suspend',
  ACTIVATE_USER: '/network/members/activate',
  UPDATE_NETWORK: '/network/update',
  FILE_TO_DEAL: '/network/deal/file',
  GET_TRANSACTIONS: (networkId) =>
    `/network/deal/transactions?networkId=${networkId}`,
  GET_MY_INTEREST: (networkId) =>
    `/network/deal/deal-in-dealflow/interest/me?networkId=${networkId}&interest=true`,
  GET_DISINTERESTS: (networkId) =>
    `/network/deal/deal-in-dealflow/interest/me?networkId=${networkId}&interest=false`,
  GET_TERMS: (dealId, networkId) =>
    `/network/deal/terms/${dealId}?networkId=${networkId}`,
  FETCH_MEMBERS: (networkId) => `/network/members?networkId=${networkId}`,
  UPDATE_MEMBER_ROLE: '/network/members/role',
  FETCH_MEMBER: (userId, networkId) =>
    `/network/members/${userId}?networkId=${networkId}`,
  FETCH_NETWORK_APPLICATIONS: (networkId) =>
    `network/applications?networkId=${networkId}&isInvited=false`,
  INVITE: '/network/applications/invite?networkId=',
  FETCH_NETWORK_MEMBER_APPLICATION: (appId, networkId) =>
    `/network/applications/${appId}?networkId=${networkId}`,
  UPDATE_APPLICATION: (appId, networkId, value) =>
    `/network/applications/${value}/${appId}?networkId=${networkId}`,
  DELETE_APPLICATION: (appId, networkId) =>
    `/network/applications/${appId}?networkId=${networkId}`,
  CREATE_GROUP: '/network/group',
  CREATE_DEAL: '/network/deal',
  EDIT_DEAL: (dealId) => `/network/deal/${dealId}`,
  ADD_MEMBER: '/network/group/members',
  FETCH_COMPANY_INFO: (networkId, compId) =>
    `/network/deal/company-info/overview/${compId}?networkId=${networkId}`,
  REMOVE_MEMBER: (groupId) => `/network/group/${groupId}/members`,
  GET_MEMBERS: (groupId) => `/network/group/${groupId}/members`,
  GET_GROUPS: `${baseURL}/network/group`,
  DELETE_GROUPS: (groupId, networkId) =>
    `/network/group/${groupId}?networkId=${networkId}`,
  UPDATE_GROUPS: (groupId) => `/network/group/${groupId}`,
  FETCH_INVITES: (networkId) =>
    `network/applications?networkId=${networkId}&isInvited=true`,
  FETCH_DEALFLOW: (networkId) =>
    `/network/deal/dealflow?networkId=${networkId}`,
  FETCH_DEALS: (networkId, dealflowId) =>
    `/network/deal/deals-in-dealflow/${dealflowId}?networkId=${networkId}`,
  FETCH_DEAL: (networkId, dealflowId, dealId) =>
    `${baseURL}/network/deal/deal-in-dealflow/${dealflowId}?networkId=${networkId}&dealId=${dealId}`,
  FETCH_COMMENTS_IN_DEAL: (networkId, dealflowId, dealId) =>
    `${baseURL}/network/deal/deal-in-dealflow/comment/${dealflowId}?networkId=${networkId}&dealId=${dealId}`,
  ADD_COMMENT_TO_DEAL: () => `${baseURL}/network/deal/deal-in-dealflow/comment`,
  FETCH_RECENT_ACTIVITIES: (networkId) =>
    `network/recent-activities?networkId=${networkId}`,
  GET_ALL_INTEREST_IN_DEAL: (networkId, dealflowId, dealId) =>
    `${baseURL}/network/deal/deal-in-dealflow/interest/${dealflowId}?networkId=${networkId}&dealId=${dealId}`,
  CLOSE_DEAL: (networkId, dealId) =>
    `/network/deal/close/${dealId}?networkId=${networkId}`,
  CREATE_DEAL_TERMS: (networkId, dealId) =>
    `${baseURL}/network/deal/terms/${dealId}?networkId=${networkId}`,
  GET_DEAL_TERMS: (networkId, dealId) =>
    `${baseURL}/network/deal/terms/${dealId}?networkId=${networkId}`,
  GET_NETWORK_DEAL_TERMS: (networkId) =>
    `${baseURL}/network/terms/${networkId}`,
  GET_PERMITTED_USERS_FOR_DEAL: (dealId, networkId) =>
    `${baseURL}/network/deal/deal-permissions/${dealId}?networkId=${networkId}`,
  GET_CHANNELS: `${baseURL}/chat/channel`,
  GET_PRIVATE_MESSAGES: (channelId) => `${baseURL}/chat/${channelId}`,
  REOPEN_DEAL: (networkId, dealId) =>
    `${baseURL}/network/deal/open/${dealId}?networkId=${networkId}`,
  LOCK_DEAL: (networkId, dealId) =>
    `${baseURL}/network/deal/lock/${dealId}?networkId=${networkId}`,
  ARCHIVE_DEAL: (networkId, dealId) =>
    `${baseURL}/network/deal/deal-in-dealflow/${dealId}/archive?networkId=${networkId}`,
  RESEND_INVITE: (networkId) =>
    `${baseURL}/network/bulk-signup/resend-invite?networkId=${networkId}`,
  CREATE_SYNDICATE: `${baseURL}/syndicate`,
  EDIT_SYNDICATE: (syndicateId) => `${baseURL}/syndicate/${syndicateId}`,
  getTotalInvestmentsForAnSPV: (syndicateId) =>
    `/syndicate/investments/${syndicateId}`,
  GET_USER_INVESTMENTS_IN_SPVS: '/syndicate/user/investments',
  GET_SYNDICATES: `${baseURL}/syndicate`,
  GET_SYNDICATE: (syndicateId) => `${baseURL}/syndicate/${syndicateId}`,
  getSyndicateDeals: (syndicateId) => `/syndicate/deals/${syndicateId}`,
  getDealInSyndicate: (syndicateId, dealId) =>
    `/syndicate/deals/${syndicateId}?dealId=${dealId}`,
  GET_SYNDICATE_MEMBERS: (syndicateId) =>
    `${baseURL}/syndicate/members/${syndicateId}`,
  GET_SYNDICATE_APPLICANTS: (syndicateId) =>
    `${baseURL}/syndicate/applicants/${syndicateId}`,
  GET_SYNDICATE_REQUESTS: `${baseURL}/syndicate/request`,
  TOGGLE_SYNDICATE_REQUEST_STATUS: `${baseURL}/syndicate/request`,
  GET_ALL_DEALS: (networkId) =>
    `${baseURL}/network/deal/all?networkId=${networkId}`,
  SEND_CROSS_SYNDICATE_INVITES: (networkId) =>
    `${baseURL}/syndicate/networks/${networkId}/request`,
  ACCEPT_CROSS_SYNDICATE_REQUEST: (networkId) =>
    `${baseURL}/syndicate/networks/${networkId}`,
  EDIT_SYNDICATE_MEMBER_COMMITMENT: (syndicateId) =>
    `${baseURL}/syndicate/members/${syndicateId}/change-info`,
  GET_SYNDICATE_REQUESTS_FOR_NETWORK: (networkId) =>
    `${baseURL}/syndicate/networks/${networkId}`,
  GET_NETWORKS_IN_SYNDICATE: (syndicateId) =>
    `${baseURL}/syndicate/syndicate-networks/${syndicateId}`,
  GET_NETWORK_MEMBERS_AS_SYNDICATE_ADMIN: (syndicateId, networkId) =>
    `${baseURL}/syndicate/network-members/${syndicateId}?networkId=${networkId}`,
  TOGGLE_SYNDICATE_STATUS: (action, syndicateId) =>
    `${baseURL}/syndicate/change-status/${action}/${syndicateId}`,
  BULK_SIGN_UP: () => `${baseURL}/network/bulk-signup`,
  TOGGLE_INTEREST_IN_DEAL: '/network/deal/deal-in-dealflow/interest',
  getNetworkWalletBalance: (networkId) => `/network/wallet/${networkId}`,
  getDealBreakDown: (dealId, networkId) =>
    `/network/deal/investment-breakdown/${dealId}?networkId=${networkId}`,
  CREATE_NETWORK: '/network',
  investInSyndicate: (syndicateId) => `/syndicate/invest/${syndicateId}`,
  getSyndicatWallet: (syndicateId) => `/syndicate/wallet/${syndicateId}`,
  getCommissionWallet: (syndicateId) =>
    `/syndicate/commission-wallet/${syndicateId}`,
  payForSyndicate: (syndicateId) => `/syndicate/pay/${syndicateId}`,
  CALCULATE_FEES: '/syndicate/calculate-service-fee',
  CREATE_SPV: '/syndicate/private',
  GET_DEALS: (page = 1, limit = 50) => `/company?page=${page}&limit=${limit}`,
  SEARCH_DEALS: (name = '', incorporationType = '') =>
    `/company?page=1&name=${name}&incorporationType=${incorporationType}&limit=10`,
  ADD_DEAL_TO_SPV: '/syndicate/deals',
  ACCEPT_SYNDICATE_APPLICATION: '/syndicate/approve',
  DISBURSE_FUNDS: '/syndicate/disburse-funds',
  REGISTER_INTEREST: '/syndicate/interest',
  GET_SYNDICATE_DEAL_INTEREST: (syndicateId, dealId) =>
    `/syndicate/interest/${syndicateId}/${dealId}?interest=true`,
};

export const PAYMENT_REQUESTS = {
  GET_FLUTTERWAVE_SUBACCOUNT_REQUIREMENTS:
    '/payment/flutterwave-subaccounts/requirements',
  GET_STRIPE_SUBACCOUNT_REQUIREMENTS:
    '/payment/stripe-subaccounts/requirements',
  GET_BANKS: '/payment/banks',
  VERIFY_BANK_ACCOUNT: '/payment/resolve-bank',
  ONBOARD_FLUTTERWAVE: '/payment/flutterwave/onboard',
  GET_STRIPE_SUPPORTED_COUNTRIES: '/payment/stripe-supported-countries',
  ONBOARD_STRIPE: '/payment/stripe/onboard',
  PAYMENT_INTEGRATIONS: '/payment/payment-details',
  GET_STRIPE_DASHBOARD: '/payment/stripe/dashboard-link',
  getSupportedPaymentMethods: (dealId) =>
    `/payment/supported-payment-gateway/${dealId}`,
  initializeFlutterwavePaymentForDeal: (
    interestId,
    commitmentTopUp,
    commitmentTopUpInUserCurrency
  ) =>
    `/payment/flutterwave/deal/initialize/${interestId}${
      commitmentTopUp ? `?commitmentTopUp=${commitmentTopUp}` : ''
    }${
      commitmentTopUpInUserCurrency
        ? `&commitmentTopUpInUserCurrency=${commitmentTopUpInUserCurrency}`
        : ''
    }`,
  CONFIRM_FLUTTERWAVE_PAYMENT: '/payment//flutterwave/deal/confirm',
  INITIALIZE_WALLET_TOP_FROM_FLUTTERWAVE: '/wallet/flutterwave/initialize',
  CONFIRM_WALLET_TOP_FROM_FLUTTERWAVE: '/wallet/flutterwave/confirm',
  INITIALIZE_WALLET_TOP_FROM_STRIPE: '/wallet/stripe/initialize',
  CONFIRM_WALLET_TOP_FROM_STRIPE: '/wallet/stripe/confirm',
  investFromWallet: (interestId) => `/network/deal/invest/${interestId}`,
  CURRENCY_CONVERT: '/exchange-rate/convert',
  payoutDeal: (dealId) => `/network/deal/close-for-payout/${dealId}`,
  getExchangeRate: (fromCurrency = 'NGN', toCurrency = 'USD') =>
    `/exchange-rate?fromCurrency=${fromCurrency}&toCurrency=${toCurrency}`,
  WITHDRAW_NETWORK_FUNDS: '/wallet/withdraw/network',
  markInterestAsPaid: (interestId) =>
    `/network/deal/update-payment-status/${interestId}`,
  INITIALIZE_WALLET_TOP_FROM_MONO: '/wallet/mono/initialize',
  GET_PLAID_TOKEN: '/payment/plaid/link-token',
  EXCHANGE_TOKEN_FOR_VALUE: '/payment/plaid/exchange-token',
  USER_PAYMENT_DETAILS: '/payment/payment-details',
  INITIALIZE_PLAID_PAYMENT: '/wallet/stripe/transfer/initialize',
  getInvestmentCharges: (provider) => `/wallet/${provider}/fee`,
  WITHDRAW_SPV_COMMISSION: '/syndicate/commission/withdraw',
  GENERATE_STRIPE_CHECKOUT_URL: '/wallet/stripe/checkout',
  GET_STRIPE_SUPPORTED_PAYMENT_METHODS: '/wallet/paymentMethod',
};

export const SUBSCRIPTION_REQUESTS = {
  GET_PLANS: '/subscription/plans?type=deal-manager',
  INITIALIZE: '/subscription/initialize',
  CONFIRM: '/subscription/confirm?type=deal-manager',
  UPGRADE: '/subscription/upgrade?type=deal-manager',
  TOGGLE_AUTO_RENEW: '/subscription/toggle-renewal?type=deal-manager',
};
