/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Collapse from '@material-ui/core/Collapse';
import Chip from '@material-ui/core/Chip';
import Ionicon from 'react-ionicons';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import menu2 from 'dan-api/ui/secondMenu';
import adminMenu from 'dan-api/ui/adminMenu';
import noNetworkMenu from 'dan-api/ui/noNetworkUserMenu';
import styles from './sidebar-jss';
import { extractNetwork } from '../../utils/helpers';

const LinkBtn = React.forwardRef((props, ref) => (
  <NavLink to={props.to} {...props} innerRef={ref} />
));
function MainMenu(props) {
  const { classes, openSubMenu, open, dataMenu, profile } = props;
  const [unReadDeals, setUnreadDeals] = useState(0);
  const [unReadScreening, setUnreadScreening] = useState(0);
  const [unReadMails, setUnreadMails] = useState(0);
  const [unReadMessages, setUnreadMessages] = useState(0);
  const [menuValues, setMenuValues] = useState(noNetworkMenu);

  useEffect(() => {
    if (profile && profile.unReadDeals && profile.unReadDeals.length) {
      setUnreadDeals(profile.unReadDeals[0].unseenDeals);
      setUnreadMessages(profile.unReadMessages);
      setUnreadMails(profile.unReadEmails);
      if (profile.unReadDeals[1]) {
        setUnreadScreening(profile.unReadDeals[1].unseenDeals);
      }
    }
  }, [profile]);

  const handleClick = useCallback(() => {
    const { toggleDrawerOpen, loadTransition } = props;
    toggleDrawerOpen();
    loadTransition(false);
  }, []);

  useEffect(() => {
    let network = extractNetwork(profile.data);
    const { isAdmin, isSuperAdmin } = network;
    if (!network.networkId) {
      setMenuValues(noNetworkMenu);
    } else {
      isSuperAdmin
        ? setMenuValues(dataMenu)
        : isAdmin
        ? setMenuValues(adminMenu)
        : setMenuValues(menu2);
    }
  }, [profile]);

  const getMenus = (menuArray) =>
    menuArray.map((item, index) => {
      if (item.child || item.linkParent) {
        if (item.child) {
          [...item.child].forEach((child, index) => {
            if (child.key === 'discover') {
              item.child[index].badge = unReadDeals;
            }

            if (child.key === 'screening') {
              item.child[index].badge = unReadScreening;
            }

            if (child.key === 'email') {
              item.child[index].badge = unReadMails;
            }

            if (child.key === 'chat') {
              item.child[index].badge = unReadMessages;
            }
          });
        }
        return (
          <div key={index.toString()}>
            <ListItem
              button
              component={LinkBtn}
              to={item.linkParent ? item.linkParent : '#'}
              className={classNames(
                classes.head,
                item.icon ? classes.iconed : '',
                open.indexOf(item.key) > -1 ? classes.opened : ''
              )}
              onClick={() => openSubMenu(item.key, item.keyParent)}
              key={index}
            >
              {item.icon && (
                <ListItemIcon className={classes.icon}>
                  <Ionicon icon={item.icon} />
                </ListItemIcon>
              )}
              <ListItemText
                classes={{ primary: classes.primary }}
                variant='inset'
              >
                {item.name}{' '}
                {item.isBeta ? (
                  <span
                    style={{
                      color: 'white',
                      display: 'inline-block',
                      border: 'blue',
                      borderRadius: 6,
                      background: blue[500],
                      padding: 2,
                      marginLeft: 4,
                    }}
                  >
                    beta
                  </span>
                ) : null}
                {item.isNew ? (
                  <span
                    style={{
                      color: 'white',
                      display: 'inline-block',
                      border: 'blue',
                      borderRadius: 6,
                      background: blue[500],
                      padding: 2,
                      marginLeft: 4,
                    }}
                  >
                    new
                  </span>
                ) : null}
              </ListItemText>
              {!item.linkParent && (
                <span>
                  {open.indexOf(item.key) > -1 ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </span>
              )}
            </ListItem>
            {!item.linkParent && (
              <Collapse
                component='div'
                className={classNames(
                  classes.nolist,
                  item.keyParent ? classes.child : ''
                )}
                in={open.indexOf(item.key) > -1}
                timeout='auto'
                unmountOnExit
              >
                <List className={classes.dense} component='nav' dense>
                  {getMenus(item.child, 'key')}
                </List>
              </Collapse>
            )}
          </div>
        );
      }
      if (item.title) {
        return (
          <ListSubheader
            disableSticky
            key={index.toString()}
            component='div'
            className={classes.title}
          >
            {item.name}
          </ListSubheader>
        );
      }
      const isExternal = item.link && item.link.includes('http');
      if (isExternal) {
        return (
          <div key={Math.random()}>
            <ListItem
              key={index.toString()}
              button
              className={classes.nested}
              component='a'
              href={item.link}
            >
              <ListItemText
                classes={{ primary: classes.primary }}
                inset
                primary={item.name}
              />
              {item.badge ? (
                <Chip variant='outlined' color='primary' label={item.badge} />
              ) : null}
            </ListItem>
          </div>
        );
      }
      return (
        <div key={Math.random()}>
          <ListItem
            key={index.toString()}
            button
            exact
            className={classes.nested}
            activeClassName={classes.active}
            component={LinkBtn}
            to={item.link}
            onClick={() => handleClick()}
          >
            <ListItemText
              classes={{ primary: classes.primary }}
              inset
              primary={item.name}
            />
            {item.badge ? (
              <Chip variant='outlined' color='primary' label={item.badge} />
            ) : null}
          </ListItem>
        </div>
      );
    });
  return <div>{getMenus(menuValues)}</div>;
}

MainMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.object.isRequired,
  openSubMenu: PropTypes.func.isRequired,
  toggleDrawerOpen: PropTypes.func.isRequired,
  loadTransition: PropTypes.func.isRequired,
  dataMenu: PropTypes.array.isRequired,
};

const openAction = (key, keyParent) => ({
  type: 'OPEN_SUBMENU',
  key,
  keyParent,
});
const reducer = 'ui';

const mapStateToProps = (state) => ({
  force: state, // force active class for sidebar menu
  open: state.getIn([reducer, 'subMenuOpen']),
  profile: state.toJS().profile,
});

const mapDispatchToProps = (dispatch) => ({
  openSubMenu: bindActionCreators(openAction, dispatch),
});

const MainMenuMapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainMenu);

export default withStyles(styles)(MainMenuMapped);
