/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import styles from './header-jss';
import { FOUNDER_ERP_URL, TIMELINE_URL } from '../../utils/constants';

const buttonStyles = {
  borderRadius: 7,
  padding: 6,
  margin: 6,
};

export function AppBox() {
  return (
    <>
      <a
        style={buttonStyles}
        href={FOUNDER_ERP_URL}
        title='Create your company'
      >
        Stack
      </a>
      <a
        style={buttonStyles}
        href={TIMELINE_URL}
        title='Connect with members of the community'
      >
        Connect
      </a>
    </>
  );
}

export const Apps = withStyles(styles)(AppBox);
