/* eslint-disable import/prefer-default-export */
/* eslint-disable linebreak-style */
/* eslint-disable no-useless-escape */
import cookies from './config/cookies';
import { baseURL, getToken } from './redux/api';

const publicVapidKey =
  'BEJ4S3FVXaf3jZM4BrUQWIdewhfreEC-DVINcQkeWaXft4ituUhYKHuiA5nxntgMYwBwmqBQSRdW7QuTvKZfzhc';

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

// Register Serviceworker, Register Push, Send Push
async function setUpPushNotification(register) {
  const subscription = await register.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
  });
  console.log('Push notification service registered...');

  console.log('Creating Subscription to push service');
  await fetch(`${baseURL}/subscribe-to-push`, {
    method: 'POST',
    body: JSON.stringify({ subscription }),
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });
}

export async function registerServiceWorker(registration) {
  console.log('Registering Push notification service...');
  setUpPushNotification(registration);
}
