export const FETCH_PROFILE_ERROR = 'FETCH_PROFILE_ERROR';
export const FETCH_PROFILE_REQUEST = 'FETCH_PROFILE_REQUEST';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';

export const CREATE_PROFILE_ERROR = 'CREATE_PROFILE_ERROR';
export const CREATE_PROFILE_REQUEST = 'CREATE_PROFILE_REQUEST';
export const CREATE_PROFILE_SUCCESS = 'CREATE_PROFILE_SUCCESS';

export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';
export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';

export const FETCH_PROFILE_FORM_DATA_REQUEST =
  'FETCH_PROFILE_FORM_DATA_REQUEST';
export const FETCH_PROFILE_FORM_DATA_SUCCESS =
  'FETCH_PROFILE_FORM_DATA_SUCCESS';
export const FETCH_PROFILE_FORM_DATA_ERROR = 'FETCH_PROFILE_FORM_DATA_ERROR';

export const FETCH_NETWORKS_REQUEST = 'FETCH_NETWORKS_REQUEST';
export const FETCH_NETWORKS_SUCCESS = 'FETCH_NETWORKS_SUCCESS';
export const FETCH_NETWORKS_ERROR = 'FETCH_NETWORKS_ERROR';

export const FETCH_NETWORKS_APPLICATIONS_REQUEST =
  'FETCH_NETWORKS_APPLICATIONS_REQUEST';
export const FETCH_NETWORKS_APPLICATIONS_SUCCESS =
  'FETCH_NETWORKS_APPLICATIONS_SUCCESS';
export const FETCH_NETWORKS_APPLICATIONS_ERROR =
  'FETCH_NETWORKS_APPLICATIONS_ERROR';

export const FETCH_NETWORKS_MEMBER_APPLICATION_REQUEST =
  'FETCH_NETWORKS_MEMBER_APPLICATIONS_REQUEST';
export const FETCH_NETWORKS_MEMBER_APPLICATIONS_SUCCESS =
  'FETCH_NETWORKS_MEMBER_APPLICATIONS_SUCCESS';
export const FETCH_NETWORKS_MEMBER_APPLICATIONS_ERROR =
  'FETCH_NETWORKS_MEMBER_APPLICATIONS_ERROR';

export const FETCH_NETWORK_MEMBERS_REQUEST = 'FETCH_NETWORK_MEMBERS_REQUEST';
export const FETCH_NETWORK_MEMBERS_SUCCESS = 'FETCH_NETWORK_MEMBERS_SUCCESS';
export const FETCH_NETWORK_MEMBERS_ERROR = 'FETCH_NETWORK_MEMBERS_ERROR';

export const FETCH_NETWORK_MEMBER_REQUEST = 'FETCH_NETWORK_MEMBER_REQUEST';
export const FETCH_NETWORK_MEMBER_SUCCESS = 'FETCH_NETWORK_MEMBER_SUCCESS';
export const FETCH_NETWORK_MEMBER_ERROR = 'FETCH_NETWORK_MEMBER_ERROR';

export const INVITE_MEMBERS_REQUEST = 'INVITE_MEMBERS_REQUEST';
export const INVITE_MEMBERS_SUCCESS = 'INVITE_MEMBERS_SUCCESS';
export const INVITE_MEMBERS_ERROR = 'INVITE_MEMBERS_ERROR';

export const UPDATE_APPLICATION_REQUEST = 'UPDATE_APPLICATION_REQUEST';
export const UPDATE_APPLICATION_SUCCESS = 'UPDATE_APPLICATION_SUCCESS';
export const UPDATE_APPLICATION_ERROR = 'UPDATE_APPLICATION_ERROR';

export const DELETE_APPLICATION_REQUEST = 'DELETE_APPLICATION_REQUEST';
export const DELETE_APPLICATION_SUCCESS = 'DELETE_APPLICATION_SUCCESS';
export const DELETE_APPLICATION_ERROR = 'DELETE_APPLICATION_ERROR';

export const NETWORK_INVITATION_REQUEST = 'NETWORK_INVITATION_REQUEST';
export const NETWORK_INVITATION_SUCCESS = 'NETWORK_INVITATION_SUCCESS';
export const NETWORK_INVITATION_ERROR = 'NETWORK_INVITATION_ERROR';

export const FETCH_INVITES_REQUEST = 'FETCH_INVITES_REQUEST';
export const FETCH_INVITES_SUCCESS = 'FETCH_INVITES_SUCCESS';
export const FETCH_INVITES_ERROR = 'FETCH_INVITES_ERROR';

export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';

export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_ERROR = 'SEND_EMAIL_ERROR';

export const FETCH_EMAILS_REQUEST = 'FETCH_EMAILS_REQUEST';
export const FETCH_EMAILS_SUCCESS = 'FETCH_EMAILS_SUCCESS';
export const FETCH_EMAILS_ERROR = 'FETCH_EMAILS_ERROR';

export const CLEAR_NETWORK_MESSAGES = 'CLEAR_NETWORK_MESSAGES';
export const CLEAR_PROFILE_MESSAGES = 'CLEAR_PROFILE_MESSAGES';
export const CLEAR_EMAIL_MESSAGES = 'CLEAR_EMAIL_MESSAGES';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';

export const CREATE_GROUP_REQUEST = 'CREATE_GROUP_REQUEST';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_ERROR = 'CREATE_GROUP_ERROR';

export const ADD_MEMBERS_REQUEST = 'ADD_MEMBERS_REQUEST';
export const ADD_MEMBERS_SUCCESS = 'ADD_MEMBERS_SUCCESS';
export const ADD_MEMBERS_ERROR = 'ADD_MEMBERS_ERROR';

export const REMOVE_MEMBERS_REQUEST = 'REMOVE_MEMBERS_REQUEST';
export const REMOVE_MEMBERS_SUCCESS = 'REMOVE_MEMBERS_SUCCESS';
export const REMOVE_MEMBERS_ERROR = 'REMOVE_MEMBERS_ERROR';

export const FETCH_GROUP_MEMBERS_REQUEST = 'FETCH_GROUP_MEMBERS_REQUEST';
export const FETCH_GROUP_MEMBERS_SUCCESS = 'FETCH_GROUP_MEMBERS_SUCCESS';
export const FETCH_GROUP_MEMBERS_ERROR = 'FETCH_GROUP_MEMBERS_ERROR';

export const FETCH_GROUP_REQUEST = 'FETCH_GROUP_REQUEST';
export const FETCH_GROUP_SUCCESS = 'FETCH_GROUP_SUCCESS';
export const FETCH_GROUP_ERROR = 'FETCH_GROUP_ERROR';

export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_ERROR = 'DELETE_GROUP_ERROR';

export const UPDATE_GROUP_REQUEST = 'UPDATE_GROUP_REQUEST';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_ERROR = 'UPDATE_GROUP_ERROR';

export const FETCH_DEALFLOW = 'FETCH_DEALFLOW';
export const FETCH_DEALFLOW_SUCCESS = 'FETCH_DEALFLOW_SUCCESS';
export const FETCH_DEALFLOW_ERROR = 'FETCH_DEALFLOW_ERROR';

export const FETCH_DEAL = 'FETCH_DEAL';
export const FETCH_DEAL_SUCCESS = 'FETCH_DEAL_SUCCESS';
export const FETCH_DEAL_ERROR = 'FETCH_DEAL_ERROR';

export const FETCH_SINGLE_DEAL = 'FETCH_SINGLE_DEAL';
export const FETCH_SINGLE_DEAL_SUCCESS = 'FETCH_SINGLE_DEAL_SUCCESS';
export const FETCH_SINGLE_DEAL_ERROR = 'FETCH_SINGLE_DEAL_ERROR';

export const FETCH_COMMENTS = 'FETCH_COMMENTS';
export const FETCH_COMMENTS_SUCCESS = 'FETCH_COMMENTS_SUCCESS';
export const FETCH_COMMENTS_ERROR = 'FETCH_COMMENTS_ERROR';

export const ADD_COMMENT = 'ADD_COMMENT';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_ERROR = 'ADD_COMMENT_ERROR';

export const FETCH_RECENT_ACTIVITIES = 'FETCH_RECENT_ACTIVITIES';
export const FETCH_RECENT_ACTIVITIES_SUCCESS =
  'FETCH_RECENT_ACTIVITIES_SUCCESS';
export const FETCH_RECENT_ACTIVITIES_ERROR = 'FETCH_RECENT_ACTIVITIES_ERROR';

export const TOGGLE_INTEREST = 'TOGGLE_INTEREST';
export const TOGGLE_INTEREST_SUCCESS = 'TOGGLE_INTEREST_SUCCESS';
export const TOGGLE_INTEREST_ERROR = 'TOGGLE_INTEREST_ERROR';

export const GET_ALL_DEAL_INTEREST = 'GET_ALL_DEAL_INTEREST';
export const GET_ALL_DEAL_INTEREST_SUCCESS = 'GET_ALL_DEAL_INTEREST_SUCCESS';
export const GET_ALL_DEAL_INTEREST_ERROR = 'GET_ALL_DEAL_INTEREST_ERROR';

export const CLOSE_DEAL = 'CLOSE_DEAL';
export const CLOSE_DEAL_SUCCESS = 'CLOSE_DEAL_SUCCESS';
export const CLOSE_DEAL_ERROR = 'CLOSE_DEAL_ERROR';

export const UNREAD_MESAGES = 'UNREAD_MESAGES';
export const SOCKET_CONNECTION = 'SOCKET_CONNECTION';
export const UNREAD_DEALS = 'UNREAD_DEALS';
export const UNREAD_EMAILS = 'UNREAD_EMAILS';
export const UNREAD_APPLICATIONS = 'UNREAD_APPLICATIONS';
