import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  makeStyles,
  Select,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useContext, useState } from 'react';
// import { loadStripe } from '@stripe/stripe-js';
import { requestKYC } from '../helpers';
import { useMobile } from './hooks';
import { extractErrorMessage } from '../../redux/api/helpers';
import { CloseDialogButton } from './CloseModalButton';
import { UserContext } from '../../redux/context';
import { CircularIndeterminateSm } from '../../components/Progress/CircularIndeterminate';

// loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  formControl: {
    width: '100%',
    margin: '10px 0',
  },
}));

const AVAILABLE_COUNTRIES = [
  { name: 'Nigeria', code: 'NG' },
  { name: 'United States', code: 'US' },
  { name: 'United Kingdom', code: 'UK' },
  { name: 'Ghana', code: 'GH' },
  { name: 'United Arab Emirates', code: 'UAE' },
];

const DOCUMENT_OPTIONS = [
  { name: 'International Passport', value: 'PP' },
  { name: "Driver's License", value: 'DL' },
  { name: 'Government Issued Identity Card', value: 'ID' },
  { name: 'Residence Permit', value: 'RP' },
  { name: 'Utility Bill', value: 'UB' },
];

export function VerifyIdentity({ open, close }) {
  const [selectedCountry, setSelectedCountry] = useState(
    AVAILABLE_COUNTRIES[0].code
  );
  const [documentType, setDocumentType] = useState(DOCUMENT_OPTIONS[0].value);
  const [error, setError] = useState('');
  const [successful, setSuccessful] = useState(false);
  const fullScreen = useMobile();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState('');
  const { reloadProfile } = useContext(UserContext);

  const documentSelectedName = DOCUMENT_OPTIONS.find(
    ({ value }) => value === documentType
  );

  const { root, formControl } = useStyles();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError('');
      await requestKYC();
      setLoading(false);
      setTimeout(reloadProfile, 3000);
      return setSuccessful(true);
    } catch (e) {
      const errorMessage = extractErrorMessage(e);
      setError(errorMessage);
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      fullScreen={fullScreen}
      disableBackdropClick
    >
      <DialogTitle>Identity Verification.</DialogTitle>
      <CloseDialogButton onClick={close} />
      <DialogContent>
        <DialogContentText>
          {error ? (
            <Alert severity='error'>{error}</Alert>
          ) : successful ? (
            <Alert severity='success'>
              Successfully verified your identity. You can now access our full
              suite of features for investments.
            </Alert>
          ) : (
            <>
              Please upload the relevant documents for us to verify your
              identity. Identity verification is required for regulatory
              compliance.
            </>
          )}
        </DialogContentText>
        <form
          className={root}
          onFocus={() => setError('')}
          onSubmit={handleSubmit}
          id='kyc'
        >
          <FormControl className={formControl} required>
            <FormLabel>Select Country</FormLabel>
            <Select
              onChange={(e) => setSelectedCountry(e.target.value)}
              fullWidth
              native
              value={selectedCountry}
            >
              {AVAILABLE_COUNTRIES.map(({ code, name }) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl className={formControl} required>
            <FormLabel>Select Document Type</FormLabel>
            <Select
              onChange={(e) => setDocumentType(e.target.value)}
              fullWidth
              native
              value={documentType}
            >
              {DOCUMENT_OPTIONS.map(({ value, name }) => (
                <option key={value} value={value}>
                  {name}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl className={formControl} required>
            <FormLabel>Upload Document</FormLabel>
            <TextField
              onChange={(e) => setFile(e.target.files[0])}
              fullWidth
              type='file'
              inputProps={{ accept: 'image/*' }}
              required
            />
          </FormControl>
          <FormHelperText>
            {documentSelectedName.name === 'International Passport'
              ? 'Upload an image of the first page of your passport.'
              : `Upload an image of the front page of your ${
                  documentSelectedName.name
                }`}
          </FormHelperText>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} variant='contained' color='secondary'>
          Cancel
        </Button>
        <Button
          disabled={loading}
          variant='contained'
          color='primary'
          form='kyc'
          type='submit'
        >
          {loading ? <CircularIndeterminateSm size='16px' /> : 'Verify'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function KYCAlert() {
  const [openVerifyId, setOpenVerifyId] = useState(false);
  return (
    <Alert severity='error'>
      Your identity is not verified. Please{' '}
      <Button
        variant='contained'
        color='primary'
        onClick={() => setOpenVerifyId(true)}
        size='small'
      >
        verify your identity
      </Button>{' '}
      to continue.
      <VerifyIdentity
        open={openVerifyId}
        close={() => setOpenVerifyId(false)}
      />
    </Alert>
  );
}
