/* eslint-disable no-confusing-arrow */
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Tooltip from '@material-ui/core/Tooltip';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import ListSubheader from '@material-ui/core/ListSubheader';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Flag from '@material-ui/icons/Flag';
import People from '@material-ui/icons/People';
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';
import ReportIcon from '@material-ui/icons/Report';
import LabelIcon from '@material-ui/icons/Label';
import FileIcon from '@material-ui/icons/Description';
import Download from '@material-ui/icons/CloudDownload';
import Divider from '@material-ui/core/Divider';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import DOMPurify from 'dompurify';
import styled from 'styled-components';
import isImage from '../Forms/helpers/helpers.js';
import styles from './email-jss';
import CircularIndeterminate from '../Progress/CircularIndeterminate';

import { clearEmailMessages } from '../../redux/actions/emailActions.js';

const LoaderWrapper = styled.div`
  & {
    width: 100%;
    height: 100%;
    display: flex;
    align: center;
    margin-bottom: 20px;
    justify-content: center;
  }
`;

const ITEM_HEIGHT = 80;
function EmailList(props) {
  const [anchorElOpt, setAnchorElOpt] = useState(null);
  const [itemToMove, setItemToMove] = useState(null);
  const [expanded, setExpanded] = React.useState(false);
  const dispatch = useDispatch();

  const {
    classes,
    emailData,
    openMail,
    filterPage,
    keyword,
    serverError,
    setServerError,
    loading,
    response,
    createDeal,
    done,
    remove,
    toggleStar,
    reply,
  } = props;
  const handleClickOpt = (event, item) => {
    setAnchorElOpt(event.currentTarget);
    setItemToMove(item);
  };

  const handleCloseOpt = () => {
    setAnchorElOpt(null);
  };

  const handleMoveTo = (item, category) => {
    const { moveTo } = props;
    moveTo(item, category);
    setAnchorElOpt(null);
  };

  const handleChange = (panel, messageId) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    openMail(messageId);
  };

  const emailListState = useSelector((state) => state.toJS().email2.emailList);
  const emailListData = emailListState.data.data || [];
  let { user, data } = useSelector((state) => state.toJS().profile.data);
  user = user || data || {};
  const currentUserEmail = user.customEmail;

  useEffect(() => {
    if (serverError) {
      setTimeout(() => {
        setServerError('');
        dispatch(clearEmailMessages('emailList'));
      }, 5000);
    }
  }, [serverError]);

  useEffect(() => {
    if (
      Object.keys(emailListState.errors || {}).length !== 0 &&
      emailListState.errors.constructor !== Object
    ) {
      setServerError(emailListState.errors);
    }
  }, [emailListState.errors]);

  /* Basic Filter */
  // const inbox = emailData.filter(item => item.get('category') !== 'sent' && item.get('category') !== 'spam');
  // const stared = emailData.filter(item => item.get('stared'));
  // const sent = emailData.filter(item => item.get('category') === 'sent');
  // const spam = emailData.filter(item => item.get('category') === 'spam');
  // /* Category Filter */
  // const updates = emailData.filter(item => item.get('category') === 'updates');
  // const social = emailData.filter(item => item.get('category') === 'social');
  // const forums = emailData.filter(item => item.get('category') === 'forums');
  // const promos = emailData.filter(item => item.get('category') === 'promos');

  const attachmentPreview = (filesArray) =>
    filesArray.map((file, index) => {
      if (isImage(file)) {
        return (
          <div key={index.toString()} className={classes.item}>
            <div className='imageContainer col fileIconImg'>
              <div className='downloadBtn'>
                <IconButton
                  color='secondary'
                  component='a'
                  target='_blank'
                  href={file.attachment}
                  download
                >
                  <Download />
                </IconButton>
              </div>
              <figure className='imgWrap'>
                <img
                  className='smallPreviewImg'
                  src={file.attachment}
                  alt='preview'
                />
              </figure>
            </div>
            <Typography noWrap>{file.fileName}</Typography>
          </div>
        );
      }
      return (
        <div key={index.toString()} className={classes.item}>
          <div className='imageContainer col fileIconImg'>
            <div className='fileWrap'>
              <div className='downloadBtn'>
                <IconButton
                  color='secondary'
                  component='a'
                  target='_blank'
                  href={file.attachment}
                  download
                >
                  <Download />
                </IconButton>
              </div>
              <FileIcon className='smallPreviewImg' alt='preview' />
            </div>
          </div>
          <Typography noWrap>{file.fileName}</Typography>
        </div>
      );
    });
  const getEmail = (dataArray) =>
    dataArray.length ? (
      dataArray.map((mail, index) => {
        const renderHTML = {
          __html: response && DOMPurify.sanitize(response.html),
        };
        // if (mail.get('subject').toLowerCase().indexOf(keyword) === -1) {
        //   return false;
        // }
        const capitalize = (str, lower = false) =>
          (lower ? str.toLowerCase() : str).replace(
            /(?:^|\s|["'([{])+\S/g,
            (match) => match.toUpperCase()
          );
        const getEmailAlias = (email) =>
          currentUserEmail === email ? 'me' : email;
        return (
          <ExpansionPanel
            className={classes.emailList}
            key={index}
            expanded={expanded === index}
            onChange={handleChange(index, mail.messageId)}
          >
            <ExpansionPanelSummary
              className={classes.emailSummary}
              expandIcon={<ExpandMoreIcon />}
            >
              <div className={classes.fromHeading}>
                {mail.category !== 'spam' ? (
                  <Avatar
                    alt='avatar'
                    src={mail.avatar}
                    className={classes.avatar}
                  >
                    {mail.from.text ? mail.from.text[0].toUpperCase() : null}
                  </Avatar>
                ) : (
                  <Avatar alt='avatar' className={classes.avatar}>
                    <ReportIcon />
                  </Avatar>
                )}
                <Typography className={classes.heading} display='block'>
                  {/* {mail['category'] === 'sent' && ('To ')} */}
                  {mail.from.text
                    ? capitalize(getEmailAlias(mail.from.text).split('<')[0])
                    : null}
                  <Typography variant='caption' display='block'>
                    {format(new Date(mail.date), 'LLL, dd yyyy')}
                  </Typography>
                </Typography>
              </div>
              <div className={classes.column}>
                <Typography className={classes.secondaryHeading} noWrap>
                  {mail.subject}
                </Typography>
              </div>
            </ExpansionPanelSummary>

            {loading ? (
              <LoaderWrapper>
                <CircularIndeterminate />
              </LoaderWrapper>
            ) : (
              <div>
                <ExpansionPanelDetails className={classes.details}>
                  <section>
                    <div className={classes.topAction}>
                      <Typography className={classes.headMail}>
                        {mail.category !== 'sent' && (
                          <Fragment>
                            From&nbsp;
                            {getEmailAlias(mail.from.text)}
                            &nbsp;to {'me'}
                          </Fragment>
                        )}
                      </Typography>
                      <div className={classes.opt}>
                        <Tooltip id='tooltip-mark' title='Remove mail'>
                          <IconButton
                            className={classes.button}
                            aria-label='Delete'
                            onClick={() => remove(mail)}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <div className={classes.emailContent}>
                      <Typography variant='h6' gutterBottom>
                        {mail.subject}
                      </Typography>
                      <article dangerouslySetInnerHTML={renderHTML} />
                    </div>
                    <div className={classes.preview}>
                      {attachmentPreview(
                        (response && response.attachments) || []
                      )}
                    </div>
                  </section>
                </ExpansionPanelDetails>
                <Divider />
                <ExpansionPanelActions>
                  <div className={classes.action}>
                    {/* <Button size='small'>Forward</Button> */}
                    <Button
                      size='small'
                      color='secondary'
                      onClick={() => reply(mail)}
                    >
                      Reply
                    </Button>
                    <Button
                      size='small'
                      color='primary'
                      onClick={() => createDeal(response.attachments)}
                    >
                      Create Deal
                    </Button>
                  </div>
                </ExpansionPanelActions>
              </div>
            )}
          </ExpansionPanel>
        );
      })
    ) : (
      <Typography>No email to show</Typography>
    );
  return (
    <main className={classes.content}>
      <Menu
        id='long-menu'
        anchorEl={anchorElOpt}
        open={Boolean(anchorElOpt)}
        onClose={handleCloseOpt}
        className={classes.markMenu}
        PaperProps={{ style: { maxHeight: ITEM_HEIGHT * 4.5, width: 200 } }}
      >
        <List
          component='nav'
          subheader={<ListSubheader component='div'>Mark to... </ListSubheader>}
        />
        <MenuItem selected onClick={() => handleMoveTo(itemToMove, 'updates')}>
          <Flag className={classes.iconOrange} />
          &nbsp;Updates
        </MenuItem>
        <MenuItem onClick={() => handleMoveTo(itemToMove, 'social')}>
          <People className={classes.iconRed} />
          &nbsp;Social
        </MenuItem>
        <MenuItem onClick={() => handleMoveTo(itemToMove, 'promos')}>
          <LabelIcon className={classes.iconBlue} />
          &nbsp;Promos
        </MenuItem>
        <MenuItem onClick={() => handleMoveTo(itemToMove, 'forums')}>
          <QuestionAnswer className={classes.iconCyan} />
          &nbsp;Forums
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleMoveTo(itemToMove, 'spam')}>
          <ReportIcon />
          &nbsp;Spam
        </MenuItem>
      </Menu>
      {emailListState.processing ? (
        <LoaderWrapper>
          <CircularIndeterminate />
        </LoaderWrapper>
      ) : (
        getEmail(emailListData)
      )}
    </main>
  );
}

EmailList.propTypes = {
  classes: PropTypes.object.isRequired,
  emailData: PropTypes.object.isRequired,
  openMail: PropTypes.func.isRequired,
  moveTo: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  toggleStar: PropTypes.func.isRequired,
  reply: PropTypes.func.isRequired,
  filterPage: PropTypes.string.isRequired,
  keyword: PropTypes.string.isRequired,
};

export default withStyles(styles)(EmailList);
