exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/**\n * Retrieves a color value from the $material-colors Sass map\n * Inspired by https://github.com/nilskaspersson/Google-Material-UI-Color-Palette/\n *\n * @function\n * @param {string} color-name             - quoted, lower-case, dasherized color\n *                                          name (e.g. 'pink', 'amber')\n * @param {string} [color-variant='500']  - quoted, lowercase color variant (e.g.\n *                                          '200', 'a100')\n */\n.bgInfo__s5-ty div {\n  background: #64b5f6;\n}\n\n.bgSuccess__2lduF div {\n  background: #81c784;\n}\n\n.bgWarning__3XA-f div {\n  background: #ffb74d;\n}\n\n.bgError__3fMak div {\n  background: #e57373;\n}\n\n.bgDefault__TURBt div {\n  background: #616161;\n}\n", ""]);

// Exports
exports.locals = {
	"bgInfo": "bgInfo__s5-ty",
	"bgSuccess": "bgSuccess__2lduF",
	"bgWarning": "bgWarning__3XA-f",
	"bgError": "bgError__3fMak",
	"bgDefault": "bgDefault__TURBt"
};