import React, { Fragment, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import classNames from 'classnames';
import List from '@material-ui/core/List';
import Tooltip from '@material-ui/core/Tooltip';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import SearchIcon from '@material-ui/icons/Search';
import Add from '@material-ui/icons/Add';
import Chat from '@material-ui/icons/Chat';
import Forum from '@material-ui/icons/Forum';
import IconButton from '@material-ui/core/IconButton';
import styles from './contact-jss';
import SingleContact from './SingleContact';
import SinglePrivateContact from './SinglePrivateContact';

function ContactList(props) {
  const {
    classes,
    dataContact,
    itemSelected,
    showDetail,
    search,
    keyword,
    clippedRight,
    addContact,
    addFn,
    total,
    handleChatBoxChange,
    title,
  } = props;

  const handleChange = (event, value) => {
    handleChatBoxChange(value);
  };

  // const favoriteData = dataContact.filter(
  //   (item) => item.get('favorited') === true
  // );
  const getItem = (dataArray) =>
    dataArray.map((data, index) => {
      if (data.group.name.toLowerCase().indexOf(keyword) === -1) {
        return null;
      }
      return (
        <SingleContact
          data={data}
          index={index}
          classes={classes}
          showDetail={showDetail}
          keyword={keyword}
          itemSelected={itemSelected}
          key={index}
          title={title}
        />
      );
    });

  const getPrivateContact = (dataArray) =>
    dataArray.map((data, index) => (
      // if (data.group.name.toLowerCase().indexOf(keyword) === -1) {
      //   return null;
      // }
      <SinglePrivateContact
        data={data}
        index={index}
        classes={classes}
        showDetail={showDetail}
        keyword={keyword}
        itemSelected={itemSelected}
        key={index}
      />
    ));
  return (
    <Fragment>
      <Drawer
        variant='permanent'
        anchor='left'
        open
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div>
          <div
            className={classNames(
              classes.toolbar,
              clippedRight && classes.clippedRight
            )}
          >
            <div className={classes.flex}>
              <div className={classes.searchWrapper}>
                <div className={classes.search}>
                  <SearchIcon />
                </div>
                <input
                  className={classes.input}
                  onChange={(event) => search(event)}
                  placeholder='Search'
                />
              </div>
              {addFn && (
                <Tooltip title='Add New Contact'>
                  <IconButton
                    className={classes.buttonAdd}
                    onClick={() => addContact()}
                    color='secondary'
                    aria-label='Delete'
                  >
                    <Add />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
          <div className={classes.total}>
            {total}
            &nbsp; {title}
          </div>
          <List>
            {title === 'groups'
              ? getItem(dataContact)
              : getPrivateContact(dataContact)}
          </List>
        </div>
      </Drawer>
      <BottomNavigation
        value={title}
        onChange={handleChange}
        className={classes.bottomFilter}
        showLabels
      >
        <BottomNavigationAction
          label='Contacts'
          value='contacts'
          icon={<Chat />}
        />
        <BottomNavigationAction
          label='Groups'
          value='groups'
          icon={<Forum />}
        />
      </BottomNavigation>
    </Fragment>
  );
}

ContactList.propTypes = {
  classes: PropTypes.object.isRequired,
  total: PropTypes.number.isRequired,
  dataContact: PropTypes.object.isRequired,
  keyword: PropTypes.string.isRequired,
  itemSelected: PropTypes.number.isRequired,
  addContact: PropTypes.func,
  addFn: PropTypes.bool,
  showDetail: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  clippedRight: PropTypes.bool,
};

ContactList.defaultProps = {
  clippedRight: false,
  addContact: () => {},
  addFn: false,
};

export default withStyles(styles)(ContactList);
