import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Outer from '../Templates/Outer';
import { Login, Register, ResetPassword, NotFound } from '../pageListAsync';

function Holding() {
  return (
    <Outer>
      <Switch>
        <Route path='/*' component={Login} />
      </Switch>
    </Outer>
  );
}

export default Holding;
