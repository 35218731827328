import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowBack from '@material-ui/icons/ArrowBack';
import styles from '../Contact/contact-jss';

const optionsOpt = [];

const ITEM_HEIGHT = 48;

function ChatHeader(props) {
  const [anchorElOpt, setAnchorElOpt] = useState(null);

  const handleClickOpt = (event) => {
    setAnchorElOpt(event.currentTarget);
  };

  const handleCloseOpt = () => {
    setAnchorElOpt(null);
  };

  const handleRemove = (person) => {
    const { remove } = props;
    remove(person);
  };

  const getMembersNames = (dataContact, chatSelected, groupMembers = []) => {
    const namesToShow = groupMembers.filter((_, index) => index <= 4);
    const othersCount = groupMembers.length - namesToShow.length;
    let text =
      dataContact[chatSelected] &&
      dataContact[chatSelected].group &&
      groupMembers &&
      namesToShow.map((val) => val.groupMemberDetails.firstName).join(', ');
    if (!text) {
      return (
        dataContact[chatSelected] &&
        dataContact[chatSelected].channelUser &&
        `${dataContact[chatSelected].channelUser.firstName} ${
          dataContact[chatSelected].channelUser.lastName
        }`
      );
    }

    if (othersCount > 0) {
      text = `${text} and ${othersCount} others`;
    }
    return text;
  };

  const {
    classes,
    chatSelected,
    dataContact,
    showMobileDetail,
    hideDetail,
    groupMembers,
    loading,
  } = props;
  return (
    <AppBar
      position='relative'
      className={classNames(
        classes.appBar,
        classes.fixHeight,
        classes.appBarShift
      )}
    >
      <div className={classes.cover}>
        {showMobileDetail && (
          <IconButton
            aria-label='open drawer'
            onClick={() => hideDetail()}
            className={classes.navIconHide}
          >
            <ArrowBack />
          </IconButton>
        )}
        <Typography variant='h6' component='h2' color='inherit' noWrap>
          {(dataContact[chatSelected] &&
            dataContact[chatSelected].group &&
            dataContact[chatSelected].group.name) ||
            (dataContact[chatSelected] &&
              dataContact[chatSelected].channelUser &&
              `${dataContact[chatSelected].channelUser.firstName} ${
                dataContact[chatSelected].channelUser.lastName
              }`)}
          {!loading && (
            <Typography
              variant='caption'
              display='block'
              className={classes.status}
              color='inherit'
              noWrap
            >
              {getMembersNames(dataContact, chatSelected, groupMembers)}
            </Typography>
          )}
        </Typography>
        <IconButton
          aria-label='More'
          aria-owns={anchorElOpt ? 'long-menu' : null}
          aria-haspopup='true'
          className={classes.button}
          onClick={handleClickOpt}
        >
          <MoreVertIcon color='inherit' />
        </IconButton>
        <Menu
          id='long-menu'
          anchorEl={anchorElOpt}
          open={Boolean(anchorElOpt)}
          onClose={handleCloseOpt}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: 200,
            },
          }}
        >
          {optionsOpt.map((option) => {
            if (option === 'Delete Conversation') {
              return (
                <MenuItem key={option} onClick={handleRemove}>
                  {option}
                </MenuItem>
              );
            }
            return (
              <MenuItem
                key={option}
                selected={option === 'Edit Profile'}
                onClick={handleCloseOpt}
              >
                {option}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    </AppBar>
  );
}

export default withStyles(styles)(ChatHeader);
