import { fromJS } from 'immutable';
import { commonState } from '../constants';
import { extractStatus, handleFetch } from '../../utils/reducerHelper';
import {
  CLEAR_EMAIL_MESSAGES,
  FETCH_EMAILS_ERROR,
  FETCH_EMAILS_REQUEST,
  FETCH_EMAILS_SUCCESS,
  SEND_EMAIL_ERROR,
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
} from '../types';

const initialState = fromJS({
  sendEmail: {
    ...commonState,
    data: {},
  },
  emailList: {
    ...commonState,
    data: {},
  },
  recipients: [],
});

const emailReducer2 = (state = initialState, action) => {
  const status = extractStatus(action.type);
  switch (action.type) {
    case SEND_EMAIL_REQUEST:
    case SEND_EMAIL_SUCCESS:
    case SEND_EMAIL_ERROR:
      return fromJS(
        handleFetch(state.toJS(), status, action.payload, 'sendEmail')
      );
    case FETCH_EMAILS_REQUEST:
    case FETCH_EMAILS_SUCCESS:
    case FETCH_EMAILS_ERROR:
      return fromJS(
        handleFetch(state.toJS(), status, action.payload, 'emailList')
      );
    case CLEAR_EMAIL_MESSAGES: {
      const { key } = action.payload;
      const stateObj = state.toJS();
      let message = stateObj[key].data.message;
      message = message ? '' : undefined;

      if (key) {
        return fromJS({
          ...stateObj,
          [key]: {
            ...stateObj[key],
            message: '',
            errors: {},
            data: {
              ...stateObj[key].data,
              message,
            },
          },
        });
      }
      return state;
    }
    default:
      return state;
  }
};

export default emailReducer2;
