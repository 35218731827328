import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import CookieConsent from 'react-cookie-consent';
import styles from './appStyles-jss';

class Outer extends React.Component {
  render() {
    const { classes, children, gradient, decoration } = this.props;
    return (
      <div
        className={classNames(
          classes.appFrameOuter,
          gradient ? classes.gradientBg : classes.solidBg
        )}
      >
        <main className={classes.outerContent} id='mainContent'>
          {decoration && <div className={classes.petal} />}
          {children}
        </main>
        <CookieConsent>
          By continuing to use this website, you agree Conectivest can store
          cookies on your device and disclose information in accordance with our
          Cookie Policy.
        </CookieConsent>
      </div>
    );
  }
}

Outer.propTypes = {
  classes: PropTypes.object.isRequired,
  gradient: PropTypes.bool.isRequired,
  decoration: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const reducer = 'ui';
const mapStateToProps = (state) => ({
  gradient: state.getIn([reducer, 'gradient']),
  decoration: state.getIn([reducer, 'decoration']),
  ...state,
});

const OuterMaped = connect(mapStateToProps)(Outer);

export default withStyles(styles)(OuterMaped);
