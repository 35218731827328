import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Link, Route } from 'react-router-dom';
import styles from './breadCrumb-jss';
import { truncateString } from '../../utils/helpers';

const Breadcrumbs = (props) => {
  const { classes, theme, separator, location } = props;
  return (
    <section
      className={classNames(
        theme === 'dark' ? classes.dark : classes.light,
        classes.breadcrumbs
      )}
    >
      <Route
        path='*'
        render={() => {
          let parts = location.pathname.split('/');
          const pages = [parts[1], parts[2]].filter(
            (path) => path.toLowerCase() !== 'pages'
          );
          const place = parts[parts.length - 1];
          parts = [...pages];
          return (
            <p>
              You are here:
              <span>
                {pages.map((part, partIndex) => {
                  const path = [...parts.slice(0, partIndex + 1)].join('/');
                  return (
                    <Fragment key={path}>
                      <Link to={`/${path}`}>{truncateString(part, 10)}</Link>
                      {separator}
                    </Fragment>
                  );
                })}
                &nbsp;
                {truncateString(place, 10)}
              </span>
            </p>
          );
        }}
      />
    </section>
  );
};

Breadcrumbs.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
  separator: PropTypes.string.isRequired,
};

export default withStyles(styles)(Breadcrumbs);
