import { call, put, takeLatest } from 'redux-saga/effects';
import safeSaga from '../../utils/safeSaga';

import http from '../api';
import { emailRequests } from '../api/requests';
import {
  FETCH_EMAILS_ERROR,
  FETCH_EMAILS_REQUEST,
  FETCH_EMAILS_SUCCESS,
  SEND_EMAIL_ERROR,
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
} from '../types';

function* sendEmail({ payload, onSuccess, onError }) {
  try {
    const { data } = yield call([http, 'post'], emailRequests.EMAIL, payload);

    yield put({
      type: SEND_EMAIL_SUCCESS,
      payload: data,
    });
    if (onSuccess) onSuccess(data);
  } catch (error) {
    if (onError) onError(error);
  }
}

function* fetchEmails({ payload: { type } }) {
  const { data } = yield call(
    [http, 'get'],
    `${emailRequests.EMAIL}?type=${type}`
  );
  yield put({
    type: FETCH_EMAILS_SUCCESS,
    payload: data,
  });
}

export default function* emailSaga() {
  yield takeLatest(SEND_EMAIL_REQUEST, safeSaga(sendEmail, SEND_EMAIL_ERROR));
  yield takeLatest(
    FETCH_EMAILS_REQUEST,
    safeSaga(fetchEmails, FETCH_EMAILS_ERROR)
  );
}
