/* eslint-disable indent */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ChevronRight } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import { usePlaidLink } from 'react-plaid-link';
import { Alert } from '@material-ui/lab';
import { useMediaQuery, useTheme } from '@material-ui/core';
import link from 'dan-api/ui/link';
import styles from './widget-jss';
import { UserContext } from '../../redux/context';
import {
  useExchangeRate,
  usePlaidCharges,
  useStripeCharges,
} from '../../utils/components/hooks';
import http from '../../redux/api';
import { PAYMENT_REQUESTS } from '../../redux/api/requests';
import { extractErrorMessage } from '../../redux/api/helpers';
import { ConfirmDialog } from '../../utils/components/ConfirmDialog';
import { currencyFormatter } from '../../utils/helpers';
import { PayWithStripe, StripeParent } from '../../utils/components/StripePay';

function TabContainer({ children, dir }) {
  return (
    <Typography component='div' dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const usePaymentOptionStyles = makeStyles((theme) => ({
  paymentButton: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    borderRadius: 8,
    width: '100%',
    color: 'white',
    padding: 4,
    background: theme.palette.secondary.main,
    '&:hover': {
      color: 'white',
      background: theme.palette.secondary.dark,
    },
    '&:disabled': {
      color: 'white',
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  subTitle: {
    textTransform: 'initial',
    fontSize: '14px',
    [theme.breakpoints.down(400)]: {
      width: '280px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  title: {
    [theme.breakpoints.down(400)]: {
      width: '280px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  stripeContainer: {
    display: 'flex',
    padding: '0 10px',
    flexDirection: 'column',
    width: '100%',
  },
}));

function PaymentOption({ paymentOption, busy }) {
  const { provider, description, onClick } = paymentOption;
  const {
    paymentButton,
    textContainer,
    subTitle,
    title,
  } = usePaymentOptionStyles();

  return (
    <Button
      endIcon={<ChevronRight />}
      disabled={busy}
      className={paymentButton}
      onClick={onClick}
      title={description}
    >
      <div className={textContainer}>
        <span className={title}>{provider}</span>
        <span className={subTitle}>{description}</span>
      </div>
    </Button>
  );
}

PaymentOption.propTypes = {
  paymentOption: PropTypes.object.isRequired,
  busy: PropTypes.bool.isRequired,
};

function TradingFormWidget(props) {
  const [value, setValue] = useState(0);
  const [amount, setAmount] = useState(1);
  const [token, setToken] = useState(null);
  const [openStripeCard, setOpenStripeCard] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [processingPayment, setProcessingPayment] = useState(false);
  const stripeCharges = useStripeCharges(amount);
  const plaidCharges = usePlaidCharges(amount);
  const [serverError, setServerError] = useState('');
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accounts, setAccounts] = useState(null);
  useQuery(
    'fetchAccounts',
    () => http.get(PAYMENT_REQUESTS.USER_PAYMENT_DETAILS),
    {
      refetchOnWindowFocus: true,
      onSuccess({ data }) {
        if (data.data && data.data.stripePlaidTokenDetails) {
          const accountsIdsAvailableForACH = data.data.stripePlaidTokenDetails.map(
            ({ account_id: accId }) => accId
          );
          const accountsAvailableForACH = data.data.plaidAccountDetails.accounts.filter(
            ({ account_id: id }) => accountsIdsAvailableForACH.includes(id)
          );
          setAccounts(accountsAvailableForACH);
        } else {
          setAccounts([]);
        }
      },
      onError(e) {
        const err = extractErrorMessage(e);
        setServerError(err);
      },
    }
  );
  const rate = useExchangeRate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(992));
  const { state: profile } = useContext(UserContext);
  const isDone = useRef(false);
  const { stripeContainer } = usePaymentOptionStyles();
  const {
    payWithMono,
    payWithFlw,
    busy,
    handleError,
    handleSuccessfulPayment,
    hideParent,
    showParent,
  } = props;
  const localPaymentOptions = [
    {
      provider: 'Mono',
      description: 'For local direct debits.',
      onClick: () => payWithMono((+amount + 45) * 100),
    },
    {
      provider: 'Flutterwave',
      description: 'For card payments',
      onClick: () => {
        const stampDuty = +amount >= 10000 ? 50 : 0;
        const toPay = +amount + stampDuty;
        payWithFlw(toPay, 'NGN', profile);
      },
    },
  ];

  // eslint-disable-next-line no-unused-vars
  const sendPublicToken = async (publicToken) => {
    try {
      await http.post(PAYMENT_REQUESTS.EXCHANGE_TOKEN_FOR_VALUE, {
        publicToken,
      });
    } catch (err) {
      const e = extractErrorMessage(err);
      setServerError(e);
    }
  };

  const { open, error, ready } = usePlaidLink({
    token,
    onSuccess: sendPublicToken,
    onExit: showParent,
  });

  const handleClickPlaid = useCallback(() => {
    open();
  }, [open]);

  const plaidObject = {
    provider: 'Connect account with Plaid',
    description: 'For ACH transfers and direct debits',
    onClick: handleClickPlaid,
    disabled: !ready,
  };

  const [
    internationalPaymentOptions,
    setInternationalPaymentOptions,
  ] = useState([
    {
      provider: 'Stripe',
      description: 'For international card payments',
      onClick: () => setOpenStripeCard(true),
    },
  ]);

  const containsDecimals = () => !!(amount % 1);

  const isInvalid = () => {
    const isValidUSD = Number(amount) >= 1;
    const isValidNGN = Number(amount) >= rate;
    const isValidAmount = () => (value === 0 ? !isValidNGN : !isValidUSD);
    return containsDecimals() || !Number(amount) || isValidAmount();
  };

  const handleChangeTab = (event, val) => {
    setValue(val);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleChangeAmount = (event) => {
    setAmount(event.target.value);
  };

  const chargePlaidAccount = async () => {
    try {
      setProcessingPayment(true);
      await http.post(PAYMENT_REQUESTS.INITIALIZE_PLAID_PAYMENT, {
        amount: plaidCharges.amountToBePayed,
        accountId: selectedAccount.account_id,
        collectedFee: plaidCharges.collectedFee,
      });
      setProcessingPayment(false);
      handleSuccessfulPayment(
        'Transfer initiated. We will inform you when the transaction is completed.'
      );
    } catch (err) {
      const e = extractErrorMessage(err);
      setServerError(e);
      setProcessingPayment(false);
    }
  };

  useEffect(() => {
    if (accounts && accounts.length && !isDone.current) {
      setInternationalPaymentOptions((val) => {
        const withoutPlaid = val.filter(
          ({ provider }) => provider !== 'Connect account with Plaid'
        );
        return [
          ...withoutPlaid,
          ...accounts.map((acc) => ({
            provider: acc.official_name,
            description: `Debit from ${acc.official_name}`,
            onClick: () => setSelectedAccount(acc),
          })),
        ];
      });
      isDone.current = true;
    }
  }, [accounts]);

  useEffect(() => {
    const initiatePlaid = async () => {
      try {
        const { data } = await http.get(PAYMENT_REQUESTS.GET_PLAID_TOKEN);
        if (data.data) {
          setToken(data.data);
        }
      } catch (err) {
        const e = extractErrorMessage(err);
        handleError(e);
      }
    };

    if (accounts && !accounts.length && !token) {
      initiatePlaid();
    }
  }, [accounts, token]);

  useEffect(() => {
    if (error && error.message) {
      handleError(error.message);
    }
  }, [error]);

  useEffect(() => {
    setOpenConfirmDialog(!!selectedAccount);
  }, [selectedAccount]);

  const { classes } = props;
  return (
    <div>
      <ConfirmDialog
        open={openConfirmDialog}
        title='Confirm Deposit'
        onCancel={() => setSelectedAccount(null)}
        onClose={() => setSelectedAccount(null)}
        onConfirm={chargePlaidAccount}
        subtitle={`You will be charged ${currencyFormatter({
          value: amount,
          currency: 'USD',
        })} and ${currencyFormatter({
          value: Number(plaidCharges.collectedFee) / 100,
          currency: 'USD',
        })} charges from the payment processor. This process is irreversible.`}
      />
      <AppBar position='static' color='default'>
        <Tabs
          value={value}
          onChange={handleChangeTab}
          indicatorColor='primary'
          textColor='primary'
          variant='fullWidth'
        >
          <Tab label='Naira' />
          <Tab label='USD' />
        </Tabs>
      </AppBar>
      <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
        <TabContainer dir='ltr'>
          <div className={classes.tabContainer}>
            {serverError ? (
              <Alert style={{ marginTop: 5, marginBottom: 5 }} severity='error'>
                {serverError}
              </Alert>
            ) : (
              <Alert
                style={{ marginTop: 5, marginBottom: 5 }}
                severity='success'
              >
                Your personal wallet will be credited with{' '}
                <strong>
                  {currencyFormatter({
                    value: amount / rate,
                    currency: 'USD',
                  })}
                </strong>{' '}
                at the rate of{' '}
                <strong>
                  {currencyFormatter({
                    value: 1,
                    currency: 'USD',
                  })}
                </strong>{' '}
                to{' '}
                <strong>
                  {currencyFormatter({
                    value: rate,
                    currency: 'NGN',
                  })}
                </strong>{' '}
                . For transactions above{' '}
                <strong>
                  {currencyFormatter({
                    value: 10000,
                    currency: 'NGN',
                  })}
                </strong>{' '}
                , a stamp duty of{' '}
                <strong>
                  {currencyFormatter({
                    value: 50,
                    currency: 'NGN',
                  })}
                </strong>{' '}
                applies if Flutterwave is used. Minimum transaction amount is{' '}
                <strong>
                  {currencyFormatter({
                    value: rate,
                    currency: 'NGN',
                  })}
                </strong>
              </Alert>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl
                  style={{ width: '100%' }}
                  fullWidth
                  className={classes.formControlTrade}
                  required
                >
                  <InputLabel htmlFor='adornment-amount4'>Amount</InputLabel>
                  <Input
                    error={containsDecimals()}
                    id='adornment-amount4'
                    onFocus={() => setServerError('')}
                    fullWidth
                    type='number'
                    value={amount}
                    inputProps={{ min: rate }}
                    onChange={handleChangeAmount}
                    startAdornment={
                      <InputAdornment position='start'>&#8358;</InputAdornment>
                    }
                  />
                  <FormHelperText>
                    {containsDecimals()
                      ? 'Decimals not allowed'
                      : `${currencyFormatter({
                          value: amount,
                          currency: 'NGN',
                        })}`}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <div>
              <Grid container spacing={2}>
                {localPaymentOptions.map((option) => (
                  <Grid key={Math.random()} item xs={12}>
                    <PaymentOption
                      busy={busy || processingPayment || isInvalid()}
                      paymentOption={option}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </TabContainer>
        <TabContainer dir='ltr'>
          <div className={classes.tabContainer}>
            {serverError ? (
              <Alert style={{ marginTop: 5, marginBottom: 5 }} severity='error'>
                {serverError}
              </Alert>
            ) : (
              <Alert
                style={{ marginTop: 5, marginBottom: 5, lineHeight: 2.5 }}
                severity='success'
              >
                Your personal wallet will be credited with{' '}
                <strong>
                  {currencyFormatter({
                    value: amount,
                    currency: 'USD',
                  })}
                </strong>
                .
                <p>
                  Using Stripe card attracts a transaction fee of{' '}
                  <strong>
                    {currencyFormatter({
                      value: Number(stripeCharges.collectedFee) / 100,
                      currency: 'USD',
                    })}
                    .
                  </strong>
                  <br />
                  Using ACH transfers through Plaid attracts a transaction fee
                  of{' '}
                  <strong>
                    {currencyFormatter({
                      value: Number(plaidCharges.collectedFee) / 100,
                      currency: 'USD',
                    })}
                  </strong>
                  . ACH transfers take 5 - 9 days to complete. Minimum
                  transaction amount is{' '}
                  <strong>
                    {currencyFormatter({
                      value: 1,
                      currency: 'USD',
                    })}
                  </strong>
                  .
                  <br />
                  {accounts && accounts.length ? (
                    <></>
                  ) : !isMobile ? (
                    <p>
                      To use ACH transfers please click{' '}
                      <NavLink
                        target='_blank'
                        to={`${link.settings}?closeTab=true`}
                      >
                        here
                      </NavLink>{' '}
                      to integrate with Plaid.
                    </p>
                  ) : (
                    <>
                      Minimum transaction amount is{' '}
                      <strong>
                        {currencyFormatter({
                          value: 1,
                          currency: 'USD',
                        })}
                      </strong>
                    </>
                  )}
                </p>
                <p />
              </Alert>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl
                  required
                  fullWidth
                  style={{ width: '100%' }}
                  className={classes.formControlTrade}
                >
                  <InputLabel htmlFor='adornment-amount5'>Amount</InputLabel>
                  <Input
                    id='adornment-amount5'
                    value={amount}
                    type='number'
                    onChange={handleChangeAmount}
                    error={containsDecimals()}
                    fullWidth
                    onFocus={() => setServerError('')}
                    startAdornment={
                      <InputAdornment position='start'>$</InputAdornment>
                    }
                  />
                  <FormHelperText>
                    {containsDecimals()
                      ? 'Decimals not allowed'
                      : currencyFormatter({ value: amount, currency: 'USD' })}
                  </FormHelperText>
                </FormControl>
              </Grid>
              {openStripeCard ? (
                <div className={stripeContainer}>
                  <StripeParent>
                    <PayWithStripe
                      open={openStripeCard}
                      handleClose={() => setOpenStripeCard(false)}
                      onError={setServerError}
                      onSuccess={handleSuccessfulPayment}
                      amount={amount}
                    />
                  </StripeParent>
                </div>
              ) : null}
            </Grid>
            <Divider className={classes.divider} />
            {!openStripeCard ? (
              <div>
                <Grid container spacing={2}>
                  {internationalPaymentOptions.map((option) => (
                    <Grid key={Math.random()} item xs={12}>
                      <PaymentOption
                        busy={
                          busy ||
                          option.disabled ||
                          processingPayment ||
                          isInvalid()
                        }
                        paymentOption={option}
                      />
                    </Grid>
                  ))}
                  {accounts && !accounts.length && isMobile ? (
                    <Grid key={Math.random()} item xs={12}>
                      <PaymentOption
                        busy={
                          busy ||
                          plaidObject.disabled ||
                          processingPayment ||
                          isInvalid()
                        }
                        paymentOption={plaidObject}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </div>
            ) : null}
          </div>
        </TabContainer>
      </SwipeableViews>
    </div>
  );
}

TradingFormWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  payWithMono: PropTypes.func.isRequired,
  payWithFlw: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  handleSuccessfulPayment: PropTypes.func.isRequired,
  hideParent: PropTypes.func.isRequired,
  showParent: PropTypes.func.isRequired,
  busy: PropTypes.bool.isRequired,
};

const Widget = React.memo(TradingFormWidget);

export default withStyles(styles)(Widget);
