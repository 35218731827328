import { useEffect, useState, useRef, useContext } from 'react';
import axios from 'axios';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { useQuery } from 'react-query';
import http from '../../redux/api';
import {
  networkRequests,
  PAYMENT_REQUESTS,
  userRequests,
} from '../../redux/api/requests';
import { extractNetwork } from '../helpers';
import { UserContext } from '../../redux/context';
import {
  CARD_PAYMENT_ALIASES,
  NIGERIAN_PAYMENT_METHODS,
  PERMISSIONS,
} from '../constants';
import { extractErrorMessage } from '../../redux/api/helpers';

export function useProfile(reload) {
  const [profile, setProfile] = useState({
    id: '',
    firstName: '',
    lastName: '',
    status: '',
    email: '',
    customEmail: '',
    phoneNumber: '',
    lastActive: '',
    type: '',
    showField: false,
    createdAt: '',
    updatedAt: '',
    deletedAt: null,
    networkMembership: [],
    userWallet: {
      userId: '',
      walletDetails: {
        totalBalance: '',
        availableBalance: '',
        id: '',
      },
    },
  });
  useEffect(() => {
    (async () => {
      try {
        const { data } = await http.get(userRequests.PROFILE);
        setProfile(data.data);
      } catch (error) {
        // TODO
      }
    })();
  }, [reload]);
  return profile;
}

export function useNetwork() {
  const profile = useProfile();
  const [network, setNetwork] = useState({
    networkId: '',
    isAdmin: false,
    isSuperAdmin: false,
    network: {
      id: '',
      name: '',
      allowMembersShowInterests: false,
    },
  });

  useEffect(() => {
    const result = extractNetwork(profile);
    if (result) setNetwork(result);
  }, [profile]);

  return network;
}

export const useDeviceInfo = () => {
  const [deviceInfo, setDeviceInfo] = useState({
    fl: '',
    h: '',
    ip: '',
    ts: '',
    visit_scheme: '',
    uag: '',
    colo: '',
    http: '',
    loc: 'NG',
    tls: '',
    sni: '',
    warp: '',
    gateway: '',
  });

  const getDeviceInfo = async () => {
    try {
      const res = await axios.get('https://www.cloudflare.com/cdn-cgi/trace');
      const data = res.data
        .trim()
        .split('\n')
        .reduce((obj, pair) => {
          const nPair = pair.split('=');
          const clone = { ...obj };
          clone[nPair[0]] = nPair[1];
          return clone;
        }, {});
      return setDeviceInfo(data);
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    getDeviceInfo();
  }, []);

  return deviceInfo;
};

export function useExchangeRate(currency = 'NGN') {
  const [rate, setRate] = useState(1);
  const [error, setError] = useState('');

  const clearError = () => setError('');

  useEffect(() => {
    const getRate = async () => {
      try {
        clearError();
        const { data } = await http.get(
          PAYMENT_REQUESTS.getExchangeRate(currency)
        );
        if (data && Array.isArray(data.data) && data.data.length) {
          setRate(data.data[0].rate);
        } else {
          setRate(1);
        }
      } catch (error) {
        setError(extractErrorMessage(error));
      }
    };
    getRate();
  }, [currency]);

  return { rate, error, clearError };
}

/**
 * Hook that nadles clicks outside of the passed ref
 */
export function useOutsideClick(ref, handleOutsideClick) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handleOutsideClick();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

export function useWallet(reload) {
  const profile = useProfile(reload);
  const [userWallet, setUserWallet] = useState({
    userId: '',
    walletDetails: {
      totalBalance: '0',
      availableBalance: '0',
      id: '',
    },
  });

  useEffect(() => {
    setUserWallet({
      ...profile.userWallet,
      walletDetails: {
        ...profile.userWallet.walletDetails,
        availableBalance:
          profile.userWallet.walletDetails.availableBalance / 100, // value is in cents
        totalBalance: profile.userWallet.walletDetails.totalBalance / 100, // value is in cents
      },
    });
  }, [profile, reload]);

  return userWallet;
}

export function useNetworkWallet(reload) {
  const [wallet, setWallet] = useState({
    networkId: '',
    walletDetails: {
      totalBalance: '0',
      availableBalance: '0',
      id: '',
      status: '',
    },
  });

  const network = useNetwork();

  useEffect(() => {
    const { networkId } = network;
    const getNetworkBalance = async () => {
      try {
        const { data } = await http.get(
          networkRequests.getNetworkWalletBalance(networkId)
        );
        setWallet({
          ...data.data,
          walletDetails: {
            ...data.data.walletDetails,
            availableBalance: data.data.walletDetails.availableBalance / 100, // value is in cents
            totalBalance: data.data.walletDetails.totalBalance / 100, // value is in cents
          },
        });
      } catch (error) {
        // TODO
      }
    };
    if (networkId) {
      getNetworkBalance();
    }
  }, [network, reload]);
  return wallet;
}

export function useMobile() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(600));
}

export function usePermissions(permission) {
  const {
    state: { currentSubscription },
  } = useContext(UserContext);
  const [members, setMembers] = useState([]);
  const [granted, setGranted] = useState(false);
  const [deals, setDeals] = useState([]);
  const dataFetched = useRef(false);
  const profile = useProfile();
  const network = useNetwork();

  const { CAN_ADD_TWENTY_PLUS_MEMBERS, CAN_ADD_FIVE_PLUS_DEALS } = PERMISSIONS;

  const isOnProfessionalPlan = () => {
    const sub = currentSubscription.find(
      ({ subType }) => subType === 'deal-manager'
    );
    if (!sub) return false;
    return (
      sub.plan.name.toLowerCase() === 'professional' ||
      sub.plan.name.toLowerCase() === 'business'
    );
  };

  useEffect(() => {
    const { networkId, isSuperAdmin } = network;
    const getMembers = async () => {
      dataFetched.current = true;
      try {
        const { data } = await http.get(
          networkRequests.FETCH_MEMBERS(networkId)
        );
        if (data.data) {
          setMembers(data.data);
        }

        if (isSuperAdmin) {
          const { data: res } = await http.get(
            networkRequests.GET_ALL_DEALS(networkId)
          );
          if (res.data) {
            setDeals(res.data);
          }
        }
      } catch (error) {
        dataFetched.current = true;
      }
    };
    if (!dataFetched.current && networkId) {
      getMembers();
    }
  }, [profile, network]);

  useEffect(() => {
    switch (permission) {
      case CAN_ADD_TWENTY_PLUS_MEMBERS: {
        setGranted(isOnProfessionalPlan() || members.length < 20);
        break;
      }
      case CAN_ADD_FIVE_PLUS_DEALS: {
        setGranted(isOnProfessionalPlan() || deals.length < 5);
        break;
      }
      default:
        break;
    }
  }, [permission, members, currentSubscription, deals]);

  return granted;
}

export function useSearchParams(key) {
  const [result, setResult] = useState('');
  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const code = params.get(key);
    setResult(code || '');
  }, [key]);
  return result;
}

export function useStripeCharges(fee, paymentMethod, currency = 'USD') {
  const [charges, setCharges] = useState({
    amountToBePayed: '0',
    percentCharged: '0',
    collectedFee: '',
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { FLW, MONO, PAYSTACK } = NIGERIAN_PAYMENT_METHODS;

  useEffect(() => {
    setError('');
    const getCharges = async () => {
      try {
        const provider =
          paymentMethod === CARD_PAYMENT_ALIASES.APPLE_PAY ||
          paymentMethod === CARD_PAYMENT_ALIASES.GOOGLE_PAY
            ? 'card'
            : paymentMethod;
        setLoading(true);
        const { data } = await http.post(
          PAYMENT_REQUESTS.getInvestmentCharges(provider),
          { amount: fee * 100, currency }
        );
        setCharges(data.data);
        setLoading(false);
      } catch (error) {
        const err = extractErrorMessage(error);
        console.log('err', error);
        setError(err);
        setLoading(false);
      }
    };
    if (
      paymentMethod !== FLW &&
      paymentMethod !== PAYSTACK &&
      paymentMethod !== MONO &&
      paymentMethod
    ) {
      getCharges();
    }
  }, [fee, paymentMethod, currency]);

  return {
    charges,
    error,
    clearError: () => setError(''),
    processing: loading,
  };
}
export function usePlaidCharges(fee) {
  const [charges, setCharges] = useState({
    amountToBePayed: '0',
    percentCharged: '0',
    collectedFee: '',
  });
  useEffect(() => {
    const getCharges = async () => {
      try {
        const { data } = await http.post(
          PAYMENT_REQUESTS.getInvestmentCharges('plaid'),
          {
            amount: fee * 100,
          }
        );
        setCharges(data.data);
      } catch (error) {
        // TODO
      }
    };
    if (fee) {
      getCharges();
    }
  }, [fee]);

  return charges;
}

export function useProfileFormData() {
  const [data, setData] = useState({
    annualInvestmentAmounts: [],
    sectors: [],
    startUpsInPortforlio: [],
  });

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await http.get(userRequests.FORM_DATA);
        if (data) {
          setData(data);
        }
      } catch (error) {
        // TODO
      }
    };

    getData();
  }, []);

  return data;
}

export function useSyndicate(syndicateId, reload) {
  const [syndicate, setSyndicate] = useState({
    syndicateServices: {
      dueDiligence: false,
      investmentVehicleSetup: false,
      reviewOfTransactionDocuments: true,
      draftingofTransactionDocuments: false,
      otherServices: {
        fullCacSearch: false,
        byeLawReview: false,
        intellectualPropertyAudit: false,
        corporateAudit: false,
        employmentAgreementAndPractices: false,
        reviewExistingAgreement: false,
        sectorialCompliance: false,
      },
    },
    id: '',
    name: '',
    dealId: '',
    overview: '',
    amountRaised: '',
    minimumAllowedInvestment: '',
    amountToBeRaised: '',
    formationType: '',
    country: '',
    formationCategory: '',
    type: '',
    status: '',
    allowNonMembersShowInterests: false,
    link: '',
    commissionConfig: {
      value: '',
      type: '',
    },
    syndicateFiles: [
      {
        fileName: '75465569_NjIwLTYyMC1hODgyYTZhOGUy.webp',
        attachment:
          'https://conectivest-erp.s3.eu-west-2.amazonaws.com/others/35c2ce52-c5ed-4c70-9297-aef1b0bc6b47-75465569_NjIwLTYyMC1hODgyYTZhOGUy.webp',
      },
    ],
    paymentStatus: '',
    syndicateDeal: {
      id: '',
      startUpName: '',
      overview: '',
      sentBy: '',
      companyId: '',
      createdBy: '',
      dealStatus: '',
      viewByAll: false,
      networkId: null,
      amountToBeRaised: '',
      amountRaised: '0',
      minimumAllowedInvestment: '',
      acceptPaymentOnConectivest: false,
      deletedAt: null,
      createdAt: '',
      updatedAt: '',
      dealfiles: [],
    },
    syndicateReminder: {
      id: '',
      syndicateId: '',
      reminderDate: '',
      alertInHours: 0,
      deletedAt: null,
      createdAt: '',
    },
  });
  const [fetching, setFetching] = useState(true);
  const [isSyndicateLead, setIsSyndicateLead] = useState(false);
  const [isSyndicateAdmin, setIsSyndicateAdmin] = useState(false);
  const [isLocked, setIsLocked] = useState(true);
  const [isClosed, setIsClosed] = useState(true);
  const [isMember, setIsMember] = useState(false);
  const [members, setMembers] = useState([]);
  const [error, setError] = useState('');
  const { id: userId } = useProfile();

  const fetchData = async () => {
    try {
      setFetching(true);
      setError('');

      const { data: response } = await http.get(
        networkRequests.GET_SYNDICATE(syndicateId)
      );
      if (response.data.syndicate) {
        setSyndicate(response.data.syndicate);
        setIsLocked(
          response.data.syndicate.status.toLowerCase() ===
            'LOCKED'.toLowerCase()
        );
        setIsClosed(
          response.data.syndicate.status.toLowerCase() ===
            'CLOSED'.toLowerCase()
        );
        const { data } = await http.get(
          networkRequests.GET_SYNDICATE_MEMBERS(syndicateId)
        );

        if (data.data) {
          const members = data.data.map(({ userId }) => userId);
          const admins = data.data
            .filter(({ isAdmin }) => !!isAdmin)
            .map(({ userId }) => userId);
          const leads = data.data
            .filter(({ isSyndicateLead }) => !!isSyndicateLead)
            .map(({ userId }) => userId);
          const isAdmin = admins.includes(userId);
          const isLead = leads.includes(userId);
          const isMember = members.includes(userId);
          setIsSyndicateAdmin(isAdmin);
          setIsSyndicateLead(isLead);
          setIsMember(isMember);
          setMembers(
            data.data.map(
              ({
                syndicateMember,
                isAdmin,
                userId,
                commitmentAmount,
                currency,
                isSyndicateLead,
              }) => ({
                isAdmin,
                commitment: {
                  currency: currency || 'USD',
                  commitmentAmount,
                },
                commitmentAmount,
                userId,
                isSyndicateLead,
                ...syndicateMember,
              })
            )
          );
        }
        return setFetching(false);
      }
      setSyndicate(response.data.requestBy);
      setFetching(false);
    } catch (err) {
      const message = extractErrorMessage(err);
      setError(message);
      setFetching(false);
    }
  };

  useEffect(() => {
    if (userId && syndicateId) {
      fetchData();
    }
  }, [syndicateId, userId, reload]);

  return {
    fetching,
    isSyndicateAdmin,
    isSyndicateLead,
    syndicate,
    isClosed,
    isLocked,
    isMember,
    error,
    members,
  };
}

export function useKYCStatus() {
  const {
    state: { verified },
  } = useContext(UserContext);
  function getKycStatus() {
    return http.get('/show-field');
  }

  const { data } = useQuery('kycStatusQuery', getKycStatus, {
    refetchOnMount: 'always',
  });

  if (data && data.data) {
    return data.data.showField;
  }

  return verified;
}
