import React from 'react';
import { Box, Typography } from "@material-ui/core";
import { FORM_STATUS } from "../../constants";

const FormMessage = ({ showFormMessages, formMessage, classes, ...rest }) => {
  return (
    <Box
      id='msg'
      {...rest}
    >
      {(() => {
        if (!showFormMessages) return '';
          const styles = {
            marginTop: "0px",
            fontSize: ".8rem",
            fontStyle: "normal",
            marginBottom: "1rem",
          };

          const { type, message } = formMessage;
          if (!type) return "";
          return (
            <Typography
              className={type === FORM_STATUS.SUCCESS ? classes.success : classes.error}
              style={styles}
            >
              {message}
            </Typography>
          );
        })()}
    </Box>
  );
};

export default FormMessage;
