import { fromJS } from 'immutable';
import { commonState } from '../constants';
import { extractStatus, handleFetch } from '../../utils/reducerHelper';
import { SIGN_IN_REQUEST, SIGN_IN_SUCCESS, SIGN_IN_ERROR } from '../types';

const initalState = fromJS({
  ...commonState,
  data: {},
  open: false,
});

const authReducer = (state = initalState, action) => {
  const status = extractStatus(action.type);
  switch (action.type) {
    case SIGN_IN_REQUEST:
       return fromJS(handleFetch(state.toJS(), status, action.payload));
    case SIGN_IN_SUCCESS:
       return fromJS(handleFetch(state.toJS(), status, action.payload));
    case SIGN_IN_ERROR:
      return fromJS(handleFetch(state.toJS(), 'ERROR', action.payload));
    default:
      return state;
  }
};

export default authReducer;
