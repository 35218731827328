import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Typography,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';
import { CloseDialogButton } from './CloseModalButton';

const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
  },
}));
export function ConfirmDialog({
  open,
  onClose,
  onConfirm,
  onCancel,
  children,
  title = 'Confirm action?',
  subtitle = 'Do you want to confirm this action. This process may be irreversible.',
  disableConfirm = false,
  retry,
  loading,
}) {
  const classes = useStyles();
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <CloseDialogButton onClick={onClose} />
      <DialogContent>
        <div>
          {children ? (
            <Alert
              severity={loading ? 'info' : 'error'}
              style={{ marginBottom: 20, lineHeight: 2.4 }}
            >
              {loading
                ? 'Please wait a moment while we resend the verification code to your email.'
                : subtitle}
            </Alert>
          ) : (
            <Typography style={{ marginBottom: 20 }}>
              {loading ? 'Please wait...' : subtitle}
            </Typography>
          )}
          {children}
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button
            color='secondary'
            variant='contained'
            onClick={() => {
              onCancel();
              onClose();
            }}
          >
            Cancel
          </Button>
          {retry ? (
            <Button
              disabled={loading}
              color='primary'
              variant='text'
              onClick={retry}
            >
              Resend Code
            </Button>
          ) : null}
          <Button
            disabled={disableConfirm}
            color='primary'
            variant='contained'
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            Confirm
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  disableConfirm: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  retry: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.node,
  loading: PropTypes.bool,
};

ConfirmDialog.defaultProps = {
  children: null,
  loading: false,
  retry: null,
  disableConfirm: false,
  title: 'Confirm action?',
  subtitle:
    'Do you want to confirm this action. This process may be irreversible.',
};
