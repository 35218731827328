module.exports = {
  dashboard: '/app',
  login: '/login',
  market: '#',
  email: '/app/pages/email',
  profile: '/app/pages/user-profile',
  settings: '/app/pages/settings',
  calendar: '/app/pages/calendar',
  chats: '/app/pages/chat',
  twitter: '#',
  github: '#',
  pinterest: '#',
  linkedin: '#',
  buy: '#',
};
