import React from 'react';
import { NotifyUserOfEvent } from './api/EventNotifier';
import { extractErrorMessage } from './api/helpers';
import http from './api/index';
import { userRequests } from './api/requests';

const defaultState = {
  id: '',
  firstName: '',
  lastName: '',
  status: '',
  email: '',
  customEmail: '',
  phoneNumber: '',
  lastActive: '',
  type: '',
  showField: false,
  createdAt: '',
  updatedAt: '',
  deletedAt: null,
  userTypes: [],
  profile: {
    id: '',
    userId: '',
    avatar: '',
    location: '',
    mentorshipHours: 0,
    startUpsInPortforlio: '',
    annualInvestmentAmount: '',
    investorTypeId: '',
    investedBefore: false,
    interestedSectors: [],
    mentoringSkills: [],
    linkedInProfile: '',
    bio: '',
    about: '',
    createdAt: '',
    updatedAt: '',
    deletedAt: null,
    investorType: {
      id: '',
      name: '',
      createdAt: '',
      updatedAt: null,
    },
  },
  company: {
    id: '',
    name: '',
    sharableLink: '',
  },
  userWallet: {
    userId: '',
    walletDetails: {
      totalBalance: '',
      availableBalance: '',
      id: '',
    },
  },
  currentSubscription: [
    {
      features: [],
      id: '',
      autoRenew: true,
      startDate: '',
      subType: '',
      endDate: '',
      plan: {
        id: '',
        name: 'Free',
      },
    },
    {
      features: [],
      id: '',
      autoRenew: true,
      startDate: '',
      subType: '',
      endDate: '',
      plan: {
        id: '',
        name: '',
      },
    },
  ],
  verified: true,
};

export const UserContext = React.createContext({
  state: defaultState,
  reloadProfile: () => null,
});

export function UserProvider({ children }) {
  const [state, setState] = React.useState(defaultState);
  const [response, setResponse] = React.useState('');
  const [reload, setReload] = React.useState('');
  const value = React.useMemo(
    () => ({ state, reloadProfile: () => setReload(Math.random()) }),
    [state]
  );

  React.useEffect(() => {
    const getUserData = async () => {
      try {
        const { data } = await http.get(userRequests.ME);
        if (data.data.user) {
          setState({ ...data.data.user, verified: true });
        }
      } catch (error) {
        const err = extractErrorMessage(error);
        setResponse(err);
      }
    };
    getUserData();
  }, [reload]);

  return (
    <UserContext.Provider value={value}>
      <NotifyUserOfEvent
        message={response}
        open={!!response}
        close={() => setResponse('')}
      />
      {children}
    </UserContext.Provider>
  );
}
