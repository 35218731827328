import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import guideData from 'dan-api/dummy/guideData';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styles from './guide-jss';
import http from '../../redux/api';

const maxStepsSwipe = guideData.length;

const Transition = React.forwardRef((props, ref) => (
  // eslint-disable-line
  <Slide direction='up' ref={ref} {...props} />
));

const headingStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'relative',
    left: theme.spacing(1),
    top: theme.spacing(1),
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const DialogTitle = withStyles(headingStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography>
      <div className={classes.headerContainer}>
        <Typography variant='h6'>{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label='close'
            onClick={onClose}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
    </MuiDialogTitle>
  );
});

function GuideSlider(props) {
  const { classes, theme, openGuide, closeGuide, showField } = props;
  const [activeStepSwipe, setActiveStepSwipe] = useState(0);
  const [checked, setCheckBox] = React.useState(!showField);
  const isMobile = useMediaQuery('(max-width: 450px)');

  const handleNextSwipe = () => {
    setActiveStepSwipe(activeStepSwipe + 1);
  };

  React.useEffect(() => {
    setCheckBox(!showField);
  }, [openGuide]);

  const handleBackSwipe = () => {
    setActiveStepSwipe(activeStepSwipe - 1);
  };

  const handleStepChangeSwipe = (index) => {
    setActiveStepSwipe(index);
  };

  const handleClose = () => {
    const { closeGuide } = props;
    closeGuide();
    setActiveStepSwipe(0);
  };

  const handleChange = async (e) => {
    const value = e.target.checked;
    setCheckBox(value);
    await http.post('/show-field', { showField: !value });
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted
      open={openGuide}
      onClose={closeGuide}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className={classes.root}
      scroll='body'
    >
      <DialogTitle id='-dialog-title' onClose={handleClose}>
        Conectivest Guide
      </DialogTitle>
      <DialogContent className={classes.rootContent}>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStepSwipe}
          onChangeIndex={handleStepChangeSwipe}
          enableMouseEvents
          className={classes.guideWrap}
        >
          {guideData.map((step, index) => (
            <div
              style={{ marginBottom: isMobile ? -150 : -90 }}
              className={classes.figure}
              key={index.toString()}
            >
              <img
                key={step.label}
                className={classes.img}
                src={step.imgPath}
                alt={step.title}
                style={{ objectFit: 'cover', width: '80%' }}
              />
            </div>
          ))}
        </SwipeableViews>
        <article className={classes.text}>
          <Typography variant='h6'>
            {guideData[activeStepSwipe].title}
          </Typography>
          <Typography>{guideData[activeStepSwipe].label}</Typography>
        </article>
        <FormControlLabel
          style={{ marginLeft: 18 }}
          control={
            <Checkbox
              checked={checked}
              onChange={handleChange}
              name='checkedB'
              color='primary'
            />
          }
          label="Don't show again"
        />
        <MobileStepper
          variant='progress'
          steps={maxStepsSwipe}
          position='static'
          activeStep={activeStepSwipe}
          className={classes.mobileStepper}
          nextButton={
            activeStepSwipe === maxStepsSwipe - 1 ? (
              <Button size='small' color='primary' onClick={handleClose}>
                Done
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            ) : (
              <Button size='small' onClick={handleNextSwipe}>
                Next
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            )
          }
          backButton={
            <Button
              size='small'
              onClick={handleBackSwipe}
              disabled={activeStepSwipe === 0}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
      </DialogContent>
    </Dialog>
  );
}

GuideSlider.propTypes = {
  openGuide: PropTypes.bool.isRequired,
  closeGuide: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(GuideSlider);
