import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Outer from '../Templates/Outer';
import { Login, Register, ResetPassword, NotFound } from '../pageListAsync';

function Auth() {
  return (
    <Outer>
      <Switch>
        <Route path='/login' component={Login} />
        <Route path='/register' component={Register} />
        <Route path='/reset-password' component={ResetPassword} />
        <Redirect from='/*' to='/login' />
        {/* <Route path='/maintenance' component={Maintenance} />
        <Route path='/coming-soon' component={ComingSoon} /> */}
        <Route component={NotFound} />
      </Switch>
    </Outer>
  );
}

export default Auth;
