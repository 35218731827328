import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import styles from './cardStyle-jss';
import { currencyFormatter } from '../../utils/helpers';
import { ConfirmDialog } from '../../utils/components/ConfirmDialog';

function PricingCard(props) {
  const SUBSCRIPTION_PLANS = {
    monthly: 'SUBSCRIPTION_MONTHLY',
    yearly: 'SUBSCRIPTION_YEARLY',
  };
  const { classes, title, price, feature, tier, onSelect, id } = props;
  const [selectedPrice, setSelectedPrice] = useState(price.monthly);
  const [duration, setDuration] = useState(SUBSCRIPTION_PLANS.monthly);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [autoRenew, setAutoRenew] = useState(true);

  useEffect(() => {
    if (duration === SUBSCRIPTION_PLANS.monthly) {
      setSelectedPrice(price.monthly);
    } else setSelectedPrice(price.yearly);
  }, [duration]);

  function getPrice(value = 0) {
    return currencyFormatter({ value });
  }

  const getTier = (lv) => {
    switch (lv) {
      case 'free':
        return classes.cheap;
      case 'professional':
        return classes.expensive;
      case 'premium':
        return classes.moreExpensive;
      default:
        return classes.free;
    }
  };

  const convertToMainUnit = (value) => value / 100;

  return (
    <Card
      className={classNames(classes.priceCard, getTier(tier.toLowerCase()))}
    >
      <div className={classes.priceHead}>
        <Typography variant='h5'>{title}</Typography>
        <Typography component='h4' variant='h4'>
          {getPrice(convertToMainUnit(selectedPrice.price))}
        </Typography>
      </div>
      <CardContent className={classes.featureList}>
        <ul>
          {feature.map((item, index) => (
            <li key={index.toString()}>{item}</li>
          ))}
        </ul>
      </CardContent>
      <CardActions className={classes.btnArea}>
        <Button
          onClick={() => setOpenConfirmDialog(true)}
          variant='outlined'
          size='large'
          className={classes.lightButton}
        >
          Select
        </Button>
      </CardActions>
      <CardActions className={classes.btnArea}>
        <TextField
          style={{ color: 'white', background: 'white' }}
          onChange={(e) => setDuration(e.target.value)}
          value={duration}
          select
          SelectProps={{
            native: true,
          }}
        >
          <option style={{ color: 'white' }} value={SUBSCRIPTION_PLANS.monthly}>
            Monthly
          </option>
          <option style={{ color: 'white' }} value={SUBSCRIPTION_PLANS.yearly}>
            Yearly
          </option>
        </TextField>
      </CardActions>
      <ConfirmDialog
        open={openConfirmDialog}
        onCancel={() => setOpenConfirmDialog(false)}
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={() =>
          onSelect(
            convertToMainUnit(selectedPrice.price),
            id,
            duration,
            autoRenew
          )
        }
        title='Confirm Subscription'
        subtitle={`We will attempt to charge you ${getPrice(
          convertToMainUnit(selectedPrice.price)
        )} for a ${title} ${
          duration === SUBSCRIPTION_PLANS.monthly ? 'monthly' : 'yearly'
        } subscription plan. This charge will be deducted from your personal balance. If Auto Renew field is checked, we will try to charge your wallet at the end of your current subscription automatically.`}
      >
        <Divider />
        <FormControlLabel
          checked={autoRenew}
          onChange={(e) => setAutoRenew(e.target.checked)}
          control={<Checkbox />}
          label='Auto Renew'
        />
      </ConfirmDialog>
    </Card>
  );
}

PricingCard.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.object.isRequired,
  tier: PropTypes.string.isRequired,
  feature: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default withStyles(styles)(PricingCard);
