import React, { useEffect, useState, useCallback, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MonoConnect from '@mono.co/connect.js';
import { closePaymentModal, useFlutterwave } from 'flutterwave-react-v3';
import {
  DialogContentText,
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  FormControl,
  FormLabel,
  Select,
  Input,
  DialogActions,
  Button,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
// import { loadStripe } from '@stripe/stripe-js';
import { CloseDialogButton } from './CloseModalButton';
import { NotifyUserOfEvent } from '../../redux/api/EventNotifier';
import { extractErrorMessage } from '../../redux/api/helpers';
import { fetchProfile } from '../../redux/actions/profileActions';
import http from '../../redux/api';
import { PAYMENT_REQUESTS } from '../../redux/api/requests';
import { WalletContext } from '../../redux/walletContext';
import {
  CARD_PAYMENT_ALIASES,
  NIGERIAN_PAYMENT_METHODS,
  PAYMENT_OPTIONS,
  STRIPE_REDIRECT_KEY,
  UNITED_STATES,
} from '../constants';
import { useExchangeRate, useProfile, useStripeCharges } from './hooks';
import { Bold } from './Bold';
import { CircularIndeterminateSm } from '../../components/Progress/CircularIndeterminate';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    marginBottom: 5,
    marginTop: 5,
  },
  info: {
    lineHeight: 2,
  },
}));

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export const FundWallet = React.memo(({ open, onClose, callback }) => {
  const [apiResponseMessage, setApiResponseMessage] = useState('');
  const [initializationError, setInitializationError] = useState('');
  const [collectingPayment, setCollectingPayment] = useState(false);
  const [info, setInfo] = useState('');
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(
    PAYMENT_OPTIONS[0]
  );
  const [currency, setCurrency] = useState();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [amount, setAmount] = useState('');
  const [generatingUrl, setGeneratingUrl] = useState(false);
  const classes = useStyles();
  const [config, setConfig] = useState({
    public_key: process.env.REACT_APP_FLW_PUBLIC_KEY,
    tx_ref: undefined,
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: '',
      phonenumber: '',
      name: '',
    },
    customizations: {
      title: 'Conectivest',
      description: 'Wallet Funding',
      logo:
        'https://conectivest-erp.s3.eu-west-2.amazonaws.com/others/fe4e16cd-4cfe-4bd2-bfb3-9520e1086b6f-logo.png',
    },
  });
  const fullScreen = useMediaQuery('(max-width:600px)');
  const handleFlutterPayment = useFlutterwave(config);
  const dispatch = useDispatch();
  const { refetch } = useContext(WalletContext);
  const { rate, error: exchangeRateError } = useExchangeRate(currency);
  const profile = useProfile();
  const { charges, error, clearError, processing } = useStripeCharges(
    amount,
    selectedPaymentMethod,
    currency
  );

  const handleSuccessfulPayment = useCallback((msg) => {
    dispatch(fetchProfile());
    refetch();
    setApiResponseMessage(msg || 'Successfully funded wallet');
    if (typeof callback === 'function') {
      callback();
    }
    setTimeout(onClose, 7000);
  }, []);

  const topUpWallet = async ({ reference, paymentGatewayReference }) => {
    try {
      await http.post(PAYMENT_REQUESTS.CONFIRM_WALLET_TOP_FROM_FLUTTERWAVE, {
        reference,
        paymentGatewayReference,
      });
      handleSuccessfulPayment();
    } catch (error) {
      const res = extractErrorMessage(error);
      setInitializationError(res);
    }
  };

  const initializePayment = useCallback(async () => {
    try {
      const { data } = await http.post(
        PAYMENT_REQUESTS.INITIALIZE_WALLET_TOP_FROM_FLUTTERWAVE
      );
      const { reference } = data.data;
      // const totalToPay = usingNairaCard
      //   ? getTotalCharge(NAIRA_CARD_CHARGE)
      //   : getTotalCharge(INTERNATIONAL_CARD_CHARGE);
      setConfig((value) => ({
        ...value,
        tx_ref: reference,
        currency,
        amount,
        customer: {
          lastName: `${profile.firstName} ${profile.lastName}`,
          phoneNumber: profile.phoneNumber,
          email: profile.email,
        },
      }));
      setCollectingPayment(true);
    } catch (error) {
      const err = extractErrorMessage(error);
      setInitializationError(err);
      setCollectingPayment(false);
    }
  }, [profile, currency, amount]);

  const flwCallback = async ({
    transaction_id: paymentGatewayReference,
    tx_ref: reference,
  }) => {
    try {
      setCollectingPayment(false);
      closePaymentModal();
      await topUpWallet({ paymentGatewayReference, reference });
    } catch (error) {
      const err = extractErrorMessage(error);
      setInitializationError(err);
    }
  };

  const flwOnClose = () => {
    closePaymentModal();
    setCollectingPayment(false);
    setConfig((value) => ({ ...value, tx_ref: undefined }));
  };

  const monoConfig = {
    key: process.env.REACT_APP_MONO_PUBLIC_KEY,
    onSuccess: () => {
      setCollectingPayment(false);
      handleSuccessfulPayment();
    },
    onClose: () => {
      setCollectingPayment(false);
    },
    scope: 'payments',
  };

  const payWithMono = useCallback(async (amount) => {
    try {
      setCollectingPayment(true);
      const { data } = await http.post(
        PAYMENT_REQUESTS.INITIALIZE_WALLET_TOP_FROM_MONO
      );
      const { reference } = data.data;
      const connect = new MonoConnect({
        ...monoConfig,
        data: {
          type: 'onetime-debit',
          amount,
          description: 'Wallet Funding',
          reference,
        },
      });
      connect.setup();
      connect.open();
    } catch (error) {
      const err = extractErrorMessage(error);
      setInitializationError(err);
    }
  }, []);

  const generateURL = async () => {
    try {
      setGeneratingUrl(true);
      const paymentMethodType =
        selectedPaymentMethod === CARD_PAYMENT_ALIASES.APPLE_PAY ||
        selectedPaymentMethod === CARD_PAYMENT_ALIASES.GOOGLE_PAY
          ? 'card'
          : selectedPaymentMethod;
      const search = window.location.href.indexOf('?');
      const url = window.location.href.substring(
        0,
        search > -1 ? search : undefined
      );
      const { data } = await http.post(
        PAYMENT_REQUESTS.GENERATE_STRIPE_CHECKOUT_URL,
        {
          amount: charges.amountToBePayed,
          collectedFee: charges.collectedFee,
          paymentMethodTypes: [paymentMethodType],
          successUrl: `${window.location.origin}/app/payment-successful`,
          cancelUrl: `${window.location.origin}/app/payment-failed`,
          currency,
        }
      );
      setGeneratingUrl(false);
      localStorage.setItem(STRIPE_REDIRECT_KEY, url);
      window.location.href = data.data;
    } catch (error) {
      setGeneratingUrl(false);
      setInitializationError(extractErrorMessage(error));
    }
  };

  const handleFormSubmit = (e) => {
    try {
      e.preventDefault();
      setInitializationError('');
      clearError('');
      if (selectedPaymentMethod === NIGERIAN_PAYMENT_METHODS.MONO) {
        return payWithMono();
      }

      if (selectedPaymentMethod === NIGERIAN_PAYMENT_METHODS.FLW) {
        return initializePayment();
      }

      return generateURL();
    } catch (error) {
      const res = extractErrorMessage(error);
      setInitializationError(res);
    }
  };

  useEffect(() => {
    if (config.tx_ref) {
      handleFlutterPayment({
        callback: flwCallback,
        onClose: flwOnClose,
      });
    }
  }, [config]);

  useEffect(() => {
    const selectedOption = PAYMENT_OPTIONS.find(({ options }) =>
      options.find(({ method }) => method === selectedPaymentMethod)
    );
    if (selectedOption) {
      const selectedMethod = selectedOption.options.find(
        ({ method }) => method === selectedPaymentMethod
      );
      setInfo(selectedMethod.info);
    } else {
      setInfo('');
    }
  }, [selectedPaymentMethod]);

  useEffect(() => {
    const option = selectedPaymentOption.options[0];
    setSelectedPaymentMethod(option.method);
    setCurrency(selectedPaymentOption.currency);
  }, [selectedPaymentOption]);

  return (
    <Dialog
      disableBackdropClick
      onClose={onClose}
      open={open && !collectingPayment}
      fullScreen={fullScreen}
    >
      <DialogTitle>Fund your wallet</DialogTitle>
      <CloseDialogButton onClick={onClose} />
      <DialogContent>
        <DialogContentText>
          Funding your wallet enables you invest in choice deals shared through
          network admins and Conectivest{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={`${process.env.REACT_APP_TIMELINE_URL}/app/deals`}
          >
            Deal
          </a>{' '}
          Page.
        </DialogContentText>
        {initializationError || error || exchangeRateError ? (
          <Alert severity='error'>
            {initializationError || error || exchangeRateError}
          </Alert>
        ) : (
          <>
            {info ? (
              <Alert style={{ marginBottom: 10 }} severity='info'>
                <p
                  dangerouslySetInnerHTML={{ __html: info }}
                  className={classes.info}
                />
              </Alert>
            ) : null}
            <Alert style={{ marginBottom: 5 }} severity='success'>
              <p className={classes.info}>
                Your personal wallet will be credited with{' '}
                <Bold value={amount / rate} />.
              </p>
              {currency !== 'USD' ? (
                <p className={classes.info}>
                  Exhange rate is <Bold value={1} currency='USD' /> ={' '}
                  <Bold value={rate} currency={currency} />.
                </p>
              ) : null}
              {currency !== 'NGN' ? (
                <p className={classes.info}>
                  You will be charged a total of{' '}
                  <Bold
                    currency={currency}
                    value={charges.amountToBePayed / 100}
                  />
                  .
                </p>
              ) : null}
              <p className={classes.info}>
                Minimum transaction amount is{' '}
                <Bold value={Math.ceil(rate)} currency={currency} />.
              </p>
            </Alert>
          </>
        )}
        {/* <TradingFormWidget
          payWithMono={payWithMono}
          payWithFlw={initializePayment}
          busy={collectingPayment}
          handleError={(err) => setInitializationError(err)}
          handleSuccessfulPayment={handleSuccessfulPayment}
          hideParent={() => setCollectingPayment(true)}
          showParent={() => setCollectingPayment(false)}
        /> */}
        <form
          onFocus={() => {
            setInitializationError('');
          }}
          onSubmit={handleFormSubmit}
          id='fund-wallet-form'
        >
          <FormControl className={classes.formControl}>
            <FormLabel>Amount ({currency})</FormLabel>
            <Input
              id='adornment-amount5'
              value={amount}
              inputProps={{ step: 1, min: Math.ceil(rate) }}
              error={amount < Math.ceil(rate)}
              required
              type='number'
              onChange={(e) =>
                setAmount(e.target.value ? Math.trunc(e.target.value) : '')
              }
              fullWidth
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <FormLabel>Select Country</FormLabel>
            <Select
              onChange={(e) => {
                const selected = PAYMENT_OPTIONS.find(
                  ({ code }) => code === e.target.value
                );
                setSelectedPaymentOption(selected);
              }}
              value={selectedPaymentOption.code}
              native
            >
              {PAYMENT_OPTIONS.map(({ country, code }) => (
                <option key={code} value={code}>
                  {country}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <FormLabel>Select Payment Method</FormLabel>
            <Select
              onChange={(e) => setSelectedPaymentMethod(e.target.value)}
              value={selectedPaymentMethod}
              native
            >
              {selectedPaymentOption.options.map(({ name, method }) => (
                <option key={`${name}-${method}`} value={method}>
                  {name}
                </option>
              ))}
            </Select>
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={onClose} color='secondary'>
          Cancel
        </Button>
        <Button
          disabled={amount < rate || generatingUrl || !!error || processing}
          form='fund-wallet-form'
          variant='contained'
          color='primary'
          type='submit'
        >
          {generatingUrl ? <CircularIndeterminateSm size='12px' /> : 'Pay'}
        </Button>
      </DialogActions>
      <NotifyUserOfEvent
        open={!!apiResponseMessage}
        close={() => setApiResponseMessage('')}
        message={apiResponseMessage}
      />
    </Dialog>
  );
});
